import {
	ADD_NEW_RESTAURANT_ORDER,
	ADD_RESTAURANT_TABLES_WAITER_CALL,
	APPEND_RESTAURANT_ORDERS, RE_INITIALIZE_RESTAURANT, REMOVE_RESTAURANT_ORDER, SET_RESTAURANT_IS_ANIMATION_STOPPED, SET_RESTAURANT_AREA_ID, SET_RESTAURANT_AREAS, SET_RESTAURANT_COUPON_ACTIVATE_MODAL, SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_NEEDED, SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_OPEN, SET_RESTAURANT_MODAL_INVOICE_CONFIGS_IS_OPEN, SET_RESTAURANT_MODAL_INVOICE_CUSTOMER_DATA_IS_OPEN, SET_RESTAURANT_MODAL_TERMINAL_BUTTONS_IS_OPEN, SET_RESTAURANT_MODAL_TERMINAL_PAYMENT_PROCESS_IS_OPEN, SET_RESTAURANT_ORDER_CHANGE_MODAL, SET_RESTAURANT_ORDER_MODAL, SET_RESTAURANT_ORDER_SPLIT_MODAL, SET_RESTAURANT_ORDERS, SET_RESTAURANT_ORDERS_CHANGE_MODAL, SET_RESTAURANT_ORDERS_MOVE, SET_RESTAURANT_ORDERS_TOTAL, SET_RESTAURANT_PAYMENT_MODAL, SET_RESTAURANT_PAYMENT_TYPE_MODAL, SET_RESTAURANT_SHOW_PROCESS_PAYMENT_ERROR_MODAL, SET_RESTAURANT_SHOW_SEND_TO_KITCHEN_MODAL, SET_RESTAURANT_SHOW_TABLE_WAITER_NOTIFIED_MODAL, SET_RESTAURANT_SPLIT_ACTIONS_MODAL, SET_RESTAURANT_SPLIT_MODAL, SET_RESTAURANT_SWAP_MODAL, SET_RESTAURANT_TABLE, SET_RESTAURANT_TABLE_CLOSED_ERROR_MODAL, SET_RESTAURANT_TABLE_MODAL, SET_RESTAURANT_TABLES, SET_RESTAURANT_TABLES_LOADING, SET_RESTAURANT_TABLES_WAITER_CALLS, UPDATE_RESTAURANT_ORDER, UPDATE_RESTAURANT_ORDER_STATUS, UPDATE_RESTAURANT_TABLE, SET_RESTAURANT_SHOW_RECEIPT_PAYER_DATA_MODAL,
} from '../../actionTypes/restaurant'

export const reInitializeRestaurant = () => ({ type: RE_INITIALIZE_RESTAURANT })

export const setRestaurantAreas = value => ({ type: SET_RESTAURANT_AREAS, value })

export const setRestaurantTables = value => ({ type: SET_RESTAURANT_TABLES, value })

export const updateRestaurantTable = value => ({ type: UPDATE_RESTAURANT_TABLE, value })

export const setRestaurantTablesLoading = value => ({ type: SET_RESTAURANT_TABLES_LOADING, value })

export const setRestaurantAreaId = value => ({ type: SET_RESTAURANT_AREA_ID, value })

export const setRestaurantTable = value => ({ type: SET_RESTAURANT_TABLE, value })

export const setRestaurantTableModal = value => ({ type: SET_RESTAURANT_TABLE_MODAL, value })

export const setRestaurantPaymentModal = value => ({ type: SET_RESTAURANT_PAYMENT_MODAL, value })

export const setRestaurantPaymentTypeModal = value => ({ type: SET_RESTAURANT_PAYMENT_TYPE_MODAL, value })

export const setRestaurantSwapModal = value => ({ type: SET_RESTAURANT_SWAP_MODAL, value })

export const setRestaurantSplitModal = value => ({ type: SET_RESTAURANT_SPLIT_MODAL, value })

export const setRestaurantSplitActionsModal = value => ({ type: SET_RESTAURANT_SPLIT_ACTIONS_MODAL, value })

export const setRestaurantOrderModal = value => ({ type: SET_RESTAURANT_ORDER_MODAL, value })

export const setRestaurantOrderSplitModal = value => ({ type: SET_RESTAURANT_ORDER_SPLIT_MODAL, value })

export const setRestaurantOrders = value => ({ type: SET_RESTAURANT_ORDERS, value })

export const setRestaurantOrdersMove = value => ({ type: SET_RESTAURANT_ORDERS_MOVE, value })

export const appendRestaurantOrders = value => ({ type: APPEND_RESTAURANT_ORDERS, value })

export const addNewRestaurantOrder = value => ({ type: ADD_NEW_RESTAURANT_ORDER, value })

export const updateRestaurantOrder = value => ({ type: UPDATE_RESTAURANT_ORDER, value })

export const removeRestaurantOrder = value => ({ type: REMOVE_RESTAURANT_ORDER, value })

export const setRestaurantOrdersTotal = value => ({ type: SET_RESTAURANT_ORDERS_TOTAL, value })

export const updateRestaurantOrderStatus = value => ({ type: UPDATE_RESTAURANT_ORDER_STATUS, value })

export const setRestaurantModalChangeCalculatorIsNeeded = value => ({ type: SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_NEEDED, value })

export const setRestaurantTableClosedErrorModal = value => ({ type: SET_RESTAURANT_TABLE_CLOSED_ERROR_MODAL, value })

export const setRestaurantModalChangeCalculatorIsOpen = value => ({ type: SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_OPEN, value })

export const setRestaurantModalTerminalButtonsIsOpen = value => ({ type: SET_RESTAURANT_MODAL_TERMINAL_BUTTONS_IS_OPEN, value })

export const setRestaurantModalTerminalPaymentProcessIsOpen = value => ({ type: SET_RESTAURANT_MODAL_TERMINAL_PAYMENT_PROCESS_IS_OPEN, value })

export const setRestaurantModalInvoiceConfigsIsOpen = value => ({ type: SET_RESTAURANT_MODAL_INVOICE_CONFIGS_IS_OPEN, value })

export const setRestaurantModalInvoiceCustomerDataIsOpen = value => ({ type: SET_RESTAURANT_MODAL_INVOICE_CUSTOMER_DATA_IS_OPEN, value })

export const setRestaurantCouponActivateModal = value => ({ type: SET_RESTAURANT_COUPON_ACTIVATE_MODAL, value })

export const setRestaurantOrderChangeModal = value => ({ type: SET_RESTAURANT_ORDER_CHANGE_MODAL, value })

export const setRestaurantOrdersChangeModal = value => ({ type: SET_RESTAURANT_ORDERS_CHANGE_MODAL, value })

export const setRestaurantShowProcessPaymentErrorModal = value => ({ type: SET_RESTAURANT_SHOW_PROCESS_PAYMENT_ERROR_MODAL, value })

export const setRestaurantTablesWaiterCalls = value => ({ type: SET_RESTAURANT_TABLES_WAITER_CALLS, value })

export const addRestaurantTablesWaiterCall = value => ({ type: ADD_RESTAURANT_TABLES_WAITER_CALL, value })

export const setRestaurantShowSendToKitchenModal = value => ({ type: SET_RESTAURANT_SHOW_SEND_TO_KITCHEN_MODAL, value })

export const setRestaurantShowTableWaiterNotifiedModal = value => ({ type: SET_RESTAURANT_SHOW_TABLE_WAITER_NOTIFIED_MODAL, value })

export const setRestaurantIsAnimationStopped = value => ({ type: SET_RESTAURANT_IS_ANIMATION_STOPPED, value })

export const setRestaurantShowReceiptPayerData = value => ({ type: SET_RESTAURANT_SHOW_RECEIPT_PAYER_DATA_MODAL, value })
