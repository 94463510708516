/* eslint no-console: off */
import { getFloat, roundPrice } from '../../../../../../helper'
import { setCartPaymentTap2PayEnabled } from '../../../../../../redux/action/cartPayment'

export const filterTipsString = v => v.replace(/[^0-9.,]/g, '')

export const getPercentsTips = (discount, itemsTotal) => {
	if (discount) {
		return roundPrice(itemsTotal * discount / 100.0)
	} else {
		return 0
	}
}

export const getPriceWithTips = (itemsTotal, discount) => {
	return roundPrice(itemsTotal + getFloat(discount))
}

export const handleInputChange = (inputValue, setCustomerGivenSum) => {
	const filter = v => v.replace(/[^0-9.,]/g, '')
	if (/^-?\d*\.?\d*$/.test(inputValue) || (/^-?\d*\.?\d*,$/.test(inputValue))) {
		setCustomerGivenSum(inputValue.replace('.', ','))
	} else {
		inputValue = filter(inputValue).replace('.', ',')
		setCustomerGivenSum(inputValue)
	}
}

export const messageParser = (value, companyIndex) => {
	console.warn(`Message parser companyIndex:${companyIndex} value:`, value)
	try {
		return JSON.parse(value)
	} catch (e) {
		console.error(`Message parser in companyIndex:${companyIndex} Error parsing message data:`, e)
		return value
	}
}
export const handleKassaAppListener = (message, dispatch, companyIndex) => {
	console.log(`App listener companyIndex:${companyIndex} handleKassaAppListener message:`, message)
	if (!message?.data) {
		return
	}
	try {
		const messageData = messageParser(message.data, companyIndex)
		const messageType = messageData?.type
		console.log(`App listener Get message type:${messageType}, companyIndex:${companyIndex}`, messageData)
		switch (messageType) {
			case 'kassa-app.TAP_TO_PAY_ENABLED':
				console.log(`Get message kassa-app.TAP_TO_PAY_ENABLED companyIndex:${companyIndex}`, messageData)
				dispatch(setCartPaymentTap2PayEnabled(messageData?.data?.enabled))
				break
			default:
				break
		}

	} catch (error) {
		console.warn('Kassa App handler error: ' + error.message + ', message: ' + message.data)
	}
}

export const getErrorMessage = (paymentSession, createSessionError, t) => {
	if (createSessionError) {
		return t('Tap2PayModals.process.createSessionError')
	} else if (paymentSession?.status === 'CANCELED') {
		return t('Tap2PayModals.process.paymentIsCanceled')
	} else {
		return t('Tap2PayModals.process.status_error')
	}
}
