import { SET_SHOW_CHANGE_PAYMENT_AMOUNT_MODAL, SET_SHOW_CHANGE_TIPS_MODAL, SET_SHOW_PAYMENT_TAP_TO_PAY_MODAL, SET_SHOW_PROCESS_PAYMENT_TAP_TO_PAY_MODAL, SET_SHOW_SUCCEEDED_PAYMENT_TAP_TO_PAY_MODAL, SET_SHOW_TAP_TO_PAY_AVAILABILITY_CHECKER_MODAL, SET_SHOW_TIP_SELECTION_MODAL, SET_TAP_TO_PAY_TIPS } from '../../actionTypes/tap2Pay'

export const setShowChangePaymentAmountModal = value => ({ type: SET_SHOW_CHANGE_PAYMENT_AMOUNT_MODAL, value })

export const setShowTipSelectionModal = value => ({ type: SET_SHOW_TIP_SELECTION_MODAL, value })

export const setShowChangeTipsModal = value => ({ type: SET_SHOW_CHANGE_TIPS_MODAL, value })

export const setShowPaymentTap2PayModal = value => ({ type: SET_SHOW_PAYMENT_TAP_TO_PAY_MODAL, value })

export const setShowProcessPaymentTap2PayModal = value => ({ type: SET_SHOW_PROCESS_PAYMENT_TAP_TO_PAY_MODAL, value })

export const setTap2PayTips = value => ({ type: SET_TAP_TO_PAY_TIPS, value })

export const setShowSucceededPaymentTap2PayModal = value => ({ type: SET_SHOW_SUCCEEDED_PAYMENT_TAP_TO_PAY_MODAL, value })

export const setShowTap2PayAvailabilityCheckerModal = value => ({ type: SET_SHOW_TAP_TO_PAY_AVAILABILITY_CHECKER_MODAL, value })
