import { APPEND_DELIVERY_CONFIG_POLYGON, APPEND_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, APPEND_DELIVERY_CONFIGS, DELETE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, SET_DELIVERY_CONFIG, SET_DELIVERY_CONFIG_DRAWING_MODE, SET_DELIVERY_CONFIG_ID_EDITED_ZONE, SET_DELIVERY_CONFIG_LENGTH, SET_DELIVERY_CONFIG_NEW_ZONE_DATA, SET_DELIVERY_CONFIG_POLYGON_COLOR, SET_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, SET_DELIVERY_CONFIG_POLYGON_ERROR, SET_DELIVERY_CONFIG_POLYGONS, SET_DELIVERY_CONFIG_POLYGONS_LENGTH, SET_DELIVERY_CONFIG_SHOW_CREATING_FORM, SET_DELIVERY_CONFIG_SHOW_DEFINE_POLYGON_MODAL, SET_DELIVERY_CONFIG_TYPE, SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SEARCH_IS_FINISHED, SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SUGGESTIONS, SET_DELIVERY_CONFIGS, SET_DELIVERY_PERIODS_CONFIG, UPDATE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, UPDATE_DELIVERY_CONFIG_ZIP_LOCALITY_SUGGESTION } from '../../actionTypes/deliveryConfig'

export const setDeliveryConfigs = value => ({ type: SET_DELIVERY_CONFIGS, value })

export const appendDeliveryConfigs = value => ({ type: APPEND_DELIVERY_CONFIGS, value })

export const setDeliveryConfig = value => ({ type: SET_DELIVERY_CONFIG, value })

export const setDeliveryConfigLength = value => ({ type: SET_DELIVERY_CONFIG_LENGTH, value })

export const setDeliveryConfigType = value => ({ type: SET_DELIVERY_CONFIG_TYPE, value })

export const setDeliveryConfigPolygonsLength = value => ({ type: SET_DELIVERY_CONFIG_POLYGONS_LENGTH, value })

export const appendDeliveryConfigPolygon = value => ({ type: APPEND_DELIVERY_CONFIG_POLYGON, value })

export const setDeliveryConfigPolygons = value => ({ type: SET_DELIVERY_CONFIG_POLYGONS, value })

export const setDeliveryConfigIdEditedZone = value => ({ type: SET_DELIVERY_CONFIG_ID_EDITED_ZONE, value })

export const setDeliveryConfigPolygonColor = value => ({ type: SET_DELIVERY_CONFIG_POLYGON_COLOR, value })

export const setDeliveryConfigNewZoneData = value => ({ type: SET_DELIVERY_CONFIG_NEW_ZONE_DATA, value })

export const setDeliveryConfigShowDefinePolygonModal = value => ({ type: SET_DELIVERY_CONFIG_SHOW_DEFINE_POLYGON_MODAL, value })

export const setDeliveryConfigShowCreatingForm = value => ({ type: SET_DELIVERY_CONFIG_SHOW_CREATING_FORM, value })

export const setDeliveryConfigPolygonError = value => ({ type: SET_DELIVERY_CONFIG_POLYGON_ERROR, value })

export const setDeliveryConfigDrawingMode = value => ({ type: SET_DELIVERY_CONFIG_DRAWING_MODE, value })

export const setDeliveryConfigZipLocalitiesSearchIsFinished = value => ({ type: SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SEARCH_IS_FINISHED, value })

export const setDeliveryConfigZipLocalitiesSuggestions = value => ({ type: SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SUGGESTIONS, value })

export const updateDeliveryConfigZipLocalitySuggestion = value => ({ type: UPDATE_DELIVERY_CONFIG_ZIP_LOCALITY_SUGGESTION, value })

export const setDeliveryConfigPolygonCoordinatesData = value => ({ type: SET_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, value })

export const appendDeliveryConfigPolygonCoordinatesData = value => ({ type: APPEND_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, value })

export const updateDeliveryConfigPolygonCoordinatesData = value => ({ type: UPDATE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, value })

export const deleteDeliveryConfigPolygonCoordinatesData = value => ({ type: DELETE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, value })

export const setDeliveryPeriodsConfig = value => ({ type: SET_DELIVERY_PERIODS_CONFIG, value })
