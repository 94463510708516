import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const CustomerModalFormWrapper = styled.form`
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.63em;
	width: 100%;

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: repeat(2, 1fr);
		row-gap: 1.43em;
		column-gap: 0.71em;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
		gap: 1.25em;
	}
`
CustomerModalFormWrapper.displayName = 'CustomerModalFormWrapper'

export const CustomerModalFormLine = styled.div`
	display: grid;
	gap: 0.63em;
	grid-template-columns: 1.5fr 0.8fr;

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: 1fr 1fr;
		grid-column: 1 / 3;
		row-gap: 1.43em;
		column-gap: 0.71em;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
		grid-column: 1;
		gap: 1.25em;
	}
`
CustomerModalFormLine.displayName = 'CustomerModalFormLine'

export const CustomerModalFormNameContainer = styled.div`
	@media screen and (max-width: ${theme.point820}) {
		grid-column: 1 / 3;

		&.columns {
			grid-column: 1;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-column: 1;
	}
`
CustomerModalFormNameContainer.dispalName = 'CustomerModalFormNameContainer'

export const CustomerModalFormAddressWrapper = styled.div`
	text-align: left;

	@media screen and (max-width: ${theme.point820}) {
		grid-column: 1 / 3;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-column: 1;
	}
`
CustomerModalFormAddressWrapper.dispalName = 'CustomerModalFormAddressWrapper'

export const CustomerModalFormEmailWrapper = styled.div`
	@media screen and (max-width: ${theme.point820}) {
		grid-column: 1 / 3;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-column: 1;
	}
`
CustomerModalFormEmailWrapper.dispalName = 'CustomerModalFormEmailWrapper'

