import { createSelector } from 'reselect'
import { calcCartItemTotalPrice, roundPrice } from '../../../helper'
import { getActualItemsUnpaidOrder } from '../../../helper/unpaidOrder'

const cartItemsTypes = ['TOPPING', 'MAIN_DISH', 'BEVERAGE', 'BOTTLE_DEPOSIT', 'MEAL', 'MENU', 'GIFT_COUPON', 'MARKETING_COUPON', 'CASH_EXCHANGE']
const discountItemsTypes = ['MARKETING_COUPON', 'DISCOUNT', 'EXTRA_DISCOUNT']

export const selectPurchaseItems = store => store.clientMonitorCart.cartState?.purchaseItems || []

export const selectCartCouponPayments = store => store.clientMonitorCart.cartState?.payments || []

export const selectCartItems = createSelector(
	selectPurchaseItems,
	(items) => {
		const cartItems =  items.filter(item => cartItemsTypes.includes(item.type))
		return getActualItemsUnpaidOrder(cartItems)
	},
)

export const selectDiscountValue = createSelector(
	selectPurchaseItems,
	(items) => {
		return items.find(item => item.type === 'DISCOUNT')?.discountMetaData?.value || 0
	}
)

export const selectCartToppingsLength = createSelector(
	selectCartItems,
	(items) => {
		return items?.reduce((total, current) => total + current?.items?.length, 0)
	}
)

export const selectCartItemsTotal = createSelector(
	selectCartItems,
	(items) => {
		const productsItems = items.filter(item => item.type !== 'MARKETING_COUPON')
		return productsItems?.reduce((tp, i) => tp + calcCartItemTotalPrice(i), 0)
	}
)
export const selectTotalDiscount = createSelector(
	selectPurchaseItems,
	(items) => {
		const discountItems = items.filter(item => discountItemsTypes.includes(item.type))
		return Math.abs(discountItems?.reduce((tp, i) => tp + calcCartItemTotalPrice(i), 0))
	}
)

export const selectGiftCouponsTotal = createSelector(
	selectCartCouponPayments,
	(coupons) => {
		return coupons?.reduce((tp, coupon) => tp + coupon.total, 0)
	}
)


export const selectDeliveryCost = createSelector(
	selectPurchaseItems,
	(items) => {
		return items.find(item => item.type === 'DELIVERY_COST')?.price || 0
	}
)

export const selectCartTotal = createSelector(
	selectCartItemsTotal,
	selectTotalDiscount,
	selectGiftCouponsTotal,
	selectDeliveryCost,
	(totalPrice, totalDiscount, totalGiftCoupons, deliveryCost) => {
		return roundPrice(totalPrice - totalDiscount - totalGiftCoupons + deliveryCost)
	}
)

