import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectCompanyData } from '../../../../../../redux/selector/system'
import AutocompleteInput from '../../../../inputs/AutocompleteInput'
import CheckBoxInputControlled from '../../../../inputs/CheckboxInput/controlled'
import AddressSearchInputs from '../../../../inputs/AddressSearchInputs'
import { setCustomer, setCustomerGeoData, setCustomerGeoDataLoading, setCustomersByCompanyName, setCustomersByEmail, setCustomersByName, setCustomersByNumber, setCustomersByPhone } from '../../../../../../redux/action/customer'
import { deliveryDistanceCustomer, distanceCustomer, searchCustomersByCompanyNameThunk, searchCustomersByEmailThunk, searchCustomersByNameThunk, searchCustomersByNumberThunk, searchCustomersByPhoneThunk, searchResetThunk } from '../../../../../../redux/thunk/customer'
import { setAppEventZIndex } from '../../../../../../redux/action/system'
import { CompanyNameAutocompleteItem, EmailAutocompleteItem, NameAutocompleteItem, NumberAutocompleteItem, PhoneAutocompleteItem } from '../../../../inputs/AutocompleteInput/items'
import { emailPattern } from '../../../../../../helper/email'
import { filterCompanyName, filterName, filterNumber, filterPhone, formatAddressInInput } from '../../../../form/CustomerModalForm/helper'
import { CustomerModalFormAddressWrapper, CustomerModalFormEmailWrapper, CustomerModalFormLine, CustomerModalFormNameContainer, CustomerModalFormWrapper } from '../../../../form/CustomerModalForm/index.styled'

const PHONE_EVENT_Z_INDEX = 73
const NUMBER_EVENT_Z_INDEX = 74
const NAME_EVENT_Z_INDEX = 75
const EMAIL_EVENT_Z_INDEX = 76
const COMPANY_NAME_EVENT_Z_INDEX = 90

const MainCustomerDataForm = ({ isOpen, zIndex, onSubmit, handleSubmit, register, errors, handleEscape, setValue, watch, setFocus, clearErrors, captureCustomerData }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const customer = useSelector(store => store.customerStore.customer)
	const geoData = useSelector(store => store.customerStore.geoData)
	const customersByPhone = useSelector(store => store.customerStore.customersByPhone)
	const customersByNumber = useSelector(store => store.customerStore.customersByNumber)
	const customersByName = useSelector(store => store.customerStore.customersByName)
	const customersByEmail = useSelector(store => store.customerStore.customersByEmail)
	const customersByCompanyName = useSelector(store => store.customerStore.customersByCompanyName)
	const customerAddressIsNotFull = useSelector(store => store.customerStore.customerAddressIsNotFull)
	const [isFirma, setIsFirma] = useState(true)

	const companyCountryCode = useSelector(selectCompanyData('countryCode'))
	const watchAddressHouse = watch('customerAddress.house')
	const watchAddressStreet = watch('customerAddress.street')
	const watchAddressCity = watch('customerAddress.city')
	const watchAddressZipCode = watch('customerAddress.zipCode')
	const watchPhone = watch('phoneNumber')
	const isValidAddress = watchAddressStreet && watchAddressHouse && watchAddressCity && watchAddressZipCode

	useEffect(() => {
		if (isOpen && customer && !customer?.companyName) {
			setIsFirma(false)
		} else {
			setIsFirma(true)
		}
	}, [isOpen, customer?.companyName]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && geoData) {
			setValue('customerAddress.latitude', geoData.lat)
			setValue('customerAddress.longitude', geoData.lon)
		}
	}, [geoData]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && isValidAddress && !customerAddressIsNotFull) {
			dispatch(setCustomerGeoData(null)) // should reset geoData, cause of coordinates change, so it contains deprecated values
			dispatch(setCustomerGeoDataLoading(true))
			const timeOutId = setTimeout(() => {
				dispatch(deliveryDistanceCustomer({
					street: watchAddressStreet,
					streetNumber: watchAddressHouse,
					city: watchAddressCity,
					postalCode: watchAddressZipCode,
				}))
			}, 300)
			return () => clearTimeout(timeOutId)
		}
	}, [watchAddressHouse, customerAddressIsNotFull])// eslint-disable-line react-hooks/exhaustive-deps

	const onAutoCompletePhone = useCallback(value => {
		if (value) {
			dispatch(searchCustomersByPhoneThunk(value))
		} else {
			dispatch(setCustomersByPhone(null))
		}
	}, [dispatch])

	const setItemsByPhone = useCallback(value => {
		dispatch(setCustomersByPhone(value))
	}, [dispatch])

	const onAutoCompleteCustomerNumber = useCallback(value => {
		if (value) {
			dispatch(searchCustomersByNumberThunk(value))
		} else {
			dispatch(setCustomersByNumber(null))
		}
	}, [dispatch])

	const setItemsByNumber = useCallback(value => {
		dispatch(setCustomersByNumber(value))
	}, [dispatch])

	const onAutoCompleteCustomerName = useCallback(value => {
		if (value && !watchPhone) {
			dispatch(searchCustomersByNameThunk(value))
		} else {
			dispatch(setCustomersByName(null))
		}
	}, [watchPhone, dispatch])

	const setItemsByName = useCallback(value => {
		dispatch(setCustomersByName(value))
	}, [dispatch])

	const onAutoCompleteCustomerEmail = useCallback(value => {
		if (value && !watchPhone) {
			dispatch(searchCustomersByEmailThunk(value))
		} else {
			dispatch(setCustomersByEmail(null))
		}
	}, [watchPhone, dispatch])

	const setItemsByEmail = useCallback(value => {
		dispatch(setCustomersByEmail(value))
	}, [dispatch])

	const onAutoCompleteCompanyName = useCallback(value => {
		if (value && isFirma && !watchPhone) {
			dispatch(searchCustomersByCompanyNameThunk(value))
		} else {
			dispatch(setCustomersByCompanyName(null))
		}
	}, [dispatch, isFirma, watchPhone])

	const setItemsByCompanyName = useCallback(value => {
		dispatch(setCustomersByCompanyName(value))
	}, [dispatch])

	const onSubmitData = useCallback((data) => {
		onSubmit(data)
	}, [onSubmit])

	const handleF2 = useCallback(() => {
		handleSubmit(onSubmitData)()
	}, [onSubmitData, handleSubmit])

	const handleCustomerClick = useCallback((data) => {
		dispatch(setCustomer(data))
		if (data.geoData && data.geoData.lon && data.geoData.lat && data.geoData.distance === null) {
			dispatch(distanceCustomer(data.geoData))
		}
		dispatch(searchResetThunk())
		dispatch(setAppEventZIndex(zIndex))
	}, [dispatch, zIndex])

	const toggleFirmaShow = useCallback(() => {
		setIsFirma(prevState => !prevState)
	}, [])

	return (
		<CustomerModalFormWrapper id="invoice-customer-data-form"
															data-testid="invoice-customer-data-form"
															onSubmit={handleSubmit(onSubmitData)}>
			<CustomerModalFormLine>
				<AutocompleteInput testId="customer-phone-number"
													 name="phoneNumber"
													 tabIndex="901"
													 zIndex={PHONE_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.Telefonnumer') + '*'}
													 errors={errors}
													 register={register}
													 options={{
														 required: t('app.validation.required'),
														 pattern: {
															 value: /^[0-9]{3,15}$/i,
															 message: t('app.validation.minNumber3'),
														 },
													 }}
													 items={customersByPhone}
													 Item={PhoneAutocompleteItem}
													 watch={watch('phoneNumber')}
													 onAutoComplete={onAutoCompletePhone}
													 filter={filterPhone}
													 setItems={setItemsByPhone}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 autoFocus={true}
													 color="gray"
													 inputMode="tel"
													 disabled={captureCustomerData} />
				<AutocompleteInput testId="customer-number"
													 name="customerNumber"
													 tabIndex="902"
													 disabled={customer ? customer.customerNumber : captureCustomerData}
													 zIndex={NUMBER_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.customerNumber')}
													 errors={errors}
													 register={register}
													 options={{
														 pattern: {
															 value: /^[0-9]{1,150}$/i,
															 message: t('app.validation.number'),
														 },
													 }}
													 items={customersByNumber}
													 Item={NumberAutocompleteItem}
													 watch={watch('customerNumber')}
													 onAutoComplete={onAutoCompleteCustomerNumber}
													 filter={filterNumber}
													 setItems={setItemsByNumber}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 color="gray"
													 inputMode="tel" />
			</CustomerModalFormLine>
			<CustomerModalFormNameContainer className={isFirma ? 'columns' : ''}>
				<AutocompleteInput testId="customer-name"
													 name="name"
													 tabIndex="903"
													 zIndex={NAME_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.Name') + (!isFirma ? '*' : '')}
													 errors={errors}
													 register={register}
													 options={{
														 required: !isFirma && t('app.validation.required'),
													 }}
													 items={customersByName}
													 Item={NameAutocompleteItem}
													 watch={watch}
													 onAutoComplete={onAutoCompleteCustomerName}
													 filter={filterName}
													 setItems={setItemsByName}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 color="gray"
													 disabled={captureCustomerData}>
					<CheckBoxInputControlled id="firma-id" testId="firma-id" label={t('AddOrder.Client.form.Company')} className="firma-id"
																	 checked={isFirma} onChange={toggleFirmaShow} />
				</AutocompleteInput>
			</CustomerModalFormNameContainer>
			{isFirma &&
				<AutocompleteInput testId="customer-company"
													 name="companyName"
													 tabIndex="904"
													 zIndex={COMPANY_NAME_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.Company') + (isFirma ? '*' : '')}
													 errors={errors}
													 register={register}
													 options={{
														 required: isFirma && t('app.validation.required'),
													 }}
													 items={customersByCompanyName}
													 Item={CompanyNameAutocompleteItem}
													 watch={watch('companyName')}
													 onAutoComplete={onAutoCompleteCompanyName}
													 filter={filterCompanyName}
													 setItems={setItemsByCompanyName}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 color="gray"
													 disabled={customer && customer?.companyName} />}
			<CustomerModalFormAddressWrapper>
				<AddressSearchInputs firstInputName="address"
														 secondInputName="streetNumber"
														 dataName="customerAddress"
														 firstTabIndex="905"
														 secondTabIndex="906"
														 firstLabel={t('AddOrder.Client.form.Strasse') + '*'}
														 secondLabel={t('AddOrder.Client.form.Hausnummer') + '*'}
														 errors={errors}
														 watch={watch}
														 setValue={setValue}
														 setFocus={setFocus}
														 formatAddress={formatAddressInInput}
														 countryDataRequired={false}
														 autocompleteRequest={true}
														 register={register}
														 countryCode={companyCountryCode}
														 returnZIndex={zIndex}
														 cols="cols-2"
														 color="gray"
														 clearErrors={clearErrors}
														 disabled={captureCustomerData} />
				<input data-testid="customer-geodata-distance" type="hidden" value={geoData?.distance || ''} /> {/* only for tests */}
			</CustomerModalFormAddressWrapper>

			<CustomerModalFormEmailWrapper>
				<AutocompleteInput testId="customer-email"
													 name="email"
													 tabIndex="907"
													 zIndex={EMAIL_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.email') + '*'}
													 errors={errors}
													 register={register}
													 options={{
														 required: t('app.validation.required'),
														 pattern: {
															 value: emailPattern,
															 message: t('app.validation.invalid_email'),
														 },
													 }}
													 items={customersByEmail}
													 Item={EmailAutocompleteItem}
													 watch={watch}
													 onAutoComplete={onAutoCompleteCustomerEmail}
													 setItems={setItemsByEmail}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 color="gray" />
			</CustomerModalFormEmailWrapper>
			<input data-testid="customer-remark" type="hidden" {...register('remark')} />
			<input data-testid="customer-delivery-cost" type="hidden" {...register('deliveryCost')} />
		</CustomerModalFormWrapper>
	)
}

MainCustomerDataForm.displayName = 'MainCustomerDataForm'
export default MainCustomerDataForm
