import React, { useCallback } from 'react'
import ModalTerminalButtons from '../../../../../Elements/cart/ModalTerminalButtons'
import ModalChangeCalculator from '../../../../../Elements/changeCalculator'
import ModalTerminalPaymentProcess from '../../../../../Elements/cart/ModalTerminalPaymentProcess'
import ActivateCouponModal from '../../../../../Elements/cart/Coupon/ActivateCouponModal'
import { setRestaurantCouponActivateModal, setRestaurantModalChangeCalculatorIsOpen, setRestaurantModalInvoiceConfigsIsOpen, setRestaurantModalInvoiceCustomerDataIsOpen, setRestaurantModalTerminalButtonsIsOpen, setRestaurantModalTerminalPaymentProcessIsOpen, setRestaurantPaymentTypeModal } from '../../../../../../redux/action/restaurant'
import { useDispatch, useSelector } from 'react-redux'
import { setCartPaymentSelectedTerminal } from '../../../../../../redux/action/cartPayment'
import InvoiceModals from '../../../../../Elements/cart/InvoiceModals'
import { createCartPaymentInvoiceConfigsThunk } from '../../../../../../redux/thunk/cartPayment'
import { selectDisabledTerminalTips } from '../../../../../../redux/selector/cartPayment'
import { isDisabledTerminalTips } from '../../../../../../redux/selector/cartPayment/helper'
import Tap2PayModals from '../../Tap2PayModals'
import { setShowChangePaymentAmountModal } from '../../../../../../redux/action/tap2Pay'

const PaymentModals = ({ totalPriceWithGiftCoupon, ecTerminalTips, handleTerminalPayment, handleModalChangePayment, paymentMethod, handleInvoicePayment, resetTable, updatePaymentMode, handleTap2PayPayment }) => {
	const modalTerminalButtonsIsOpen = useSelector(store => store.restaurant.modalTerminalButtonsIsOpen)
	const modalChangeCalculatorIsOpen = useSelector(store => store.restaurant.modalChangeCalculatorIsOpen)
	const modalTerminalPaymentProcessIsOpen = useSelector(store => store.restaurant.modalTerminalPaymentProcessIsOpen)
	const terminalsLength = useSelector(store => store.cartPayment.terminalsLength)
	const showCouponActivateModal = useSelector(store => store.restaurant.showCouponActivateModal)
	const modalInvoiceConfigsIsOpen = useSelector(store => store.restaurant.modalInvoiceConfigsIsOpen)
	const modalInvoiceCustomerDataIsOpen = useSelector(store => store.restaurant.modalInvoiceCustomerDataIsOpen)
	const disabledTerminalTips = useSelector(selectDisabledTerminalTips('RESTAURANT'))
	const dispatch = useDispatch()

	const closeTerminalButtonsModal = useCallback(() => {
		dispatch(setRestaurantModalTerminalButtonsIsOpen(false))
		dispatch(setRestaurantPaymentTypeModal(true))
	}, [dispatch])

	const handleTerminalSelected = useCallback((item) => {
		const disabledTips = isDisabledTerminalTips(item, 'RESTAURANT')
		dispatch(setRestaurantModalTerminalButtonsIsOpen(false))
		if (disabledTips) {
			dispatch(setRestaurantModalTerminalPaymentProcessIsOpen(true))
		} else {
			dispatch(setRestaurantModalChangeCalculatorIsOpen(true))
		}
	}, [dispatch])

	const closeChangeModal = useCallback(() => {
		dispatch(setRestaurantModalChangeCalculatorIsOpen(false))
		dispatch(setCartPaymentSelectedTerminal(null))
		if (paymentMethod === 'EC_TERMINAL' && terminalsLength > 1) {
			dispatch(setRestaurantModalTerminalButtonsIsOpen(true))
		} else {
			dispatch(setRestaurantPaymentTypeModal(true))
		}
	}, [dispatch, paymentMethod, terminalsLength])

	const reOpenModalChangeCalculator = useCallback(() => {
		dispatch(setRestaurantModalTerminalPaymentProcessIsOpen(false))
		if (disabledTerminalTips) {
			closeChangeModal()
		} else {
			dispatch(setRestaurantModalChangeCalculatorIsOpen(true))
		}
	}, [dispatch, disabledTerminalTips, closeChangeModal])

	const closeModalPaymentsTerminal = useCallback(() => {
		dispatch(setRestaurantModalTerminalPaymentProcessIsOpen(false))
	}, [dispatch])

	const closeActivationModal = useCallback(() => {
		dispatch(setRestaurantCouponActivateModal(false))
	}, [dispatch])

	const closeInvoiceConfigsModal = useCallback(() => {
		dispatch(setRestaurantModalInvoiceConfigsIsOpen(false))
		dispatch(setRestaurantPaymentTypeModal(true))
	}, [dispatch])

	const submitInvoiceConfig = useCallback(async (data) => {
		await dispatch(createCartPaymentInvoiceConfigsThunk(data))
		dispatch(setRestaurantModalInvoiceConfigsIsOpen(false))
		dispatch(setRestaurantModalInvoiceCustomerDataIsOpen(true))
	}, [dispatch])

	const closeInvoiceCustomerDataModal = useCallback(() => {
		dispatch(setRestaurantModalInvoiceCustomerDataIsOpen(false))
		dispatch(setRestaurantPaymentTypeModal(true))
	}, [dispatch])

	const closeChangePaymentAmountModal = useCallback(() => {
		dispatch(setShowChangePaymentAmountModal(false))
		dispatch(setRestaurantPaymentTypeModal(true))
	}, [dispatch])

	return (
		<>
			<ModalTerminalButtons isOpen={modalTerminalButtonsIsOpen}
														close={closeTerminalButtonsModal}
														totalPrice={totalPriceWithGiftCoupon}
														onTerminalSelected={handleTerminalSelected} />
			<ModalChangeCalculator isOpen={modalChangeCalculatorIsOpen}
														 close={closeChangeModal}
														 totalPrice={totalPriceWithGiftCoupon}
														 isEcTerminal={paymentMethod === 'EC_TERMINAL'}
														 handlePayment={handleModalChangePayment} />
			<ModalTerminalPaymentProcess isOpen={modalTerminalPaymentProcessIsOpen}
																	 totalPrice={totalPriceWithGiftCoupon + ecTerminalTips}
																	 navigateBack={reOpenModalChangeCalculator}
																	 close={closeModalPaymentsTerminal}
																	 handlePayment={handleTerminalPayment}
																	 resetCart={resetTable}
																	 printReceipt={true}
																	 ecTerminalTips={ecTerminalTips}
																	 paymentSource={updatePaymentMode ? 'CASH_REGISTER' : 'RESTAURANT'}
																	 module="RESTAURANT"
																	 orderType="RESTAURANT" />
			<InvoiceModals invoiceConfigsModalIsOpen={modalInvoiceConfigsIsOpen}
										 closeInvoiceConfigsModal={closeInvoiceConfigsModal}
										 submitInvoiceConfig={submitInvoiceConfig}
										 invoiceCustomerDataModalIsOpen={modalInvoiceCustomerDataIsOpen}
										 closeInvoiceCustomerDataModal={closeInvoiceCustomerDataModal}
										 handlePayment={handleInvoicePayment} />
			<Tap2PayModals totalPrice={totalPriceWithGiftCoupon}
										 handlePayment={handleTap2PayPayment}
										 closeChangePaymentAmountModal={closeChangePaymentAmountModal}
										 resetCart={resetTable}
										 module="RESTAURANT"
										 orderType="RESTAURANT"
										 printReceipt={true}/>

			<ActivateCouponModal isOpen={showCouponActivateModal}
													 close={closeActivationModal}
													 isGiftCouponMode={true}
													 giftCouponsType="CART_PAYMENT" />
		</>
	)
}

PaymentModals.displayName = 'PaymentModals'
export default PaymentModals
