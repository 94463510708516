import styled from 'styled-components'
import { theme } from '../../../../../../../style/theme'
import { colorTheme } from '../../../../../../../style/theme/colorTheme'


export const GetMoneyInputField = styled.input`
	font-size: 1.75em;
	width: 100%;
	background-color: ${colorTheme.cartModal.changeCalculator.input.getMoney.background};
	color: ${colorTheme.cartModal.changeCalculator.input.getMoney.text};
	text-align: left;
	align-items: center;
`
GetMoneyInputField.displayName = 'GetMoneyInputField'

export const GetMoneyInputContainer = styled.div`
  font-size: 1rem;
  height: 4.19em;
	background-color: ${colorTheme.cartModal.changeCalculator.input.getMoney.background};
  border: 1px solid ${colorTheme.cartModal.changeCalculator.input.getMoney.border};
  border-radius: 0.75em;
  padding: 0.75em 1.25em;
  box-sizing: border-box;

	display: flex;
	align-items: center;

  @media screen and (max-width: ${theme.point720}) {
    height: 3.44em;
    box-shadow: none;
  }
`
GetMoneyInputContainer.displayName = 'GetMoneyInputContainer'

export const GetMoneyLabelText = styled.label`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	color: ${colorTheme.cartModal.changeCalculator.input.getMoney.labelText};
	text-align: left;
`
GetMoneyLabelText.displayName = 'GetMoneyLabelText'
export const GetMoneyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: .31em;
  width: 100%;
  max-width: 25.37em;

  @media screen and (max-width: ${theme.point820}) {
    max-width: 27.67em;
  }

	@media screen and (max-width: ${theme.point720}) {
    max-width: 100%;
  }
`
GetMoneyWrapper.displayName = 'GetMoneyWrapper'
