import styled from 'styled-components'
import { colorTheme } from '../../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../../style/theme'


export const TipsButtonWrap = styled.div`
	font-size: 1rem;
	display: flex;
	flex: 1 0 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.19em;
	height: 8em;
	border-radius: 0.63em;
	background: ${colorTheme.Tap2PayModals.tips.background};
	padding: 0.4em;
`
TipsButtonWrap.displayName = 'TipsButtonWrap'

export const TipsButtonText = styled.div`
	color: ${colorTheme.Tap2PayModals.tips.text};
	text-align: center;
	font-family: ${theme.fontRoboto};
	font-size: 2.5em;
	font-weight: 600;

	&.small {
		font-size: 1.13em;
		font-weight: 500;
	}
`
TipsButtonText.displayName = 'TipsButtonText'
