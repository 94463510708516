import { ADD_NEW_KITCHEN_ORDER, RE_INITIALIZE_KITCHEN_ORDERS, REMOVE_KITCHEN_ORDER, SET_KITCHEN_CONFIG, SET_KITCHEN_MONITOR_CONFIG, SET_KITCHEN_MONITOR_CONFIG_IS_LOADING, SET_KITCHEN_ORDERS, SET_KITCHEN_ORDERS_LOADING, SET_KITCHEN_ORDERS_TOTAL_LENGTH, SET_KITCHEN_SELECTED_ORDER, UPDATE_KITCHEN_ORDER, SET_KITCHEN_MONITOR_CONFIGS } from '../../actionTypes/kitchenMonitor'

const initialState = {
	kitchenOrders: [],
	selectedKitchenOrder: null,
	ordersTotalLength: 0,
	kitchenMonitorConfigs: [],
	kitchenMonitorConfig: null,
	kitchenMonitorConfigIsLoading: false,
	config: null,
	ordersLoading: false,
}

export function kitchenMonitorReducer (state = initialState, action) {
	switch (action.type) {
		case RE_INITIALIZE_KITCHEN_ORDERS:
			return initialState
		case SET_KITCHEN_ORDERS:
			return {
				...state,
				kitchenOrders: action.value,
			}
		case SET_KITCHEN_ORDERS_TOTAL_LENGTH:
			return {
				...state,
				ordersTotalLength: action.value,
			}
		case SET_KITCHEN_SELECTED_ORDER:
			return {
				...state,
				selectedKitchenOrder: action.value,
			}
		case ADD_NEW_KITCHEN_ORDER:
			let ordersNewCopy = [...state.kitchenOrders]
			ordersNewCopy.push(action.value)
			return {
				...state,
				kitchenOrders: ordersNewCopy,
			}
		case UPDATE_KITCHEN_ORDER:
			let ordersUpdateCopy = [...state.kitchenOrders]
			const orderToUpdate = ordersUpdateCopy.find(order => order.orderId === action.value.orderId)
			if (orderToUpdate) {
				ordersUpdateCopy[ordersUpdateCopy.indexOf(orderToUpdate)] = action.value
			}
			return {
				...state,
				kitchenOrders: ordersUpdateCopy,
			}
		case REMOVE_KITCHEN_ORDER:
			let ordersRemovedCopy = [...state.kitchenOrders]
			ordersRemovedCopy = ordersRemovedCopy.filter(order => order?.orderId !== action.value.orderId)
			return {
				...state,
				kitchenOrders: ordersRemovedCopy,
			}
		case SET_KITCHEN_MONITOR_CONFIGS:
			return {
				...state,
				kitchenMonitorConfigs: action.value,
			}
		case SET_KITCHEN_MONITOR_CONFIG:
			return {
				...state,
				kitchenMonitorConfig: action.value,
			}
		case SET_KITCHEN_MONITOR_CONFIG_IS_LOADING:
			return {
				...state,
				kitchenMonitorConfigIsLoading: action.value,
			}
		case SET_KITCHEN_CONFIG:
			return {
				...state,
				config: action.value,
			}
		case SET_KITCHEN_ORDERS_LOADING:
			return {
				...state,
				ordersLoading: action.value,
			}
		default:
			return state
	}
}
