/* eslint no-console: off */
import React, { useCallback, useEffect, useState } from 'react'
import MainIcon from '../../../../../../img/svg/main'
import { ButtonsWrapper, ProcessPaymentTap2PayContent, ProcessPaymentText } from '../index.styled'
import HourglassLoader from '../../../../../Elements/loaders/HourglassLoader'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountDetailsThunk, getTap2PayConnectionTokenThunk } from '../../../../../../redux/thunk/cartPayment/tap2PayPayment'
import { v4 as uuidv4 } from 'uuid'
import { setShowChangePaymentAmountModal, setShowTap2PayAvailabilityCheckerModal } from '../../../../../../redux/action/tap2Pay'
import Button from '../../../../../Elements/buttons/Button'
import CustomModal from '../../../../../Elements/modal/CustomModal'
import { setCartPaymentTap2PayAccountDetails, setCartPaymentTap2PayAccountDetailsError, setCartPaymentTap2PayConnectionToken, setCartPaymentTap2PayConnectionTokenError, setCartPaymentTap2PayEnabled } from '../../../../../../redux/action/cartPayment'
import { selectCompanyIndex } from '../../../../../../redux/selector/system'
import { isDevEnvironment } from '../../../../../../helper'

const EVENT_Z_INDEX = 101
const ModalTap2PayAvailabilityChecker = React.memo(({ hasListenerReady }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isOpen = useSelector(state => state.tap2Pay.modal.showTap2PayAvailabilityChecker)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const hasModalLogin = !!waiterAccessToken
	const tap2PayConnectionToken = useSelector(state => state.cartPayment.tap2PayConnectionToken)
	const isErrorConnectionToken = useSelector(state => state.cartPayment.tap2PayConnectionTokenError)
	const accountDetails = useSelector(state => state.cartPayment.tap2PayAccountDetails)
	const accountDetailsError = useSelector(state => state.cartPayment.tap2PayAccountDetailsError)
	const isEnableTap2Pay = useSelector(state => state.cartPayment.tap2PayEnabled)
	const [createConnectionTokenIsLoading, setCreateConnectionTokenIsLoading] = useState(false)
	const disabledCloseButton = createConnectionTokenIsLoading || (tap2PayConnectionToken && isEnableTap2Pay === null)
	const companyIndex = useSelector(selectCompanyIndex)
	const displayError = isErrorConnectionToken || isEnableTap2Pay === false || accountDetailsError
	const messageError = isErrorConnectionToken ? t('Tap2PayModals.availableCheck.errorConnectionToken') : t('Tap2PayModals.availableCheck.error')
	const isDev = isDevEnvironment()

	useEffect(() => {
		if (isOpen && !tap2PayConnectionToken) {
			fetchConnectionToken()
			dispatch(getAccountDetailsThunk())
		}
	}, [isOpen, tap2PayConnectionToken])// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && hasListenerReady && tap2PayConnectionToken && accountDetails) {
			publishConnectionToken()
		}
	}, [tap2PayConnectionToken, isOpen, hasListenerReady, accountDetails]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && isEnableTap2Pay) {
			dispatch(setShowTap2PayAvailabilityCheckerModal(false))
			dispatch(setShowChangePaymentAmountModal(true))
		}
	}, [isEnableTap2Pay, isOpen]) //eslint-disable-line react-hooks/exhaustive-deps

	const fetchConnectionToken = useCallback(async () => {
		setCreateConnectionTokenIsLoading(true)
		await dispatch(getTap2PayConnectionTokenThunk(hasModalLogin))
		setCreateConnectionTokenIsLoading(false)
	}, [hasModalLogin, dispatch])

	const publishConnectionToken = useCallback(() => {
		console.log(`Publish connection token to native app ${companyIndex}. Connection token:${tap2PayConnectionToken} AccountDetails: ${accountDetails} `)
		const message = {
			eventId: uuidv4(),
			traceId: uuidv4(),
			type: 'kassa-web.TAP_TO_PAY_CONNECTION_TOKEN_CREATED',
			context: 'kassa-web',
			eventTime: new Date().toISOString(),
			data: { connectionToken: tap2PayConnectionToken, isDev, accountDetails },
		}
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(JSON.stringify(message))
		}
	}, [tap2PayConnectionToken, companyIndex, isDev, accountDetails])

	const handleClose = useCallback(() => {
		if (disabledCloseButton) return
		dispatch(setCartPaymentTap2PayConnectionToken(null))
		dispatch(setShowTap2PayAvailabilityCheckerModal(false))
		dispatch(setCartPaymentTap2PayEnabled(null))
		dispatch(setCartPaymentTap2PayConnectionTokenError(null))
		dispatch(setCartPaymentTap2PayAccountDetails(null))
		dispatch(setCartPaymentTap2PayAccountDetailsError(null))
		localStorage.removeItem('appMode')
	}, [dispatch, disabledCloseButton])

	const handleRetryGetConnectionToken = useCallback(() => {
		fetchConnectionToken()
		getAccountDetailsThunk()
	}, [fetchConnectionToken])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 id="modal-tap2pay-availability-checker"
								 size="x-small"
								 title={t('Tap2PayModals.payment')}
								 disableBackButton={true}
								 zIndex={EVENT_Z_INDEX}
								 showCloseButton={!disabledCloseButton}
								 enableOutsideClick={!disabledCloseButton}
								 button={displayError && <ButtonsWrapper>
									 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel')} zIndex={EVENT_Z_INDEX} color="gray" onClick={handleClose} disabled={disabledCloseButton} />
									 {isErrorConnectionToken && <Button name="retry" icon="retry" text={t('Tap2PayModals.retry')} zIndex={EVENT_Z_INDEX} color="blue" onClick={handleRetryGetConnectionToken} disabled={disabledCloseButton} />}
								 </ButtonsWrapper>}>
			<ProcessPaymentTap2PayContent>
				{displayError ?
					<>
						<MainIcon icon="error" />
						<ProcessPaymentText className="error">{messageError}</ProcessPaymentText>
					</> :
					<>
						<HourglassLoader className="dark" />
						<ProcessPaymentText>{t('Tap2PayModals.availableCheck.text')}</ProcessPaymentText>
					</>
				}
			</ProcessPaymentTap2PayContent>
		</CustomModal>
	)

})
ModalTap2PayAvailabilityChecker.displayName = 'ModalTap2PayAvailabilityChecker'
export default ModalTap2PayAvailabilityChecker
