import { SET_SHOW_CHANGE_PAYMENT_AMOUNT_MODAL, SET_SHOW_CHANGE_TIPS_MODAL, SET_SHOW_PAYMENT_TAP_TO_PAY_MODAL, SET_SHOW_PROCESS_PAYMENT_TAP_TO_PAY_MODAL, SET_SHOW_SUCCEEDED_PAYMENT_TAP_TO_PAY_MODAL, SET_SHOW_TAP_TO_PAY_AVAILABILITY_CHECKER_MODAL, SET_SHOW_TIP_SELECTION_MODAL, SET_TAP_TO_PAY_TIPS } from '../../actionTypes/tap2Pay'

const initialState = {
	tap2PayTips: 0,
	modal: {
		showChangePaymentAmount: false,
		showTipSelection: false,
		showChangeTips: false,
		showPaymentTap2Pay: false,
		showProcessPaymentTap2Pay: false,
		showSucceededPaymentTap2Pay: false,
		showTap2PayAvailabilityChecker: false,
	},
}

export function tap2PayReducer (state = initialState, action) {
	switch (action.type) {
		case SET_TAP_TO_PAY_TIPS:
			return {
				...state,
				tap2PayTips: action.value,
			}
		case SET_SHOW_CHANGE_PAYMENT_AMOUNT_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showChangePaymentAmount: action.value,
				},
			}
		case SET_SHOW_TIP_SELECTION_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showTipSelection: action.value,
				},
			}
		case SET_SHOW_CHANGE_TIPS_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showChangeTips: action.value,
				},
			}
		case SET_SHOW_PAYMENT_TAP_TO_PAY_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showPaymentTap2Pay: action.value,
				},
			}
		case SET_SHOW_PROCESS_PAYMENT_TAP_TO_PAY_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showProcessPaymentTap2Pay: action.value,
				},
			}
		case SET_SHOW_SUCCEEDED_PAYMENT_TAP_TO_PAY_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showSucceededPaymentTap2Pay: action.value,
				},
			}
		case SET_SHOW_TAP_TO_PAY_AVAILABILITY_CHECKER_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showTap2PayAvailabilityChecker: action.value,
				},
			}
		default:
			return state
	}
}
