import React from 'react'
import { useSelector } from 'react-redux'
import { selectCompanyData } from '../../../../../../../redux/selector/system'
import { GetMoneyInputContainer, GetMoneyInputField, GetMoneyLabelText, GetMoneyWrapper } from './index.styled'
import { getCurrencySymbol } from '../../../../../../../helper'

const GetMoneyInput = React.memo(({ value, textForGetMoneyInput, onChange, onBlur, onFocus }) => {
	const currencyCode = useSelector(selectCompanyData('currency'))

	return (
		<GetMoneyWrapper>
			<GetMoneyLabelText htmlFor="calculator-sum">{textForGetMoneyInput} ({getCurrencySymbol(currencyCode)})</GetMoneyLabelText>
			<GetMoneyInputContainer>
				<GetMoneyInputField value={value}
														name="customer-sum"
														onChange={onChange}
														onBlur={onBlur}
														onFocus={onFocus}
														data-testid="customer-sum"
														inputMode="decimal" />
			</GetMoneyInputContainer>
		</GetMoneyWrapper>
	)
})

GetMoneyInput.displayName = 'GetMoneyInput'
export default GetMoneyInput
