import {
	APPEND_CART_PAYMENT_TERMINALS, SET_CART_PAYMENT_GIFT_COUPONS, SET_CART_PAYMENT_INVOICE_CONFIGS, SET_CART_PAYMENT_PAYMENTS, SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION, SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_IS_CREATING, SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_STARTED, SET_CART_PAYMENT_SELECTED_TERMINAL, SET_CART_PAYMENT_SESSION_CREATE_IS_LOADING, SET_CART_PAYMENT_SESSION_PROCESS_IS_LOADING, SET_CART_PAYMENT_TAP_2_PAY_ACCOUNT_DETAILS, SET_CART_PAYMENT_TAP_2_PAY_ACCOUNT_DETAILS_ERROR, SET_CART_PAYMENT_TAP_2_PAY_CONNECTION_TOKEN, SET_CART_PAYMENT_TAP_2_PAY_CONNECTION_TOKEN_ERROR, SET_CART_PAYMENT_TAP_2_PAY_CREATE_SESSION_ERROR, SET_CART_PAYMENT_TAP_2_PAY_ENABLED, SET_CART_PAYMENT_TAP_2_PAY_PAYMENT_SESSION, SET_CART_PAYMENT_TAP_2_PAY_PAYMENT_SESSION_INVALID, SET_CART_PAYMENT_TAP_2_PAY_RETRY_IS_LOADING, SET_CART_PAYMENT_TAP_2_PAY_SESSION_CREATE_IS_LOADING, SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION, SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION_INVALID, SET_CART_PAYMENT_TERMINAL_PROCESS_ERROR, SET_CART_PAYMENT_TERMINALS, SET_CART_PAYMENT_TERMINALS_LENGTH, SET_PROCESS_PAYMENT_IS_LOADING,
} from '../../actionTypes/cartPayment'

const initialState = {
	payments: [],
	terminals: [],
	terminalsLength: 0,
	selectedTerminal: null,
	terminalPaymentSession: null,
	terminalPaymentSessionInvalid: false,
	processPaymentIsLoading: false,
	paymentSessionCreateIsLoading: false,
	paymentSessionProcessIsLoading: false,
	terminalProcessError: null,
	invoiceConfigs: null,
	qrCodePaymentSession: null,
	qrCodePaymentSessionIsCreating: false,
	qrCodePaymentSessionStarted: false,
	giftCoupons: [],
	paymentSessionTap2PayCreateIsLoading: false,
	tap2PayPaymentSession: null,
	tap2PayPaymentSessionInvalid: false,
	paymentSessionTap2PayProcessIsLoading: false,
	tap2PayProcessError: null,
	tap2PayCreateSessionError: null,
	tap2PayConnectionToken: null,
	tap2PayConnectionTokenError: null,
	tap2PayEnabled: null,
	tap2PayAccountDetails: null,
	tap2PayAccountDetailsError: null,
	tap2PayRetryIsLoading: false,
}

export function cartPaymentReducer (state = initialState, action) {
	switch (action.type) {
		case SET_CART_PAYMENT_PAYMENTS:
			return {
				...state,
				payments: action.value,
			}
		case SET_CART_PAYMENT_TERMINALS:
			return {
				...state,
				terminals: action.value,
			}
		case APPEND_CART_PAYMENT_TERMINALS:
			return {
				...state,
				terminals: state.terminals.concat(...action.value),
			}
		case SET_CART_PAYMENT_TERMINALS_LENGTH:
			return {
				...state,
				terminalsLength: action.value,
			}
		case SET_CART_PAYMENT_SELECTED_TERMINAL:
			return {
				...state,
				selectedTerminal: action.value,
			}
		case SET_PROCESS_PAYMENT_IS_LOADING:
			return {
				...state,
				processPaymentIsLoading: action.value,
			}
		case SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION:
			return {
				...state,
				terminalPaymentSession: action.value,
			}
		case SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION_INVALID:
			return {
				...state,
				terminalPaymentSessionInvalid: action.value,
			}
		case SET_CART_PAYMENT_TERMINAL_PROCESS_ERROR:
			return {
				...state,
				terminalProcessError: action.value,
			}
		case SET_CART_PAYMENT_INVOICE_CONFIGS:
			return {
				...state,
				invoiceConfigs: action.value,
			}
		case SET_CART_PAYMENT_SESSION_CREATE_IS_LOADING:
			return {
				...state,
				paymentSessionCreateIsLoading: action.value,
			}
		case SET_CART_PAYMENT_SESSION_PROCESS_IS_LOADING:
			return {
				...state,
				paymentSessionProcessIsLoading: action.value,
			}
		case SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION:
			return {
				...state,
				qrCodePaymentSession: action.value,
			}
		case SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_IS_CREATING:
			return {
				...state,
				qrCodePaymentSessionIsCreating: action.value,
			}
		case SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_STARTED:
			return {
				...state,
				qrCodePaymentSessionStarted: action.value,
			}
		case SET_CART_PAYMENT_GIFT_COUPONS:
			return {
				...state,
				giftCoupons: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_SESSION_CREATE_IS_LOADING:
			return {
				...state,
				paymentSessionTap2PayCreateIsLoading: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_PAYMENT_SESSION:
			return {
				...state,
				tap2PayPaymentSession: action.value ? {
					...action.value,
					clientSecret: action.value.clientSecret ? action.value.clientSecret : state.tap2PayPaymentSession?.clientSecret
				} : action.value
			}
		case SET_CART_PAYMENT_TAP_2_PAY_PAYMENT_SESSION_INVALID:
			return {
				...state,
				tap2PayPaymentSessionInvalid: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_CREATE_SESSION_ERROR:
			return {
				...state,
				tap2PayCreateSessionError: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_CONNECTION_TOKEN:
			return {
				...state,
				tap2PayConnectionToken: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_CONNECTION_TOKEN_ERROR:
			return {
				...state,
				tap2PayConnectionTokenError: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_ENABLED:
			return {
				...state,
				tap2PayEnabled: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_ACCOUNT_DETAILS:
			return {
				...state,
				tap2PayAccountDetails: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_ACCOUNT_DETAILS_ERROR:
			return {
				...state,
				tap2PayAccountDetailsError: action.value,
			}
		case SET_CART_PAYMENT_TAP_2_PAY_RETRY_IS_LOADING:
			return {
				...state,
				tap2PayRetryIsLoading: action.value,
			}
		default:
			return state
	}
}
