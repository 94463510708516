import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ProductSearchWrap = styled.div`
	display: grid;
	grid-template-rows: min-content auto;
	font-size: 1rem;
	border-radius: 0.75em 0.75em 0 0;
	grid-row: 2;
	background-color: ${colorTheme.cart.productSearch.background};

	&.col-3 {
		grid-template-rows: min-content min-content auto;
	}

	&.touchPad {
		overflow-y: auto;
		padding: 0.63em;
		margin-left: -0.63em;

		&::-webkit-scrollbar {
			width: 1.12em;
			border-radius: 0.56em;
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		grid-row: 1;

		&.touchPad {
			margin: 0;

			&::-webkit-scrollbar {
				width: 0.38em;
				border-radius: 0.75em;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.touchPad {
			padding: 0.63em;
			overflow-x: visible;
		}
	}
`
ProductSearchWrap.displayName = 'ProductSearchWrap'

export const InputSearch = styled.div`
	position: relative;

	input {
		border-radius: 0.67em;
		width: 100%;
		height: 3.06em;
		border: 2px solid ${colorTheme.cart.productSearch.inputSearch.border};
		font-family: ${theme.fontRobotoMedium};
		font-size: 1.13em;
		line-height: 1.17em;
		color: ${colorTheme.cart.productSearch.inputSearch.text};
		padding: 0 1.28em;
		background-color: ${colorTheme.cart.productSearch.inputSearch.background};;

		&:focus {
			box-shadow: 0 4px 27px rgba(0, 0, 0, 0.22);
		}

		&::placeholder {
			color: ${colorTheme.cart.productSearch.inputSearch.placeholder};
		}
	}

	&.active input {
		border-color: ${colorTheme.cart.productSearch.inputSearch.border};
	}

	img {
		font-size: 1em;
		position: absolute;
		right: 0.94em;
		top: calc(50% - 0.7em);
		cursor: pointer;
		width: 2.31em;
		height: 1.44em;
	}
`
InputSearch.displayName = 'InputSearch'

export const ProductListWrapper = styled.div`
	overflow: auto;
	scroll-behavior: smooth;
	background: ${colorTheme.cart.productSearch.productList.backgroundWrapper};
	padding: 1.37em 0.63em 0 0.63em;
	border-radius: 0.75em 0.75em 0 0;

	&::-webkit-scrollbar {
		width: 1.12em;
		border-radius: 0.56em;
	}

	&.touchPad {
		overflow: initial;
		margin: 0;
		padding: 0;
		filter: none;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin: 0;
	}
`
ProductListWrapper.displayName = 'ProductListWrapper'
