import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { filterNumber, getFloat } from '../../../../helper'
import { setCartExtraDiscount, setCartExtraDiscountType } from '../../../../redux/action/cartDiscount'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { selectCurrencySymbol, selectIsNeededZIndex } from '../../../../redux/selector/system'
import KeyIcon from '../../buttons/KeyIcon'
import KeyBindings from '../../keybindings'
import { ExtraDiscountInput, ExtraDiscountLabel, ExtraDiscountTextWrap } from './index.styled'
import { CartTotalText, CartTotalValue } from '../index.styled'
import { CURRENCY, PERCENTS } from '../../../../redux/reducer/businessScopeDiscount/constants'
import Currency from '../../currency'
import Tooltip from '../../tooltip'
import { updateClientMonitorCartThunk } from '../../../../redux/thunk/clientMonitorCart'

const EVENT_Z_INDEX = 8

const ExtraDiscount = React.memo(({ type, extraDiscountInCurrency, previousIndex, hasWaiterDiscountError = false, maxDiscountForWaiter, isDisabled }) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const discount = useSelector(state => state.cartDiscount.extraDiscount)
	const discountType = useSelector(state => state.cartDiscount.extraDiscountType)
	const currencySymbol = useSelector(selectCurrencySymbol)
	const errorTooltipText = t('Cart.tooltip.discount_for_waiter_error', { maxDiscount: maxDiscountForWaiter })
	const dispatch = useDispatch()
	const discountInput = useRef(null)
	const [inputValue, setInputValue] = useState('')
	const layout = isNeededZIndex ? 'active' : 'static'

	useEffect(() => {
		setInputValue(`${discount}`.replace('.', ','))
	}, [discount])

	useEffect(() => {
		if (isNeededZIndex) {
			discountInput.current.focus()
			discountInput.current.select()
		}
	}, [isNeededZIndex])

	const handleDiscountChange = useCallback(e => {
		e.target.value = filterNumber(e.target.value).replace('.', ',')
		setInputValue(e.target.value)
		dispatch(updateClientMonitorCartThunk(type, () => dispatch(setCartExtraDiscount(getFloat(e.target.value)))))
	}, [dispatch, type])

	const handleArrowUp = useCallback(() => {
		dispatch(setAppEventZIndex(7))
	}, [dispatch])

	const handleArrowDown = useCallback(() => {
		dispatch(setAppEventZIndex(0))
	}, [dispatch])

	const handleEnter = useCallback(() => {
		dispatch(setAppEventZIndex(0))
	}, [dispatch])

	const handleF9 = useCallback(() => {
		dispatch(setAppEventZIndex(7))
	}, [dispatch])

	const handleFocusDiscount = useCallback(() => {
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
	}, [dispatch])

	const resetDiscount = useCallback((e) => {
		if (e.target.value === '') setInputValue('0')
	}, [])

	const handleDiscountTypeChange = useCallback(e => {
		dispatch(updateClientMonitorCartThunk(type,
			() => dispatch(setCartExtraDiscountType(e.target.value))))
	}, [dispatch, type])

	return (
		<>
			<ExtraDiscountLabel onClick={handleFocusDiscount}>
				<ExtraDiscountTextWrap>
					<CartTotalText className={layout}>{t('Cart.extraDiscount')}:</CartTotalText>
					<KeyIcon keyButton="F8" zIndex={EVENT_Z_INDEX} onKeyPress={handleFocusDiscount} />
				</ExtraDiscountTextWrap>
				<Tooltip isShow={hasWaiterDiscountError} text={errorTooltipText} position="top" margin="4">
					<ExtraDiscountInput className={layout}>
						<input type="text"
									 name="discount"
									 data-testid="extra-discount-input"
									 ref={discountInput}
									 onBlur={resetDiscount}
									 value={inputValue}
									 onChange={handleDiscountChange}
									 disabled={isDisabled} />
						<select name="discountType" data-testid="extra-discount-type" value={discountType} onChange={handleDiscountTypeChange} disabled={isDisabled}>
							<option value={PERCENTS}>%</option>
							<option value={CURRENCY}>{currencySymbol}</option>
						</select>
					</ExtraDiscountInput>
				</Tooltip>
				<CartTotalValue>
					-<Currency>{extraDiscountInCurrency}</Currency>
				</CartTotalValue>
			</ExtraDiscountLabel>

			{isNeededZIndex && <KeyBindings arrowUp={handleArrowUp} arrowDown={handleArrowDown} enter={handleEnter} f9={handleF9} />}
		</>
	)
})

ExtraDiscount.displayName = 'ExtraDiscount'
export default ExtraDiscount
