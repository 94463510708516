/* eslint no-console: off */
import React, { useCallback, useEffect, useState } from 'react'
import { ButtonsWrapper, ProcessPaymentTap2PayContent, ProcessPaymentText, TestBlockWrap, TestCardBlock } from '../index.styled'
import CustomModal from '../../../../../Elements/modal/CustomModal'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import HourglassLoader from '../../../../../Elements/loaders/HourglassLoader'
import { createTap2PayPaymentSessionThunk, deleteTap2PayPaymentSessionThunk, getTap2PayPaymentSessionThunk, resetTap2PayPaymentErrorsThunk } from '../../../../../../redux/thunk/cartPayment/tap2PayPayment'
import { setCartPaymentTap2PayPaymentSession, setCartPaymentTap2PayPaymentSessionInvalid, setCartPaymentTap2PayRetryIsLoading } from '../../../../../../redux/action/cartPayment'
import { v4 as uuidv4 } from 'uuid'
import { setShowProcessPaymentTap2PayModal, setShowSucceededPaymentTap2PayModal, setTap2PayTips } from '../../../../../../redux/action/tap2Pay'
import { isDevEnvironment, roundPrice } from '../../../../../../helper'
import Button from '../../../../../Elements/buttons/Button'
import MainIcon from '../../../../../../img/svg/main'
import { selectCompanyIndex } from '../../../../../../redux/selector/system'
import TextInputControlled from '../../../../../Elements/inputs/TextInput/controlled'
import { getErrorMessage } from '../helper'

const EVENT_Z_INDEX = 98
const ModalProcessPaymentTap2Pay = React.memo(({ totalPrice, paymentSource = 'RESTAURANT', handlePayment }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const companyIndex = useSelector(selectCompanyIndex)
	const isDev = isDevEnvironment()
	const isOpen = useSelector(store => store.tap2Pay.modal.showProcessPaymentTap2Pay)
	const tap2PayTips = useSelector(store => store.tap2Pay.tap2PayTips)
	const totalSumWithTips = roundPrice(totalPrice + tap2PayTips)
	const paymentSessionInvalid = useSelector(store => store.cartPayment.tap2PayPaymentSessionInvalid)
	const paymentSessionCreateIsLoading = useSelector(state => state.cartPayment.paymentSessionTap2PayCreateIsLoading)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const hasModalLogin = !!waiterAccessToken
	const retryIsLoading = useSelector(state => state.cartPayment.tap2PayRetryIsLoading)
	const paymentSession = useSelector(state => state.cartPayment.tap2PayPaymentSession)
	const paymentSessionId = paymentSession?.sessionId
	const paymentCanBeCollected = !!paymentSession
	const paymentIsCollected = paymentSession?.status === 'SUCCEEDED'
	const paymentIsCanceled = paymentSession?.status === 'CANCELED'
	const paymentOrderId = paymentSession?.orderId
	const [processingPayment, setProcessingPayment] = useState(false)
	const [handleCancelIsLoading, setHandleCancelIsLoading] = useState(false)
	const [testCardNumber, setTestCardNumber] = useState('')
	const disableCancelAndRetryButtons = handleCancelIsLoading || retryIsLoading || processingPayment || paymentSessionCreateIsLoading || (paymentSession?.status === 'CARD_VALIDATION_SUCCEEDED' && !paymentIsCollected && !paymentIsCanceled)
	const simulateButtonIsOpen = paymentCanBeCollected && !paymentIsCanceled

	const paymentError = (paymentSession?.status === 'CARD_VALIDATION_FAILED' && !retryIsLoading) || paymentSessionInvalid || paymentIsCanceled
	const createSessionError = useSelector(store => store.cartPayment.tap2PayCreateSessionError)
	const isError = createSessionError || paymentError
	const errorMessage = getErrorMessage(paymentSession, createSessionError, t)

	useEffect(() => {
		if (isOpen && !paymentSessionId) {
			dispatch(createTap2PayPaymentSessionThunk(totalSumWithTips, paymentSource, hasModalLogin))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	useEffect(() => {
		const interval = setInterval(() => {
			if (isOpen && paymentSessionId) {
				dispatch(getTap2PayPaymentSessionThunk(paymentSessionId, hasModalLogin))
			}
		}, 3000)

		return () => clearInterval(interval)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, paymentSessionId])

	useEffect(() => {
		if (isOpen && paymentCanBeCollected && !paymentOrderId) {
			setProcessingPayment(true)
			handlePlaceOrder(paymentSessionId)
		}
		// eslint-disable-next-line
	}, [paymentCanBeCollected, isOpen, paymentSessionId])

	useEffect(() => {
		if (isOpen && paymentOrderId && !isDev) {
			publishSessionCreated()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentOrderId, isOpen, isDev])

	useEffect(() => {
		if (isOpen && paymentIsCollected) {
			handleFinishTap2PayPaymentSession()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentIsCollected, isOpen])

	const publishSessionCreated = useCallback((isSimulate = false) => {
		console.log(`Publish session created: session=${paymentSessionId} clientSecret=${paymentSession?.clientSecret} companyIndex=${companyIndex}`)
		const json = {
			eventId: uuidv4(),
			traceId: uuidv4(),
			type: 'kassa-web.TAP_TO_PAY_SESSION_CREATED',
			context: 'kassa-web',
			eventTime: new Date().toISOString(),
			data: { clientSecret: paymentSession?.clientSecret, sessionId: paymentSessionId },
		}
		if (isSimulate) {
			json.data.testCard = testCardNumber
		}
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(JSON.stringify(json))
		}
	}, [paymentSessionId, paymentSession, testCardNumber, companyIndex])

	const handlePlaceOrder = useCallback(async (paymentId) => {
		try {
			await handlePayment(paymentId)
		} catch (e) {
			return dispatch(setCartPaymentTap2PayPaymentSessionInvalid(true))
		} finally {
			setProcessingPayment(false)
		}
	}, [dispatch, handlePayment])

	const handleReset = useCallback(async () => {
		dispatch(resetTap2PayPaymentErrorsThunk())
		dispatch(setCartPaymentTap2PayPaymentSession(null))
		setProcessingPayment(false)
	}, [dispatch])

	const handleFinishTap2PayPaymentSession = useCallback(async () => {
		console.log(`HandleFinishTap2PayPaymentSession paymentSessionId=${paymentSessionId}, companyIndex=${companyIndex}`)
		dispatch(resetTap2PayPaymentErrorsThunk())
		dispatch(setShowProcessPaymentTap2PayModal(false))
		dispatch(setShowSucceededPaymentTap2PayModal(true))
	}, [dispatch, companyIndex, paymentSessionId])

	const handleCancelSession = useCallback(async () => {
		if (processingPayment) return

		if (paymentSessionId && !paymentIsCanceled) {
			setHandleCancelIsLoading(true)
			console.log(`HandleCancel: session=${paymentSessionId} companyIndex=${companyIndex}`)
			try {
				await dispatch(deleteTap2PayPaymentSessionThunk(paymentSessionId, hasModalLogin))
			} catch (error) {
				console.error('Error deleting payment session:', error)
				return
			} finally {
				setHandleCancelIsLoading(false)
			}
		}
		await handleReset()
		dispatch(setTap2PayTips(0))
		dispatch(setShowProcessPaymentTap2PayModal(false))
	}, [dispatch, handleReset, paymentSessionId, paymentIsCanceled, companyIndex, hasModalLogin, processingPayment])

	const publishSessionRetryRequested = useCallback(() => {
		console.log(`Publish session retry requested: session=${paymentSessionId} clientSecret=${paymentSession?.clientSecret} companyIndex=${companyIndex}`)
		const json = {
			eventId: uuidv4(),
			traceId: uuidv4(),
			type: 'kassa-web.TAP_TO_PAY_SESSION_RETRY_REQUESTED',
			context: 'kassa-web',
			eventTime: new Date().toISOString(),
			data: { sessionId: paymentSessionId, clientSecret: paymentSession?.clientSecret },
		}

		if (isDev) {
			json.data.testCard = testCardNumber
		}

		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(JSON.stringify(json))
		}
	}, [paymentSession, paymentSessionId, isDev, testCardNumber, companyIndex])

	const handleRetryPayment = useCallback(async () => {
		dispatch(setCartPaymentTap2PayRetryIsLoading(true))
		dispatch(resetTap2PayPaymentErrorsThunk())
		console.log(`HandleRetryPayment: session=${paymentSessionId} companyIndex=${companyIndex}`)
		publishSessionRetryRequested()
		if (paymentIsCanceled) {
			dispatch(setCartPaymentTap2PayPaymentSession(null))
			await dispatch(createTap2PayPaymentSessionThunk(totalSumWithTips, paymentSource, hasModalLogin))
			dispatch(setCartPaymentTap2PayRetryIsLoading(false))
		}
	}, [dispatch, publishSessionRetryRequested, companyIndex, hasModalLogin, paymentIsCanceled, paymentSessionId, paymentSource, totalSumWithTips])

	const simulatePayment = () => {
		publishSessionCreated(true)
	}

	const handleClose = useCallback(() => {
		handleCancelSession()
	}, [handleCancelSession])

	const onChangeTestCardNumber = useCallback((e) => {
		setTestCardNumber(e.target.value)
	}, [])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 id="modal-process-payment-tap2pay"
								 size="x-small"
								 title={t('Tap2PayModals.payment')}
								 disableBackButton={true}
								 zIndex={EVENT_Z_INDEX}
								 showCloseButton={!disableCancelAndRetryButtons}
								 enableOutsideClick={!disableCancelAndRetryButtons}
								 button={isError && <ButtonsWrapper>
									 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel')} zIndex={EVENT_Z_INDEX} color="gray" onClick={handleCancelSession} disabled={disableCancelAndRetryButtons} />
									 <Button name="retry" icon="retry" text={t('Tap2PayModals.retry')} zIndex={EVENT_Z_INDEX} color="blue" onClick={handleRetryPayment} disabled={disableCancelAndRetryButtons} />
								 </ButtonsWrapper>}>
			<ProcessPaymentTap2PayContent className={isDev ? 'mg-small' : ''}>
				{isError ?
					<>
						<MainIcon icon="error" />
						<ProcessPaymentText className="error">{errorMessage}</ProcessPaymentText>
					</> :
					<>
						<HourglassLoader className="dark" />
						<ProcessPaymentText>{t('Tap2PayModals.process.preparingPaymentProcess')}</ProcessPaymentText>
					</>
				}
				{isDev && !disableCancelAndRetryButtons &&
					<TestBlockWrap>
						<TestCardBlock>
							<Button name="payment-fail" color="red" onClick={() => setTestCardNumber('4000000000000002')}>0002</Button>
							<Button name="payment-succeed" color="green" onClick={() => setTestCardNumber('4242424242424242')}>4242</Button>
							<TextInputControlled name="test-card-number" color="gray" size="small" value={testCardNumber} onChange={onChangeTestCardNumber} />
							{simulateButtonIsOpen && <Button name="process-payment" color="blue" onClick={simulatePayment} text="Simulate payment" />}
						</TestCardBlock>
					</TestBlockWrap>}
			</ProcessPaymentTap2PayContent>
		</CustomModal>
	)
})
ModalProcessPaymentTap2Pay.displayName = 'ModalProcessPaymentTap2Pay'
export default ModalProcessPaymentTap2Pay
