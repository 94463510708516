import { getDiscountSymbol } from '../../../../helper'
import { getActualItemsUnpaidOrder } from '../../../../helper/unpaidOrder'

const sortItemsByLastCoupon = (a, b) => {
	if (a.type === 'MARKETING_COUPON') return 1
	if (b.type === 'MARKETING_COUPON') return -1
	return 0
}

export const convert2ResultItems = (items, currencySymbol) => {
	const resultItems = items.reduce((acc, item) => {
		if (item.type === 'EXTRA_DISCOUNT') {
			return acc
		}
	
		if (item.type === 'MARKETING_COUPON') {
			const { initialProduct, quantity, type } = item
			const discountSymbol = getDiscountSymbol(initialProduct?.discountData.discountType, currencySymbol)
	
			return [
				...acc,
				{
					itemId: initialProduct?.id,
					type,
					quantity,
					name: initialProduct?.name
						? initialProduct?.name + ' ' + initialProduct?.discountData.discount + discountSymbol
						: '',
					discountData: initialProduct?.discountData,
				},
			]
		}
		return [...acc, item]
	}, [])

	return resultItems.sort(sortItemsByLastCoupon)
}

export const convertToClientMonitorPurchaseItems = (resultItems, discountValue, discountType, discount, extraDiscountValue, extraDiscountType, extraDiscount, deliveryCost, couponPrice) => {
	const filteredItems = getActualItemsUnpaidOrder(resultItems)
	const discountItem = { name: 'Discount', type: 'DISCOUNT', quantity: 1, price: -discount, discountMetaData: { value: discountValue, type: discountType } }
	const extraDiscountItem = { name: 'Extra Rabatt', type: 'EXTRA_DISCOUNT', quantity: 1, price: -extraDiscount, discountMetaData: { value: extraDiscountValue, type: extraDiscountType } }
	const deliveryCostItem = { name: 'Delivery cost', type: 'DELIVERY_COST', quantity: 1, price: deliveryCost }
	const items = [...filteredItems, discountItem, extraDiscountItem, deliveryCostItem]
		.map(el => el.type === 'MARKETING_COUPON' ? { ...el, price: -couponPrice } : el)
	return items
}

export const getExtraDiscountPrice = (items) => {
	const extraDiscountPrices = items.filter(item => item.type === 'EXTRA_DISCOUNT' && item.status !== 'CANCELED')?.[0]?.price
	return extraDiscountPrices || 0
}
