import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const ButtonIconWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5em;
	height: 1.56em;
	order: 0;
	flex-shrink: 0;

	> svg {
		width: 100%;
		height: 100%;
	}

	&.x-small {
		width: 0.94em;
		height: 0.94em;
	}

	&.x-big {
		width: 1.69em;
		height: 1.69em;
	}

	&.icon-auto-width {
		width: auto;
		height: 1.25em;

		> svg {
			width: auto;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.small {
			width: 1em;
			height: 1em;
		}
	}
`
ButtonIconWrap.displayName = 'ButtonIconWrap'
export const ButtonKey = styled.div`
	font-size: 0.69em;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 2.27em;
	height: 2.27em;
	padding: 0 0.36em;
	border-radius: 0.55em;
	border: 1px solid ${colorTheme.button.buttonKey.border};
	color: ${colorTheme.button.buttonKey.color};
	opacity: 0.7;
	line-height: 1em;
	font-family: ${theme.fontRobotoBold};
	text-transform: uppercase;

	&.key-small {
		font-size: 0.65em;
		height: 1.71em;
		opacity: 0.6;
		border-radius: 0.35em;
	}

	&.big {
		min-width: 2.58em;
		height: 2.58em;
		font-size: 0.75em;
	}

	&.gray {
		color: ${colorTheme.button.buttonKey.colorGray};
		border-color: ${colorTheme.button.buttonKey.borderGray};
	}

	@media screen and (max-width: ${theme.point820}) {
		display: none;
	}
`
ButtonKey.displayName = 'ButtonKey'

export const ButtonCount = styled.div`
	font-size: 0.75em;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 2em;
	height: 2em;
	padding: 0 0.33em;
	border-radius: 0.5em;
	color: ${colorTheme.button.count.color};
	font-family: ${theme.fontRobotoBold};
	line-height: 1.17em;
	background: ${colorTheme.button.count.background};

	&.big {
		min-width: 2.58em;
		height: 2.58em;
	}

	&.outline {
		background: ${colorTheme.button.count.backgroundOutline};
	}
`
ButtonCount.displayName = 'ButtonCount'

export const ButtonText = styled.div`
	font-size: 1.25em;
	display: flex;
	align-items: center;
	font-family: ${theme.fontRobotoMedium};
	color: ${props => props.textColor ? props.textColor : `${colorTheme.button.color}`};
	text-align: left;

	&.x-small {
		font-size: 1.06em;
		line-height: 0.88em;
	}

	&.small {
		font-size: 1.06em;
	}

	&.medium {
		font-size: 1.13em;
	}

	&.center {
		text-align: center;
	}

	&.bold {
		font-family: ${theme.fontRobotoBold};
		font-weight: bold;
	}

	@media screen and (max-width: ${theme.point720}) {
		white-space: nowrap;

		&.small {
			font-size: 0.85em;
		}
	}
`
ButtonText.displayName = 'ButtonText'
