import React from 'react'
import { CheckboxWrapper } from './index.styled'

const CheckBoxInput = ({ id, label, name, onChange, register, options, className, testId, disabled }) => {
	const layout = 'checkmark' + (disabled ? ' disabled' : '')
	return (
		<CheckboxWrapper id={id} className={className + (disabled ? ' disabled' : '')}>
			<input {...register(name, { ...options, onChange })}
						 type="checkbox"
						 data-testid={testId}
						 disabled={disabled} />
			<span className={layout} />
			<span className="label">{label}</span>
		</CheckboxWrapper>
	)
}

CheckBoxInput.displayName = 'CheckBoxInput'
export default CheckBoxInput
