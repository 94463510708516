export const SET_CANCEL_REASON = 'SET_CANCEL_REASON'
export const SET_ORDER_EVENT = 'SET_ORDER_EVENT'
export const SET_ORDER_USER_MODAL = 'SET_ORDER_USER_MODAL'
export const SET_ORDER_PROCEED_USER = 'SET_ORDER_PROCEED_USER'
export const SET_ORDER_CALCULATE_USER = 'SET_ORDER_CALCULATE_USER'
export const SET_ORDER_SHOW_PAYMENT_METHOD = 'SET_ORDER_SHOW_PAYMENT_METHOD'
export const SET_SELECTED_USER = 'SET_SELECTED_USER'
export const SET_SHOW_CANCEL_ORDER = 'SET_SHOW_CANCEL_ORDER'
export const SET_SHOW_ORDER = 'SET_SHOW_ORDER'
export const SET_SHOW_ORDER_DATA = 'SET_SHOW_ORDER_DATA'
export const SET_PROCESSED_BY_VALUES = 'SET_PROCESSED_BY_VALUES'
export const SET_SHOW_RECEIPT_PAYER_DATA = 'SET_SHOW_RECEIPT_PAYER_DATA'
