import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonsModal from '../../modal/ButtonsModal'
import { useDispatch, useSelector } from 'react-redux'
import { ModalPaymentWrapper } from './index.styled'
import { selectContainsAnyRole, selectFormattedCurrency, selectHasAnyGroup } from '../../../../redux/selector/system'
import ModalChangeCalculator from '../../changeCalculator'
import { PaymentCost, PaymentTitle, PaymentTitleWrapper } from '../index.styled'
import ModalTerminalButtons from '../ModalTerminalButtons'
import Currency from '../../currency'
import { createCartPaymentInvoiceConfigsThunk, getCartPaymentInvoiceConfigsThunk, getCartPaymentTerminalsThunk, getCartPaymentThunk } from '../../../../redux/thunk/cartPayment'
import ModalTerminalPaymentProcess from '../ModalTerminalPaymentProcess'
import { setCartPaymentGiftCoupons, setCartPaymentSelectedTerminal } from '../../../../redux/action/cartPayment'
import InvoiceModals from '../InvoiceModals'
import { QrCodeButtonText, TerminalButtonText } from './buttonTexts'
import ModalQrCodePayment from '../ModalQrCodePayment'
import ActivateCouponModal from '../Coupon/ActivateCouponModal'
import { calculateGiftCouponsBalance } from '../../../CashDesk/helper'
import { setGiftCoupon } from '../../../../redux/action/coupon'
import { capitalize, roundPrice } from '../../../../helper'
import { getGiftCouponsSum } from '../../../../helper/getGiftCouponsPayment'
import { getPaymentBusinessScope } from './helper'
import { selectDisabledTerminalTips } from '../../../../redux/selector/cartPayment'
import { isDisabledTerminalTips } from '../../../../redux/selector/cartPayment/helper'
import TextInputWithKeyboard from './TextInputWithKeyboard'
import { filterSearchString } from '../../../../helper/filterSearchString'
import { setCustomer } from '../../../../redux/action/customer'

const EVENT_Z_INDEX = 4
const PER_LOAD = 10
const STRIPE_MIN_ORDER_AMOUNT = 0.5

const ModalPayment = React.memo(({
	id = 'cart-payment', isOpen, closeModal, openModal,
	remark, totalSum, module, orderType, handlePayment, resetCart,
	changeCalculatorIsNeeded, isDeferredPayment = false, updatePaymentMode = false, isPreorder = false,
	isMoneyExchange = false, updateOrder = false, platformType = '', customerOrder,
}) => {
	const { t } = useTranslation()
	const customer = useSelector(store => store.customerStore.customer)
	const [remarkText, setRemarkText] = useState('')
	const [customerName, setCustomerName] = useState('')
	const [focusedInputName, setFocusedInputName] = useState(null)
	const [paymentMethod, setPaymentMethod] = useState('')
	const [modalChangeCalculatorIsOpen, setModalChangeCalculatorIsOpen] = useState(false)
	const [modalTerminalButtonsIsOpen, setModalTerminalButtonsIsOpen] = useState(false)
	const [modalTerminalPaymentProcessIsOpen, setModalTerminalPaymentProcessIsOpen] = useState(false)
	const [ecTerminalTips, setEcTerminalTips] = useState(0)
	const [modalInvoiceConfigsIsOpen, setModalInvoiceConfigsIsOpen] = useState(false)
	const [modalInvoiceCustomerDataIsOpen, setModalInvoiceCustomerDataIsOpen] = useState(false)
	const [modalQrCodePaymentIsOpen, setModalQrCodePaymentIsOpen] = useState(false)
	const [modalActivateCouponIsOpen, setModalActivateCouponIsOpen] = useState(false)
	const hasAdminRole = useSelector(selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN']))
	const hasRoleSettingsReadOrWrite = useSelector(selectContainsAnyRole(['PROGRAM_SETTINGS_READ', 'PROGRAM_SETTINGS_WRITE']))

	const payments = useSelector(store => store.cartPayment.payments)
	const terminals = useSelector(store => store.cartPayment.terminals)
	const terminalsLength = useSelector(store => store.cartPayment.terminalsLength)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const formattedEcTerminalLimitSum = useSelector(selectFormattedCurrency(STRIPE_MIN_ORDER_AMOUNT))
	const disabledTerminalTips = useSelector(selectDisabledTerminalTips(orderType))
	const hasModalLogin = !!waiterAccessToken
	const dispatch = useDispatch()

	const isNeededDeferredPayment = orderType === 'PICKUP' && !updatePaymentMode && !isDeferredPayment

	const isDelivery = orderType === 'DELIVERY'
	const isExistInvoicePaymentConfig = !!useSelector(state => state.cartPayment.invoiceConfigs)
	const disableInvoicePayment = !hasAdminRole && !isExistInvoicePaymentConfig

	const giftCoupon = useSelector(state => state.coupon?.giftCoupon)
	const giftCoupons = useSelector(state => state.cartPayment.giftCoupons)

	const giftCouponSum = giftCoupons?.length ? getGiftCouponsSum(giftCoupons) : 0
	const totalPriceWithGiftCoupon = totalSum >= 0 ? roundPrice(totalSum - giftCouponSum) : null

	const isTerminalPaymentLow = totalPriceWithGiftCoupon < STRIPE_MIN_ORDER_AMOUNT
	const paymentBusinessScope = getPaymentBusinessScope(orderType)

	useEffect(() => {
		if (isOpen) {
			dispatch(getCartPaymentThunk(paymentBusinessScope, hasModalLogin))
			dispatch(getCartPaymentTerminalsThunk(0, PER_LOAD, hasModalLogin))
			dispatch(setCartPaymentGiftCoupons([]))
		}
	}, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && hasRoleSettingsReadOrWrite) {
			dispatch(getCartPaymentInvoiceConfigsThunk())
		}
	}, [isOpen, hasRoleSettingsReadOrWrite]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && customer && customer.name) {
			setCustomerName(customer.name)
		}
	}, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (remark) setRemarkText(remark)
	}, [remark])

	useEffect(() => {
		if (isOpen) {
			const isExistGiftCoupon = giftCoupons?.findIndex(item => item?.id === giftCoupon?.id)
			if (giftCoupon && isExistGiftCoupon === -1) {
				let allGiftCoupons = [...giftCoupons, { ...giftCoupon, cartBalance: 0 }]
				const giftCouponsWithBalance = calculateGiftCouponsBalance(allGiftCoupons, totalSum)
				dispatch(setCartPaymentGiftCoupons(giftCouponsWithBalance))
				dispatch(setGiftCoupon(null))
			}
		}
	}, [giftCoupon, isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && totalPriceWithGiftCoupon <= 0 && giftCoupons?.length) {
			handlePayment({ paymentMethod: 'GIFT_COUPON', giftCoupons })
			handleClose()
		}
	}, [giftCoupons, isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	const resetRemark = useCallback(() => {
		setRemarkText('')
	}, [])

	const resetCustomerName = useCallback(() => {
		setCustomerName('')
	}, [])

	const resetInputValues = useCallback(() => {
		resetCustomerName()
		resetRemark()
	}, [resetCustomerName, resetRemark])

	const handleEcTerminalPaymentClick = useCallback(() => {
		if (terminalsLength > 1) {
			setModalTerminalButtonsIsOpen(true)
		} else {
			const disabledTips = isDisabledTerminalTips(terminals[0], orderType)
			dispatch(setCartPaymentSelectedTerminal(terminals[0]))
			if (isMoneyExchange || disabledTips) {
				setModalTerminalPaymentProcessIsOpen(true)
			} else {
				setModalChangeCalculatorIsOpen(true)
			}
		}
	}, [dispatch, terminals, terminalsLength, isMoneyExchange, orderType])

	const handleCashPaymentClick = useCallback((remark) => {
		if (changeCalculatorIsNeeded) {
			setModalChangeCalculatorIsOpen(true)
		} else {
			handlePayment({ paymentMethod: 'CASH', remark, giftCoupons })
			resetInputValues()
			dispatch(setCartPaymentGiftCoupons([]))
		}
	}, [dispatch, changeCalculatorIsNeeded, handlePayment, resetInputValues, giftCoupons])

	const openModalInvoiceCustomerData = useCallback(() => {
		if (isDeferredPayment && customerOrder) {
			dispatch(setCustomer({ ...customerOrder }))
		}
		setModalInvoiceCustomerDataIsOpen(true)
	}, [customerOrder, dispatch, isDeferredPayment])

	const handleInvoicePaymentClick = useCallback(() => {
		if (!isExistInvoicePaymentConfig) {
			setModalInvoiceConfigsIsOpen(true)
		} else {
			openModalInvoiceCustomerData()
		}
		resetCustomerName()
	}, [isExistInvoicePaymentConfig, resetCustomerName, openModalInvoiceCustomerData])

	const couponPayment = useCallback(() => {
		setPaymentMethod('GIFT_COUPON')
		setModalActivateCouponIsOpen(true)
	}, [])

	const handlePaymentClick = useCallback((paymentMethod) => {
		setPaymentMethod(paymentMethod)
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		const isECTerminal = paymentMethod === 'EC_TERMINAL'
		const isCash = paymentMethod === 'CASH'
		const isInvoice = paymentMethod === 'INVOICE'
		const isQrCode = paymentMethod === 'QR_CODE'

		if (isECTerminal) {
			handleEcTerminalPaymentClick()
		} else if (isCash) {
			handleCashPaymentClick(remarkNotEmpty)
		} else if (isInvoice) {
			handleInvoicePaymentClick()
		} else if (isQrCode) {
			setModalQrCodePaymentIsOpen(true)
		} else {
			handlePayment({ paymentMethod, remark: remarkNotEmpty, giftCoupons })
			resetInputValues()
		}
		closeModal(!isECTerminal && (!isQrCode && !isInvoice && !isDeferredPayment))

		dispatch(setCartPaymentGiftCoupons([]))
	}, [dispatch, closeModal, handlePayment, remarkText, setPaymentMethod, handleCashPaymentClick, handleEcTerminalPaymentClick, handleInvoicePaymentClick, resetInputValues, giftCoupons, isDeferredPayment])

	const handleDeferredPaymentClick = useCallback(() => {
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		handlePayment({ paymentMethod: 'CASH', remark: remarkNotEmpty, deferred: true })
		resetInputValues()
		closeModal(true)
	}, [closeModal, handlePayment, remarkText, resetInputValues])

	const closeModalTerminalPaymentProcess = useCallback(() => {
		dispatch(setCartPaymentSelectedTerminal(null))
		setModalTerminalPaymentProcessIsOpen(false)
	}, [dispatch])

	const handlePaymentTerminal = useCallback(async (paymentId, tipsFromTerminal) => {
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		await handlePayment({ paymentId, paymentMethod: 'EC_TERMINAL', tips: ecTerminalTips + tipsFromTerminal, remark: remarkNotEmpty, giftCoupons })
		resetInputValues()
		dispatch(setCartPaymentGiftCoupons([]))
	}, [dispatch, handlePayment, ecTerminalTips, remarkText, resetInputValues, giftCoupons])

	const handlePaymentInvoice = useCallback((customer) => {
		setModalInvoiceCustomerDataIsOpen(false)
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		handlePayment({ paymentMethod: 'INVOICE', remark: remarkNotEmpty, customer, giftCoupons })
		resetInputValues()
		dispatch(setCartPaymentGiftCoupons([]))
	}, [dispatch, handlePayment, remarkText, resetInputValues, giftCoupons])

	const disablePaymentButton = useCallback((paymentMethod) => {
		switch (paymentMethod) {
			case 'EC_TERMINAL':
				return !terminalsLength || isTerminalPaymentLow
			case 'INVOICE':
				return disableInvoicePayment
			default:
				return false
		}
	}, [terminalsLength, isTerminalPaymentLow, disableInvoicePayment])

	const showPaymentButton = useCallback((paymentMethod) => {
		switch (paymentMethod) {
			case 'INVOICE':
				return !updatePaymentMode && !isMoneyExchange
			case 'QR_CODE':
				return !isPreorder && !isDelivery && !updatePaymentMode && !isMoneyExchange && !updateOrder
			case 'CASH':
				return !isMoneyExchange
			case 'TAP_TO_PAY':
				return false
			default:
				return true
		}
	}, [isPreorder, isDelivery, updatePaymentMode, isMoneyExchange, updateOrder])

	const paymentButtonMessage = useCallback((paymentMethod) => {
		const isEcTerminal = paymentMethod === 'EC_TERMINAL'
		const isInvoice = paymentMethod === 'INVOICE'
		if (isInvoice && disableInvoicePayment) {
			return { text: t('Modal.payment.invoice_disable_message') }
		} else if (!isDelivery && isEcTerminal && isTerminalPaymentLow) {
			return { text: t('Modal.payment.terminal_disable_message', { sum: formattedEcTerminalLimitSum }) }
		} else {
			return null
		}
	}, [isDelivery, disableInvoicePayment, isTerminalPaymentLow, t, formattedEcTerminalLimitSum])

	const paymentButtonText = useCallback((paymentMethod) => {
		const isQrCode = paymentMethod === 'QR_CODE'
		const isEcCard = paymentMethod === 'EC_AT_DOORSTEP'
		const isEcTerminal = paymentMethod === 'EC_TERMINAL'

		if (isQrCode) {
			return <QrCodeButtonText text={t(`Payments.payments.${paymentMethod}`)} />
		} else if (isDelivery && (isEcCard || isEcTerminal)) {
			return <TerminalButtonText paymentMethod={isEcCard ? t('Payments.payments.portable_terminal') : t('Payments.payments.stationary_terminal')}
																 infoPaymentMethod={t(`Payments.payments.${paymentMethod}`)} />
		} else {
			return t(`Payments.payments.${paymentMethod}`)
		}
	}, [isDelivery, t])

	const dynamicPaymentButtons = useMemo(() => {
		const staticGiftCouponPayment = { icon: 'GIFT_COUPON', text: t('order.payment_type.coupon'), onClick: () => couponPayment(), show: isDeferredPayment && !isMoneyExchange, disabled: false, testId: 'paymentButton' }
		const staticOnlinePayment = { icon: 'ONLINE', text: t('order.payment_type.online'), onClick: () => handlePaymentClick('ONLINE'), show: !isDeferredPayment && !isMoneyExchange, disabled: false, testId: 'paymentButton' }
		const deferredPaymentButton = { icon: 'payment', text: t('order.payment_type.deferred_payment'), onClick: () => handleDeferredPaymentClick(), show: isNeededDeferredPayment && !isMoneyExchange, disabled: false, testId: 'paymentButton' }
		const dynamicPayments = payments.map(payment => {
			return ({
				icon: payment?.paymentMethod,
				text: paymentButtonText(payment.paymentMethod),
				onClick: () => handlePaymentClick(payment.paymentMethod),
				show: showPaymentButton(payment.paymentMethod),
				disabled: payment?.disabledForPay || disablePaymentButton(payment.paymentMethod),
				testId: 'paymentButton',
				message: paymentButtonMessage(payment.paymentMethod),
			})
		})
		dynamicPayments.splice(1, 0, staticOnlinePayment)
		return [staticGiftCouponPayment, ...dynamicPayments, deferredPaymentButton]
	}, [payments, handlePaymentClick, isDeferredPayment, isNeededDeferredPayment, handleDeferredPaymentClick, t, disablePaymentButton, showPaymentButton, isMoneyExchange, paymentButtonMessage, couponPayment, paymentButtonText])

	const onChangeRemark = useCallback((value) => {
		setRemarkText(value)
	}, [])

	const onChangeCustomerName = useCallback((value) => {
		const replaceForbiddenSymbol = filterSearchString(value)
		setCustomerName(capitalize(replaceForbiddenSymbol))
	}, [])

	const onInputFocus = useCallback((name) => {
		setFocusedInputName(name)
	}, [])

	const onBlurRemark = useCallback(() => {
		setFocusedInputName(null)
	}, [])

	const onBlurCustomerName = useCallback(() => {
		const customerNameTrimmed = customerName.trim() === '' ? null : customerName
		if (customerNameTrimmed) {
			dispatch(setCustomer({
				...customer,
				name: customerNameTrimmed,
			}))
		}
		setFocusedInputName(null)
	}, [dispatch, customer, customerName])

	const handleClose = useCallback(() => {
		dispatch(setCartPaymentGiftCoupons([]))
		resetInputValues()
		closeModal()
	}, [dispatch, closeModal, resetInputValues])

	const closeChangeModal = useCallback(() => {
		setModalChangeCalculatorIsOpen(false)
		dispatch(setCartPaymentSelectedTerminal(null))
		if (paymentMethod === 'EC_TERMINAL' && terminalsLength > 1) {
			setModalTerminalButtonsIsOpen(true)
		} else {
			openModal()
		}
	}, [openModal, terminalsLength, paymentMethod, dispatch])

	const handleModalChangePayment = useCallback(({ differenceSum }) => {
		if (paymentMethod === 'EC_TERMINAL') {
			setEcTerminalTips(differenceSum)
			setModalTerminalPaymentProcessIsOpen(true)
			setModalChangeCalculatorIsOpen(false)
		} else {
			setModalChangeCalculatorIsOpen(false)
			const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
			handlePayment({ paymentMethod: 'CASH', remark: remarkNotEmpty })
			resetInputValues()
		}
	}, [paymentMethod, remarkText, handlePayment, resetInputValues])

	const closeTerminalButtonsModal = useCallback(() => {
		setModalTerminalButtonsIsOpen(false)
		openModal()
	}, [openModal])

	const reOpenModalChangeCalculator = useCallback(() => {
		setModalTerminalPaymentProcessIsOpen(false)
		if (isMoneyExchange || disabledTerminalTips) {
			closeChangeModal()
		} else {
			setModalChangeCalculatorIsOpen(true)
		}
	}, [closeChangeModal, isMoneyExchange, disabledTerminalTips])

	const handleTerminalSelected = useCallback((item) => {
		setModalTerminalButtonsIsOpen(false)
		const disabledTips = isDisabledTerminalTips(item, orderType)
		if (isMoneyExchange || disabledTips) {
			setModalTerminalPaymentProcessIsOpen(true)
		} else {
			setModalChangeCalculatorIsOpen(true)
		}
	}, [isMoneyExchange, orderType])

	const submitInvoiceConfig = useCallback(async (data) => {
		await dispatch(createCartPaymentInvoiceConfigsThunk(data))
		setModalInvoiceConfigsIsOpen(false)
		// setModalInvoiceCustomerDataIsOpen(true)
		openModalInvoiceCustomerData()
	}, [dispatch, openModalInvoiceCustomerData])

	const closeInvoiceConfigsModal = useCallback(() => {
		setModalInvoiceConfigsIsOpen(false)
		openModal()
	}, [openModal])

	const closeInvoiceCustomerDataModal = useCallback(() => {
		setModalInvoiceCustomerDataIsOpen(false)
		openModal()
	}, [openModal])

	const closeModalQrCodePayment = useCallback(() => {
		setModalQrCodePaymentIsOpen(false)
	}, [])

	const closeModalActivateCoupon = useCallback(() => {
		setModalActivateCouponIsOpen(false)
	}, [])

	const handlePaymentQrCode = useCallback(async (paymentId, paymentLink) => {
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		await handlePayment({ paymentId, paymentLink, paymentMethod: 'QR_CODE', remark: remarkNotEmpty, giftCoupons })
		resetInputValues()
		dispatch(setCartPaymentGiftCoupons([]))
	}, [dispatch, handlePayment, remarkText, resetInputValues, giftCoupons])

	return (
		<ModalPaymentWrapper>
			<ButtonsModal id={id}
										isOpen={isOpen}
										close={handleClose}
										size="small"
										title={t('Modal.payment.title')}
										zIndex={EVENT_Z_INDEX}
										buttons={dynamicPaymentButtons}
										mobileIndentIsNeeded={true}>
				{giftCoupons?.length > 0 ?
					<PaymentTitleWrapper className="with-gift-coupon">
						<PaymentTitle>{t('Cart.leftToPay')}: </PaymentTitle>
						<PaymentCost><Currency>{totalPriceWithGiftCoupon}</Currency></PaymentCost>
						<PaymentTitle>({t('Cart.total')}: <Currency>{totalSum}</Currency>)</PaymentTitle>
					</PaymentTitleWrapper> :
					<PaymentTitleWrapper>
						<PaymentTitle>{t('Cart.titlePrice')}: </PaymentTitle>
						<PaymentCost><Currency>{totalSum}</Currency></PaymentCost>
					</PaymentTitleWrapper>}
				{orderType === 'PICKUP' && !updatePaymentMode && !isMoneyExchange &&
					<TextInputWithKeyboard name="customerName"
																 value={customerName}
																 onChange={onChangeCustomerName}
																 onBlur={onBlurCustomerName}
																 placeholder={t('AddOrder.Client.form.Name')}
																 disabled={focusedInputName && focusedInputName !== 'customerName'}
																 onFocus={onInputFocus} />}
				{!isDeferredPayment && !isMoneyExchange &&
					<TextInputWithKeyboard name="remark"
																 value={remarkText}
																 onChange={onChangeRemark}
																 onBlur={onBlurRemark}
																 placeholder={t('Modal.notice')}
																 disabled={focusedInputName && focusedInputName !== 'remark'}
																 onFocus={onInputFocus} />}
			</ButtonsModal>


			<ModalTerminalButtons isOpen={modalTerminalButtonsIsOpen}
														close={closeTerminalButtonsModal}
														totalPrice={totalPriceWithGiftCoupon}
														onTerminalSelected={handleTerminalSelected} />
			<ModalChangeCalculator isOpen={modalChangeCalculatorIsOpen}
														 close={closeChangeModal}
														 totalPrice={totalPriceWithGiftCoupon}
														 isEcTerminal={paymentMethod === 'EC_TERMINAL'}
														 handlePayment={handleModalChangePayment} />
			<ModalTerminalPaymentProcess isOpen={modalTerminalPaymentProcessIsOpen}
																	 totalPrice={totalPriceWithGiftCoupon + ecTerminalTips}
																	 navigateBack={reOpenModalChangeCalculator}
																	 close={closeModalTerminalPaymentProcess}
																	 handlePayment={handlePaymentTerminal}
																	 isMoneyExchange={isMoneyExchange}
																	 resetCart={resetCart}
																	 ecTerminalTips={ecTerminalTips}
																	 printReceipt={!updatePaymentMode || isDeferredPayment}
																	 module={module}
																	 orderType={orderType}
																	 platformType={platformType} />
			<InvoiceModals invoiceConfigsModalIsOpen={modalInvoiceConfigsIsOpen}
										 closeInvoiceConfigsModal={closeInvoiceConfigsModal}
										 submitInvoiceConfig={submitInvoiceConfig}
										 invoiceCustomerDataModalIsOpen={modalInvoiceCustomerDataIsOpen}
										 closeInvoiceCustomerDataModal={closeInvoiceCustomerDataModal}
										 handlePayment={handlePaymentInvoice}
										 captureCustomerData={isDelivery} />
			<ModalQrCodePayment isOpen={modalQrCodePaymentIsOpen}
													totalPrice={totalPriceWithGiftCoupon}
													close={closeModalQrCodePayment}
													openPaymentModal={openModal}
													handlePayment={handlePaymentQrCode}
													resetCart={resetCart}
													printReceipt={!updatePaymentMode || isDeferredPayment}
													module={module}
													orderType={orderType}
													platformType={platformType} />
			<ActivateCouponModal isOpen={modalActivateCouponIsOpen}
													 close={closeModalActivateCoupon}
													 isGiftCouponMode={true}
													 giftCouponsType="CART_PAYMENT" />

		</ModalPaymentWrapper>
	)
})

ModalPayment.displayName = 'ModalPayment'
export default ModalPayment
