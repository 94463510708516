import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const InputsWrapper = styled.div`
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.88em;
    margin-bottom: 0.88em;
    margin-top: 3.25em;

    @media screen and (max-width: ${theme.point820}) {
        gap: 1.25em;
        margin-top: 4.38em;

    }
`
InputsWrapper.displayName = 'InputsWrapper'

export const ChangeInput = styled.div`
    width: 100%;
    max-width: 25.37em;
    font-size: 1rem;
    height: 4.19em;
    font-family: ${theme.fontRobotoBold};
    background-color: ${colorTheme.cartModal.changeCalculator.input.change.background};
    border: 1px dashed ${colorTheme.cartModal.changeCalculator.input.change.border};
    border-radius: 0.75em;
    padding: 0.78em;
    box-shadow: none;

    display: flex;
    gap: .31em;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${theme.point820}) {
        max-width: 27.67em;
    }

    @media screen and (max-width: ${theme.point720}) {
        height: 3.44em;
        max-width: 100%;
    }
`

ChangeInput.displayName = 'ChangeInput'

export const ChangeInputText = styled.span`
    font-family: ${theme.fontRoboto};
    font-size: 1.5em;
    color: ${colorTheme.cartModal.changeCalculator.input.change.lightText};
`
ChangeInputText.displayName = 'ChangeInputText'

export const ChangeInputSum = styled.span`
    font-size: 1.5em;
    font-family: ${theme.fontRobotoBold};
    color: ${colorTheme.cartModal.changeCalculator.input.change.text};
`
ChangeInputSum.displayName = 'ChangeInputSum'

export const ButtonsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25em;

    &.single-button {
        display: flex;
        justify-content: flex-end;
    }

    @media screen and (max-width: ${theme.point820}) {
        max-width: 23.38em;
        grid-template-columns: 1fr ;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        & > :first-child {
            order: 2;
        }

        & > :last-child {
            order: 1;
        }

        &.single-button {
            display: grid;
        }
    }
`
ButtonsWrapper.displayName = 'ButtonsWrapper'

export const ChangePaymentAmountContent = styled.div`
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1.13em;
    margin-bottom: 18em;

    @media screen and (max-width: ${theme.point820}) {
        font-size: 1rem;
        padding: 0;
        margin: 2.5em auto 0;
    }
`
export const ChangeTipsContent = styled.div`
    display: grid;
    gap: 1.88em;
    max-width: 23.38em;
    margin: 2.5em auto 20.5em;

    @media screen and (max-width: ${theme.point820}) {
        font-size: 1rem;
        padding: 0;
        margin-bottom: 2.5em;
    }
`
ChangeTipsContent.displayName = 'ChangeTipsContent'

export const TipSelectionWrap = styled.div`
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 23.38em;
    gap: 1.25em;
    margin: 2.5em auto 0;

    @media screen and (max-width: ${theme.point820}) {
        font-size: 1rem;
        padding: 0;
        margin-bottom: 2.5em;
        margin-right: auto;
        margin-left: auto;
    }
`
TipSelectionWrap.displayName = 'TipSelectionWrap'

export const TipSelectionContent = styled.div`
    width: 100%;
    display: grid;
    align-items: center;
    gap: 1.25em;
    margin-bottom: 7.5em;
`
TipSelectionContent.displayName = 'TipSelectionContent'

export const PaymentTap2PayContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.56em;
    margin: 2.5em auto 25.75em;

    @media screen and (max-width: ${theme.point820}) {
        font-size: 1rem;
        padding: 0;
        margin-top: 10em;
    }
`
PaymentTap2PayContent.displayName = 'PaymentTap2PayContent'
export const SumContent = styled.div`
    max-width: 23.38em;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.56em;
`
SumContent.displayName = 'SumContent'

export const SumText = styled.div`
    color: ${colorTheme.Tap2PayModals.text};
    text-align: center;
    font-size: 1.25em;
    font-weight: 500;

    &.bold {
        font-weight: 700;
    }

    &.big {
        font-size: 2em;
    }
`
SumText.displayName = 'SumText'

export const Sum = styled.div`
    color: ${colorTheme.Tap2PayModals.sum};
    font-size: 3em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &.big {
        font-size: 4em;
    }
`
Sum.displayName = 'Sum'

export const ChangeTipsText = styled.div`
    color: ${colorTheme.Tap2PayModals.text};
    font-size: .88em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
ChangeTipsText.displayName = 'ChangeTipsText'

export const ProcessPaymentTap2PayContent = styled.div`
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.56em;
    margin: 5.31em auto 13em;

    > svg {
        width: 10em;
        height: 9.06em;
        fill: ${colorTheme.Tap2PayModals.error};
    }

    > .error {
        max-width: 16.88em;
    }

    &.mg-small {
        margin: 5.31em auto 10em;

    }

    @media screen and (max-width: ${theme.point820}) {
        margin-top: 10.18em;
    }
`
ProcessPaymentTap2PayContent.displayName = 'ProcessPaymentTap2PayContent'


export const ProcessPaymentText = styled.div`
    color: ${colorTheme.Tap2PayModals.text};
    text-align: center;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.error {
        color: ${colorTheme.Tap2PayModals.error};
    }
`
ProcessPaymentText.displayName = 'ProcessPaymentText'

export const TextInputWrapper = styled.div`
    display: grid;
    gap: .63em;
`
TextInputWrapper.displayName = 'TextInputWrapper'


export const SucceededPaymentTap2PayContent = styled.div`
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    align-items: center;
    justify-content: center;
    margin: 2.5em auto 13.63em;

`
SucceededPaymentTap2PayContent.displayName = 'SucceededPaymentTap2PayContent'

export const CircleSucceeded = styled.div`
    display: flex;
    width: 10em;
    height: 10em;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.25em auto;
    background: ${colorTheme.Tap2PayModals.circleSucceeded.background};

    > svg {
        width: 5.13em;
        height: 3.88em;
        flex-shrink: 0;
    }
`
CircleSucceeded.displayName = 'CircleSucceeded'

export const TestBlockWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    align-items: center;
    margin-top: 3em;
`
TestBlockWrap.displayName = 'TestBlockWrap'

export const TestCardBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`
TestCardBlock.displayName = 'TestCardBlock'
