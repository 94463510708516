import { createSelector } from 'reselect'
import { sortPosPrices } from './helper'

export const selectCategories = state => state.category.categories
const selectProduct = store => store.product.product
export const selectPosPrices = createSelector(
	selectCategories,
	selectProduct,

	(categories, product) => {
		if (!product) return []
		if (!product.category) return product.posPrices
		const category = categories?.find(category => category.id === product?.category?.id)

		const posPrices = product?.posPrices.map((item, index) => {
			const formattedPrice = []
			category?.priceDescriptions.forEach((categoryPrice, i) => {
				const price = item.prices.find(price => categoryPrice.id === price.priceTitle.id)
				formattedPrice.push(price)
			})
			return {
				...item,
				prices: formattedPrice
			}
		})

		return sortPosPrices(posPrices)
	}
)
