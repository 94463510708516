import React, { useEffect, useState } from 'react'
import ModalChangePaymentAmount from './modalChangePaymentAmount'
import ModalTipSelection from './modalTipSelection'
import ModalChangeTips from './modalChangeTips'
import ModalPaymentTap2Pay from './modalPaymentTap2Pay'
import ModalProcessPaymentTap2Pay from './modalProcessPaymentTap2Pay'
import ModalSucceededPaymentTap2Pay from './modalSucceededPaymentTap2Pay'
import ModalTap2PayAvailabilityChecker from './modalTap2PayAvailabilityChecker'
import { isMobileAppMode } from '../../../../../helper/mode'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompanyIndex } from '../../../../../redux/selector/system'
import { handleKassaAppListener } from './helper'

const Tap2PayModals = React.memo(({
	totalPrice, closeChangePaymentAmountModal, handlePayment, printReceipt = false, module,
	orderType, resetCart,
}) => {
	const dispatch = useDispatch()
	const isMobileApp = isMobileAppMode()
	const companyIndex = useSelector(selectCompanyIndex)
	const [hasListenerReady, setHasListenerReady] = useState(false)

	useEffect(() => {
		if (isMobileApp) {
			const messageListener = message => {
				handleKassaAppListener(message, dispatch, companyIndex)
			}
			window.addEventListener('message', messageListener, true)
			setHasListenerReady(true)

			return () => {
				setHasListenerReady(false)
				window.removeEventListener('message', messageListener, true)
			}
		}
	}, [ companyIndex, isMobileApp]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<ModalTap2PayAvailabilityChecker hasListenerReady={hasListenerReady}/>
			<ModalChangePaymentAmount totalPrice={totalPrice}
																close={closeChangePaymentAmountModal} />
			<ModalTipSelection totalPrice={totalPrice} />
			<ModalChangeTips totalPrice={totalPrice} />
			<ModalPaymentTap2Pay totalPrice={totalPrice} />
			<ModalProcessPaymentTap2Pay totalPrice={totalPrice}
																	handlePayment={handlePayment}
																	resetCart={resetCart} />
			<ModalSucceededPaymentTap2Pay totalPrice={totalPrice}
																		printReceipt={printReceipt}
																		module={module}
																		orderType={orderType}
																		resetCart={resetCart}/>
		</>

	)
})

Tap2PayModals.displayName = 'Tap2PayModals'
export default Tap2PayModals
