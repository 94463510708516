import React, { useCallback, useState } from 'react'
import { ButtonsWrapper, ChangeTipsContent, ChangeTipsText, Sum, SumContent, SumText, TextInputWrapper } from '../index.styled'
import Button from '../../../../../Elements/buttons/Button'
import CustomModal from '../../../../../Elements/modal/CustomModal'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Currency from '../../../../../Elements/currency'
import { getFloat, roundPrice } from '../../../../../../helper'
import TextInputControlled from '../../../../../Elements/inputs/TextInput/controlled'
import { setShowChangeTipsModal, setShowPaymentTap2PayModal, setShowTipSelectionModal, setTap2PayTips } from '../../../../../../redux/action/tap2Pay'
import { handleInputChange } from '../helper'

const EVENT_Z_INDEX = 96

const ModalChangeTips = React.memo(({ totalPrice }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isOpen = useSelector(store => store.tap2Pay.modal.showChangeTips)
	const roundedTotalPrice = roundPrice(totalPrice)
	const [customerGivenSum, setCustomerGivenSum] = useState('0')
	const customerGivenSumNumber = +customerGivenSum.replace(',', '.')

	const handleChange = (e) => {
		handleInputChange(e.target.value, setCustomerGivenSum)
	}

	const handleOnBlur = () => {
		const formattedValue = customerGivenSum ? (getFloat(customerGivenSum).toFixed(2).replace('.', ',')).toString() : customerGivenSum
		setCustomerGivenSum(formattedValue)
	}

	const handleFocus = () => {
		if (!+customerGivenSum) setCustomerGivenSum('')
	}

	const handleClose = useCallback(() => {
		dispatch(setShowChangeTipsModal(false))
		dispatch(setShowTipSelectionModal(true))
		setCustomerGivenSum('0')
	}, [dispatch])

	const handleGreenButtonClick = useCallback(() => {
		dispatch(setTap2PayTips(customerGivenSumNumber))
		dispatch(setShowPaymentTap2PayModal(true))
		dispatch(setShowChangeTipsModal(false))
		setCustomerGivenSum('0')
	}, [dispatch, customerGivenSumNumber])

	const handleBack = useCallback(() => {
		handleClose()
	}, [handleClose])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 id="modal-change-tips"
								 size="x-small"
								 title={t('Tap2PayModals.tip')}
								 disableBackButton={true}
								 zIndex={EVENT_Z_INDEX}
								 button={<ButtonsWrapper>
									 <Button name="back" icon="back" text={t('Cart.Buttons.back')} zIndex={EVENT_Z_INDEX} color="gray" onClick={handleBack} />
									 <Button name="send" icon="checkmark" text={t('buttons.controls.apply')} zIndex={EVENT_Z_INDEX} color="green" onClick={handleGreenButtonClick} />
								 </ButtonsWrapper>}>
			<ChangeTipsContent>
				<SumContent>
					<SumText>{t('Tap2PayModals.total')}:</SumText>
					<Sum><Currency>{roundedTotalPrice}</Currency></Sum>
					<SumText>{t('Tap2PayModals.addTips')}</SumText>
				</SumContent>
				<TextInputWrapper>
					<TextInputControlled name="discount"
															 label={t('Tap2PayModals.changeTips.label')}
															 testId="discount"
															 value={customerGivenSum}
															 onChange={handleChange}
															 onBlur={handleOnBlur}
															 color="gray"
															 onFocus={handleFocus} />
					<ChangeTipsText>{t('Tap2PayModals.changeTips.text')}</ChangeTipsText>
				</TextInputWrapper>
			</ChangeTipsContent>
		</CustomModal>
	)
})

ModalChangeTips.displayName = 'ModalChangeTips'
export default ModalChangeTips
