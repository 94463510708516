import React, { useCallback } from 'react'
import { ButtonsWrapper, PaymentTap2PayContent, Sum, SumText } from '../index.styled'
import Button from '../../../../../Elements/buttons/Button'
import CustomModal from '../../../../../Elements/modal/CustomModal'
import { useTranslation } from 'react-i18next'
import Currency from '../../../../../Elements/currency'
import { roundPrice } from '../../../../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { setShowPaymentTap2PayModal, setShowProcessPaymentTap2PayModal, setShowTipSelectionModal, setTap2PayTips } from '../../../../../../redux/action/tap2Pay'

const EVENT_Z_INDEX = 97
const ModalPaymentTap2Pay = React.memo(({ totalPrice }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const selectedTips = useSelector(store => store.tap2Pay.tap2PayTips)
	const isOpen = useSelector(store => store.tap2Pay.modal.showPaymentTap2Pay)

	const roundedTotalPrice = roundPrice(totalPrice + selectedTips)

	const handleGreenButtonClick = useCallback(() => {
		dispatch(setShowPaymentTap2PayModal(false))
		dispatch(setShowProcessPaymentTap2PayModal(true))
	}, [dispatch])

	const handleClose = useCallback(() => {
		dispatch(setShowPaymentTap2PayModal(false))
		dispatch(setShowTipSelectionModal(true))
		dispatch(setTap2PayTips(0))
	}, [dispatch])

	const handleBack = useCallback(() => {
		handleClose()
	}, [handleClose])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 id="modal-payment-tap2pay"
								 size="x-small"
								 title={t('Tap2PayModals.payment')}
								 disableBackButton={true}
								 zIndex={EVENT_Z_INDEX}
								 button={<ButtonsWrapper>
									 <Button name="editTips" icon="back" text={t('Tap2PayModals.editTip')} zIndex={EVENT_Z_INDEX} color="gray" onClick={handleBack} />
									 <Button name="send" icon="tap2Pay" text={t('Cart.Buttons.pay')} zIndex={EVENT_Z_INDEX} color="green" onClick={handleGreenButtonClick} />
								 </ButtonsWrapper>}>
			<PaymentTap2PayContent>
				<SumText className="big">{t('Tap2PayModals.total')}:</SumText>
				<Sum className="big"><Currency>{roundedTotalPrice}</Currency></Sum>
			</PaymentTap2PayContent>
		</CustomModal>
	)
})
ModalPaymentTap2Pay.displayName = 'ModalPaymentTap2Pay'
export default ModalPaymentTap2Pay
