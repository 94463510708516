import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { autocompleteHereThunkSilent, geoDataHereThunk } from '../../../../redux/thunk/geoCode/here-api'
import AutocompleteInput from '../AutocompleteInput'
import { useTranslation } from 'react-i18next'
import { getCurrencyCodeByCountryCode } from '../../../../helper'
import TextInput from '../TextInput'
import { AddressSearchWrapper } from './index.styled'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { selectCompanyData } from '../../../../redux/selector/system'
import { setAutocompleteGeo, setCustomerAddressIsNotFull } from '../../../../redux/action/customer'
import { filterAutocompleteSearchResult } from './helper'

const EVENT_Z_INDEX = 13

const AddressSearchInputs = ({
	firstInputName,
	secondInputName,
	dataName,
	defaultValue,
	firstLabel,
	secondLabel,
	firstTabIndex,
	secondTabIndex,
	register,
	errors,
	watch,
	setValue,
	formatAddress,
	countryDataRequired = true,
	geoDataRequired = true,
	geoDataPermissionIsNeeded = false,
	autocompleteRequest = false,
	size,
	color,
	setFocus = () => {
	},
	cols,
	returnZIndex = 0,
	countryCode = null,
	textArea = false, validationIsRequired = true,
	clearErrors = () => {},
	disabled,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [addressItems, setAddressItems] = useState([])
	const [browserLocation, setBrowserLocation] = useState(null)
	const companyLatitude = useSelector(selectCompanyData('latitude'))
	const companyLongitude = useSelector(selectCompanyData('longitude'))
	const companyLocation = (companyLatitude && companyLongitude) ? `${companyLatitude},${companyLongitude}` : null
	const watchDataNameLatitude = watch(`${dataName}.latitude`)
	const watchDataNameLongitude = watch(`${dataName}.longitude`)
	const locationAfterChooseStreet = (watchDataNameLatitude && watchDataNameLongitude) ? `${watchDataNameLatitude},${watchDataNameLongitude}` : companyLocation
	const customerAddressIsNotFull = useSelector(store => store.customerStore.customerAddressIsNotFull)
	const watchDataNameHouse = watch(`${dataName}.house`)
	const watchDataNameStreet = watch(`${dataName}.street`)
	const watchDataNameZipCode = watch(`${dataName}.zipCode`)
	const watchDataNameCity = watch(`${dataName}.city`)
	const watchDataNameCountryName = watch(`${dataName}.countryName`)

	useEffect(() => {
		if (addressItems && addressItems.length > 0) {
			dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		}
		return () => {
			dispatch(setAppEventZIndex(returnZIndex))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!addressItems?.length])

	useEffect(() => {
		if (geoDataPermissionIsNeeded && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setBrowserLocation(`${position.coords.latitude},${position.coords.longitude}`)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (customerAddressIsNotFull && (watchDataNameCity || watchDataNameStreet || watchDataNameZipCode)) {
			dispatch(autocompleteHereThunkSilent(countryCode, companyLocation, watch(firstInputName) + ' ' + (watch(secondInputName) ? watch(secondInputName) : ''), autocompleteRequest, res => {
				const filteredData = filterAutocompleteSearchResult(res)
				if (filteredData?.length === 1) {
					clickOnAutocompleteAddress(filteredData[0])
				} else {
					setAddressItems(filteredData)
				}
			}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerAddressIsNotFull, watch(firstInputName), watch(secondInputName)])

	const formatedAddressInput = useCallback((data) => {
		if (typeof formatAddress === 'function') {
			return formatAddress(data)
		} else {
			return `${data.street}, ${data.postalCode} ${data.city}, ${data.countryName}`
		}
	}, [formatAddress])

	const handleAddressInput = value => {
		setValue(`${dataName}.countryName`, null)
		setValue(`${dataName}.countryCode`, null)
		setValue(`${dataName}.currency`, null)
		setValue(`${dataName}.city`, null)
		setValue(`${dataName}.street`, null)
		setValue(`${dataName}.zipCode`, null)
		setValue(`${dataName}.latitude`, null)
		setValue(`${dataName}.longitude`, null)
		setValue(`${dataName}.house`, null)
		setValue(secondInputName, null)
		clearErrors()
		dispatch(autocompleteHereThunkSilent(countryCode, companyLocation ? companyLocation : browserLocation, value + (watch(secondInputName) ? ' ' + watch(secondInputName) : ''), autocompleteRequest, res => {
			const filteredData = filterAutocompleteSearchResult(res)
			setAddressItems(filteredData)
		}))
	}

	const handleAddressHouseNumberInput = async (e) => {
		clearErrors()
		const value = e.target.value
		const valueLowerCase = value.toLowerCase()
		setValue(`${dataName}.house`, value)

		if (!autocompleteRequest && watch(`${firstInputName}`)) {
			const res = await dispatch( geoDataHereThunk(countryCode, locationAfterChooseStreet, watch(firstInputName) + ' ' + valueLowerCase))
			const { lat, lng } = res.items[0].position
				setValue(`${dataName}.latitude`, lat)
				setValue(`${dataName}.longitude`, lng)
		}
	}

	const clickOnAutocompleteAddress = async (data) => {
		if (data.address.houseNumber) {
			setValue(secondInputName, data.address.houseNumber)
			setValue(`${dataName}.house`, data.address.houseNumber)
		}

		setValue(firstInputName, formatedAddressInput(data.address))

		setValue(`${dataName}.countryName`, data.address.countryName)
		setValue(`${dataName}.countryCode`, data.address.countryCode)
		setValue(`${dataName}.currency`, getCurrencyCodeByCountryCode(data.address.countryCode))
		setValue(`${dataName}.city`, data.address.city)
		setValue(`${dataName}.street`, data.address.street)
		setValue(`${dataName}.zipCode`, data.address.postalCode)

		if (!autocompleteRequest) {
			const res = await dispatch(geoDataHereThunk(countryCode, locationAfterChooseStreet, watch(firstInputName)))
			const { lat, lng } = res.items[0].position
			setValue(`${dataName}.latitude`, lat)
			setValue(`${dataName}.longitude`, lng)
		}

		dispatch(setCustomerAddressIsNotFull(false))
		setFocus(secondInputName)
		setAddressItems([])
		dispatch(setAutocompleteGeo(false))
	}

	const handleEscape = useCallback(() => {
		setAddressItems([])
	}, [])

	const firstInputDefaultValue = defaultValue ?
		`${defaultValue.street}, ${defaultValue.zipCode} ${defaultValue.city}, ${defaultValue.countryName}` : null

	const secondInputDefaultValue = defaultValue ?
		`${defaultValue.house}` : null

	const validateAddress = useCallback(() => {
		if (!watchDataNameStreet) return t('app.validation.street')
		if (!watchDataNameZipCode) return t('app.validation.postalCode')
		if (!watchDataNameCity) return t('app.validation.city')
		if (countryDataRequired && (!watchDataNameCountryName)) return t('Registration.Company.Address.country')
		if (geoDataRequired && (!watchDataNameLatitude || !watchDataNameLongitude)) return t('app.validation.geoData')
		return true
	}, [watchDataNameStreet, watchDataNameZipCode, watchDataNameCity, watchDataNameCountryName, watchDataNameLatitude, watchDataNameLongitude, countryDataRequired, geoDataRequired, t])

	const validateHouseNumber = useCallback(() => {
		if (!watchDataNameHouse) return t('app.validation.streetNumber')
		return true
	}, [watchDataNameHouse, t])

	const errorParserAddress = useCallback((errors) => {
		return (
			errors[firstInputName]
			|| errors[dataName]?.countryName
			|| errors[dataName]?.countryCode
			|| errors[dataName]?.currency
			|| errors[dataName]?.city
			|| errors[dataName]?.street
			|| errors[dataName]?.zipCode
			|| errors[dataName]?.latitude
			|| errors[dataName]?.longitude
		)
	}, [dataName, firstInputName])

	const errorParserHouseNumber = useCallback((errors) => {
		return (
			errors[secondInputName]
			|| errors[dataName]?.house
		)
	}, [dataName, secondInputName])

	return (
		<AddressSearchWrapper className={cols}>
			<input {...register(`${dataName}.countryName`, countryDataRequired && validationIsRequired && { required: t('Registration.Company.Address.country') })} type="hidden" />
			<input {...register(`${dataName}.countryCode`, countryDataRequired && validationIsRequired && { required: t('Registration.Company.Address.country') })} type="hidden" />
			<input {...register(`${dataName}.currency`, countryDataRequired && validationIsRequired && { required: t('Registration.Company.Address.country') })} type="hidden" />
			<input {...register(`${dataName}.city`, { required: validationIsRequired ? t('Registration.Company.Address.city') : false })} type="hidden" />
			<input {...register(`${dataName}.street`, { required: validationIsRequired ? t('Registration.Company.Address.street') : false })} type="hidden" />
			<input {...register(`${dataName}.house`)} type="hidden" />
			<input {...register(`${dataName}.zipCode`, { required: validationIsRequired ? t('Registration.Company.Address.street') : false })} type="hidden" />
			<input {...register(`${dataName}.latitude`, { required: geoDataRequired && validationIsRequired ? t('Registration.Company.Address.street') : false })} type="hidden" />
			<input {...register(`${dataName}.longitude`, { required: geoDataRequired && validationIsRequired ? t('Registration.Company.Address.street') : false })} type="hidden" />

			<AutocompleteInput testId={firstInputName}
												 name={firstInputName}
												 defaultValue={firstInputDefaultValue}
												 label={firstLabel}
												 zIndex={EVENT_Z_INDEX}
												 returnZIndex={returnZIndex}
												 tabIndex={firstTabIndex}
												 errors={errors}
												 errorParser={errorParserAddress}
												 register={register}
												 options={{
													 required: validationIsRequired && t('CompanyData.Required'),
													 validate: validationIsRequired && validateAddress,
												 }}
												 items={addressItems}
												 Item={({ item }) => (<p>{item?.address?.label}</p>)}
												 watch={watch}
												 onAutoComplete={handleAddressInput}
												 setItems={(value) => setAddressItems(value)}
												 onClick={clickOnAutocompleteAddress}
												 onReset={handleEscape}
												 color={color}
												 size={size}
												 textArea={textArea}
												 disabled={disabled} />

			<TextInput label={secondLabel}
								 testId={secondInputName}
								 name={secondInputName}
								 defaultValue={secondInputDefaultValue}
								 tabIndex={secondTabIndex}
								 errors={errors}
								 errorParser={errorParserHouseNumber}
								 watch={watch}
								 onChange={handleAddressHouseNumberInput}
								 setValue={setValue}
								 register={register}
								 options={{
									 required: validationIsRequired && t('CompanyData.Required'),
									 validate: validationIsRequired && validateHouseNumber,
								 }}
								 color={color}
								 size={size}
								 disabled={disabled} />
		</AddressSearchWrapper>
	)
}

AddressSearchInputs.displayName = 'AddressSearchInputs'
export default AddressSearchInputs
