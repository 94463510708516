export const SET_CASH_DESK_ORDER_TYPE = 'SET_CASH_DESK_ORDER_TYPE'
export const SET_CASH_DESK_ITEMS = 'SET_CASH_DESK_ITEMS'
export const ADD_CASH_DESK_ITEM = 'ADD_CASH_DESK_ITEM'
export const ADD_CASH_DESK_SUBITEM = 'ADD_CASH_DESK_SUBITEM'
export const REMOVE_CASH_DESK_ITEM = 'REMOVE_CASH_DESK_ITEM'
export const REMOVE_CASH_DESK_SUBITEM = 'REMOVE_CASH_DESK_SUBITEM'
export const UPDATE_CASH_DESK_ITEM = 'UPDATE_CASH_DESK_ITEM'
export const SET_CASH_DESK_ITEM_FOCUS_INDEX = 'SET_CASH_DESK_ITEM_FOCUS_INDEX'
export const SET_CASH_DESK_PREORDER_DATE = 'SET_CASH_DESK_PREORDER_DATE'
export const SET_CASH_DESK_ORDER = 'SET_CASH_DESK_ORDER'
export const REMOVE_CASH_DESK_REMARK = 'REMOVE_CASH_DESK_REMARK'
export const ADD_CASH_DESK_REMARK_TO_ITEM = 'ADD_CASH_DESK_REMARK_TO_ITEM'
export const SET_CASH_DESK_MODAL_CHANGE_CALCULATOR_IS_NEEDED = 'SET_CASH_DESK_MODAL_CHANGE_CALCULATOR_IS_NEEDED'
export const REMOVE_CASH_DESK_COUPON = 'REMOVE_CASH_DESK_COUPON'
export const SET_CASH_DESK_SHOW_ORDER = 'SET_CASH_DESK_SHOW_ORDER'
export const SET_CASH_DESK_SHOW_ORDER_DATA = 'SET_CASH_DESK_SHOW_ORDER_DATA'
export const SET_CASH_DESK_SHOW_CANCEL_ORDER = 'SET_CASH_DESK_SHOW_CANCEL_ORDER'
export const SET_CASH_DESK_ORDER_SHOW_PAYMENT_METHOD = 'SET_CASH_DESK_ORDER_SHOW_PAYMENT_METHOD'
export const SET_CASH_DESK_CANCEL_REASON = 'SET_CASH_DESK_CANCEL_REASON'
export const SET_CASH_DESK_SHOW_ORDERS_TABLE = 'SET_CASH_DESK_SHOW_ORDERS_TABLE'
export const SET_CASH_DESK_SHOW_RECEIPT_PAYER_DATA = 'SET_CASH_DESK_SHOW_RECEIPT_PAYER_DATA'
export const SET_CASH_DESK_GIFT_COUPONS = 'SET_CASH_DESK_GIFT_COUPONS'

