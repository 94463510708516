import React, { useCallback } from 'react'
import { TipsButtonText, TipsButtonWrap } from './index.styled'
import { getPercentsTips } from '../../helper'
import { useSelector } from 'react-redux'
import { selectFormattedCurrency } from '../../../../../../../redux/selector/system'

const TipsButton = React.memo(({ index, button, title, active, onClick, totalSum }) => {
	const layout = active ? 'active ' : ''

	const totalPriceWithTips = getPercentsTips(button.value, totalSum) || 0
	const formattedTotalPrice = useSelector(selectFormattedCurrency(totalPriceWithTips))

	const handleClick = useCallback(() => {
		onClick(index, button.value)
	}, [onClick, index, button.value])

	return (
		<TipsButtonWrap onClick={handleClick} className={layout} data-testid={`tips-${title}`}>
			<TipsButtonText className={layout}>{title}</TipsButtonText>
			<TipsButtonText className={layout + 'small '}>{formattedTotalPrice}</TipsButtonText>
		</TipsButtonWrap>
	)
})

TipsButton.displayName = 'TipsButton'
export default TipsButton
