import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonsWrapper, Sum, SumContent, SumText, TipSelectionContent, TipSelectionWrap } from '../index.styled'
import Button from '../../../../../Elements/buttons/Button'
import CustomModal from '../../../../../Elements/modal/CustomModal'
import Currency from '../../../../../Elements/currency'
import { roundPrice } from '../../../../../../helper'
import { getPercentsTips } from '../helper'
import TipsButton from './tipsButton'
import { TipsButtonWrapper } from './index.styled'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { useDispatch, useSelector } from 'react-redux'
import { setShowChangePaymentAmountModal, setShowChangeTipsModal, setShowPaymentTap2PayModal, setShowTipSelectionModal, setTap2PayTips } from '../../../../../../redux/action/tap2Pay'

const EVENT_Z_INDEX = 95
const buttons = [{ text: '5%', value: 5 }, { text: '10%', value: 10 }, { text: '15%', value: 15 }]

const ModalTipSelection = React.memo(({ totalPrice }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isOpenTipSelectionModal = useSelector(store => store.tap2Pay.modal.showTipSelection)

	const roundedTotalPrice = roundPrice(totalPrice)
	const [selectedTipsIndex, setSelectedTipsIndex] = useState()

	const handleSelectTips = useCallback((index, value) => {
		const tips = getPercentsTips(totalPrice, value)
		setSelectedTipsIndex(index)
		dispatch(setTap2PayTips(tips))
		dispatch(setShowTipSelectionModal(false))
		dispatch(setShowPaymentTap2PayModal(true))
	}, [totalPrice, dispatch])

	const handleClose = useCallback(() => {
		dispatch(setShowTipSelectionModal(false))
		dispatch(setShowChangePaymentAmountModal(true))
	}, [dispatch])

	const handleBack = useCallback(() => {
		handleClose()
	}, [handleClose])

	const handleChangeOtherTips = useCallback(() => {
		dispatch(setShowTipSelectionModal(false))
		dispatch(setShowChangeTipsModal(true))
	}, [dispatch])

	const handlePayWithoutTips = useCallback(() => {
		dispatch(setShowTipSelectionModal(false))
		dispatch(setShowPaymentTap2PayModal(true))
	}, [dispatch])

	return (
		<CustomModal isOpen={isOpenTipSelectionModal}
								 close={handleClose}
								 id="modal-tip-selection"
								 size="x-small"
								 title={t('Tap2PayModals.tip')}
								 disableBackButton={true}
								 zIndex={EVENT_Z_INDEX}
								 button={<ButtonsWrapper>
									 <Button name="back" icon="back" text={t('Cart.Buttons.back')} zIndex={EVENT_Z_INDEX} color="gray" onClick={handleBack} />
								 </ButtonsWrapper>}>
			<TipSelectionWrap>
				<SumContent>
					<SumText>{t('Tap2PayModals.total')}:</SumText>
					<Sum><Currency>{roundedTotalPrice}</Currency></Sum>
					<SumText>{t('Tap2PayModals.addTips')}</SumText>
				</SumContent>
				<TipSelectionContent>
					<TipsButtonWrapper>
						{buttons.map((button, index) => (
							<TipsButton key={index}
													index={index}
													active={selectedTipsIndex === index}
													button={button}
													title={button.text}
													totalSum={totalPrice}
													onClick={handleSelectTips} />
						))}
					</TipsButtonWrapper>
					<Button name="otherTips"
									outline
									textColor={colorTheme.Tap2PayModals.btnOutline.text}
									text={t('Tap2PayModals.tipSelection.otherTips')}
									onClick={handleChangeOtherTips} />
					<Button name="withoutTips"
									outline
									textColor={colorTheme.Tap2PayModals.btnOutline.text}
									text={t('Tap2PayModals.tipSelection.withoutTips')}
					onClick={handlePayWithoutTips}/>
				</TipSelectionContent>
			</TipSelectionWrap>
		</CustomModal>)
})
ModalTipSelection.displayName = 'ModalTipSelection'
export default ModalTipSelection
