import { setAppErrors } from '../../../action/system'
import { getDateParameterString, retrieveErrorFromApi, setWorkingHoursHelper } from '../../../../helper'
import { appendRestaurantOrders, setRestaurantOrders, setRestaurantOrdersTotal, setRestaurantTableClosedErrorModal, updateRestaurantOrderStatus } from '../../../action/restaurant'
import getOrders from '../../../axios/orderview'
import { batch } from 'react-redux'
import setRestaurantOrderStatus from '../../../axios/order/setRestaurantOrderStatus'
import updateRestaurantOrder from '../../../axios/order/updateRestaurantOrder'
import { fetchPrintSettingsThunk } from '../../printReceipt'

export const fetchRestaurantOrdersThunk = (page, size) => dispatch => {
	return getOrders({
		dateFrom: getDateParameterString(setWorkingHoursHelper(new Date())),
		dateTo: getDateParameterString(setWorkingHoursHelper(new Date())),
		orderType: 'RESTAURANT',
		closed: false,
		page, size,
	}).then(res => {
		return batch(() => {
			if (page === 0) {
				dispatch(setRestaurantOrders(res.data))
			} else {
				dispatch(appendRestaurantOrders(res.data))
			}
			return dispatch(setRestaurantOrdersTotal(parseInt(res.headers['x-orders-total'])))
		})
	}).catch(error => {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const setRestaurantOrderStatusThunk = (orderId, status, reason, accessToken) => dispatch => {
	return setRestaurantOrderStatus(orderId, { status, reason }, accessToken)
		.then(() => {
			return dispatch(updateRestaurantOrderStatus({ orderId, status }))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateRestaurantOrderThunk = (orderId, order, accessToken) => async (dispatch, getState) => {
	try {
		const isEcTerminalPaymentMethod = order.paymentMethod === 'EC_TERMINAL'
		const isTap2PayPaymentMethod = order.paymentMethod === 'TAP_TO_PAY'
		const res = await updateRestaurantOrder(orderId, order, accessToken)
		const updatedOrderId = res.data.remoteResponse.orderId
		if (!isEcTerminalPaymentMethod && updatedOrderId && !isTap2PayPaymentMethod) {
			dispatch(fetchPrintSettingsThunk(updatedOrderId, { module: 'RESTAURANT', businessScope: 'RESTAURANT' }))
		}
	} catch (error) {
		const errorStatusCode = error.response.data?.apierror.errorStatusCode
		if (errorStatusCode === 'terminal_payment_session_invalid') {
			const paymentEC = order.payments.find(payment => payment.method === 'EC_TERMINAL')

			/* eslint no-console: off */
			console.error(`Update restaurant order: paymentId=${paymentEC.id} totalPrice=${paymentEC.total} companyIndex=${getState().login.companyIndex}`)
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			throw new Error(error.response?.data?.apierror?.message)
		} else if (errorStatusCode === 'table_order_is_not_exist') {
			return dispatch(setRestaurantTableClosedErrorModal(true))
		} else {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
	}
}
