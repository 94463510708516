import React, { useCallback } from 'react'
import { ButtonsWrapper, CircleSucceeded, SucceededPaymentTap2PayContent, Sum, SumContent, SumText } from '../index.styled'
import Button from '../../../../../Elements/buttons/Button'
import CustomModal from '../../../../../Elements/modal/CustomModal'
import { useTranslation } from 'react-i18next'
import Currency from '../../../../../Elements/currency'
import { roundPrice } from '../../../../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { setShowSucceededPaymentTap2PayModal, setTap2PayTips } from '../../../../../../redux/action/tap2Pay'
import { fetchPrintSettingsThunk } from '../../../../../../redux/thunk/printReceipt'
import { setCartPaymentTap2PayPaymentSession } from '../../../../../../redux/action/cartPayment'

const EVENT_Z_INDEX = 99
const ModalSucceededPaymentTap2Pay = React.memo(({ totalPrice, printReceipt, module, orderType, platformType, resetCart }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isOpen = useSelector(store => store.tap2Pay.modal.showSucceededPaymentTap2Pay)
	const tap2PayTips = useSelector(store => store.tap2Pay.tap2PayTips)
	const roundedTotalPrice = roundPrice(totalPrice + tap2PayTips)
	const paymentSession = useSelector(state => state.cartPayment.tap2PayPaymentSession)
	const paymentOrderId = paymentSession?.orderId

	const handleReset = useCallback(async () => {
		dispatch(setCartPaymentTap2PayPaymentSession(null))
		dispatch(setTap2PayTips(0))
		dispatch(setShowSucceededPaymentTap2PayModal(false))
	}, [dispatch])

	const handleClose = useCallback(async () => {
		await handleReset()
		resetCart()
	}, [resetCart, handleReset])

	const handleGreenButtonClick = useCallback(async () => {
		await handleReset()
		if (printReceipt) dispatch(fetchPrintSettingsThunk(paymentOrderId, { module, platformType, businessScope: orderType }))
		resetCart()
	}, [module, platformType, orderType, dispatch, printReceipt, paymentOrderId, handleReset, resetCart])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 id="modal-succeeded-payment-tap2pay"
								 size="x-small"
								 title={t('Tap2PayModals.payment')}
								 disableBackButton={true}
								 zIndex={EVENT_Z_INDEX}
								 button={
									 <ButtonsWrapper className="single-button">
										 <Button name="send" icon="checkmark" text={t('Tap2PayModals.finish')} zIndex={EVENT_Z_INDEX} color="green" onClick={handleGreenButtonClick} />
									 </ButtonsWrapper>}>
			<SucceededPaymentTap2PayContent>
				<SumContent>
					<SumText>{t('Tap2PayModals.total')}:</SumText>
					<Sum><Currency>{roundedTotalPrice}</Currency></Sum>
				</SumContent>
				<CircleSucceeded>
					<svg xmlns="http://www.w3.org/2000/svg" width="82" height="62" viewBox="0 0 82 62" fill="none">
						<path d="M71.3188 1.9328L28.8951 46.8737L10.735 27.183C8.43792 24.6943 4.52787 24.5157 2.00485 26.7949C-0.505616 29.0742 -0.684487 32.9458 1.61573 35.4375L24.2884 60.0101C25.1388 60.928 26.234 61.5656 27.4327 61.8428C27.8815 61.9413 28.3396 61.9998 28.8072 61.9998C30.5331 62.0122 32.1744 61.3099 33.3574 60.0687L80.3376 10.292C82.663 7.82493 82.5343 3.95639 80.0427 1.64944C77.5636 -0.651358 73.6504 -0.534316 71.3188 1.9328Z" fill="white" />
					</svg>
				</CircleSucceeded>
				<SumText>{t('Tap2PayModals.paymentSuccessful')}</SumText>
			</SucceededPaymentTap2PayContent>
		</CustomModal>
	)
})
ModalSucceededPaymentTap2Pay.displayName = 'ModalSucceededPaymentTap2Pay'
export default ModalSucceededPaymentTap2Pay
