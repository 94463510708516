import React, { useCallback } from 'react'
import { selectCompanyData } from '../../../../../redux/selector/system'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AutocompleteInput from '../../../inputs/AutocompleteInput'
import AddressSearchInputs from '../../../inputs/AddressSearchInputs'
import { setCustomersByCompanyName, setCustomersByName, setCustomersByNumber, setCustomersByPhone } from '../../../../../redux/action/customer'
import { searchCustomersByCompanyNameThunk, searchCustomersByNameThunk, searchCustomersByNumberThunk, searchCustomersByPhoneThunk, searchResetThunk } from '../../../../../redux/thunk/customer'
import { setAppEventZIndex } from '../../../../../redux/action/system'
import CheckBoxInput from '../../../inputs/CheckboxInput'
import { CustomerModalFormAddressWrapper, CustomerModalFormLine, CustomerModalFormNameContainer, CustomerModalFormWrapper } from '../../../form/CustomerModalForm/index.styled'
import { filterCompanyName, filterName, filterNumber, filterPhone, formatAddressInInput } from '../../../form/CustomerModalForm/helper'
import { CompanyNameAutocompleteItem, NameAutocompleteItem, NumberAutocompleteItem, PhoneAutocompleteItem } from '../../../inputs/AutocompleteInput/items'

const PHONE_EVENT_Z_INDEX = 103
const NUMBER_EVENT_Z_INDEX = 104
const NAME_EVENT_Z_INDEX = 105
const COMPANY_NAME_EVENT_Z_INDEX = 107

const MainPayerDataForm = ({
	zIndex,
	onSubmit, handleSubmit, register, errors, handleEscape, setValue, watch, setFocus, clearErrors,
	handleResetForm,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const customersByPhone = useSelector(store => store.customerStore.customersByPhone)
	const customersByNumber = useSelector(store => store.customerStore.customersByNumber)
	const customersByName = useSelector(store => store.customerStore.customersByName)
	const customersByCompanyName = useSelector(store => store.customerStore.customersByCompanyName)

	const companyCountryCode = useSelector(selectCompanyData('countryCode'))
	const watchPhone = watch('phoneNumber')
	const isCompany = watch('isCompany')

	const onAutoCompletePhone = useCallback(value => {
		if (value) {
			dispatch(searchCustomersByPhoneThunk(value))
		} else {
			dispatch(setCustomersByPhone(null))
		}
	}, [dispatch])

	const setItemsByPhone = useCallback(value => {
		dispatch(setCustomersByPhone(value))
	}, [dispatch])

	const onAutoCompleteCustomerNumber = useCallback(value => {
		if (value) {
			dispatch(searchCustomersByNumberThunk(value))
		} else {
			dispatch(setCustomersByNumber(null))
		}
	}, [dispatch])

	const setItemsByNumber = useCallback(value => {
		dispatch(setCustomersByNumber(value))
	}, [dispatch])

	const onAutoCompleteCustomerName = useCallback(value => {
		if (value && !watchPhone) {
			dispatch(searchCustomersByNameThunk(value))
		} else {
			dispatch(setCustomersByName(null))
		}
	}, [watchPhone, dispatch])

	const setItemsByName = useCallback(value => {
		dispatch(setCustomersByName(value))
	}, [dispatch])

	const onAutoCompleteCompanyName = useCallback(value => {
		if (value && isCompany && !watchPhone) {
			dispatch(searchCustomersByCompanyNameThunk(value))
		} else {
			dispatch(setCustomersByCompanyName(null))
		}
	}, [dispatch, isCompany, watchPhone])

	const setItemsByCompanyName = useCallback(value => {
		dispatch(setCustomersByCompanyName(value))
	}, [dispatch])

	const onSubmitData = useCallback((data) => {
		onSubmit(data)
	}, [onSubmit])

	const handleF2 = useCallback(() => {
		handleSubmit(onSubmitData)()
	}, [onSubmitData, handleSubmit])

	const handleCustomerClick = useCallback((data) => {
		handleResetForm(data)
		dispatch(searchResetThunk())
		dispatch(setAppEventZIndex(zIndex))
	}, [dispatch, zIndex, handleResetForm])

	return (
		<CustomerModalFormWrapper id="payer-data-form"
															data-testid="payer-data-form"
															onSubmit={handleSubmit(onSubmitData)}>
			<CustomerModalFormLine>
				<AutocompleteInput testId="payer-phone-number"
													 name="phoneNumber"
													 tabIndex="901"
													 zIndex={PHONE_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.Telefonnumer') + '*'}
													 errors={errors}
													 register={register}
													 options={{
														 required: t('app.validation.required'),
														 pattern: {
															 value: /^[0-9]{3,15}$/i,
															 message: t('app.validation.minNumber3'),
														 },
													 }}
													 items={customersByPhone}
													 Item={PhoneAutocompleteItem}
													 watch={watch('phoneNumber')}
													 onAutoComplete={onAutoCompletePhone}
													 filter={filterPhone}
													 setItems={setItemsByPhone}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 autoFocus={true}
													 color="gray"
													 inputMode="tel" />
				<AutocompleteInput testId="payer-number"
													 name="customerNumber"
													 tabIndex="902"
													 zIndex={NUMBER_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.customerNumber')}
													 errors={errors}
													 register={register}
													 options={{
														 pattern: {
															 value: /^[0-9]{1,150}$/i,
															 message: t('app.validation.number'),
														 },
													 }}
													 items={customersByNumber}
													 Item={NumberAutocompleteItem}
													 watch={watch('customerNumber')}
													 onAutoComplete={onAutoCompleteCustomerNumber}
													 filter={filterNumber}
													 setItems={setItemsByNumber}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 color="gray"
													 inputMode="tel" />
			</CustomerModalFormLine>
			<CustomerModalFormNameContainer className={isCompany ? 'columns' : ''}>
				<AutocompleteInput testId="payer-name"
													 name="name"
													 tabIndex="903"
													 zIndex={NAME_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.Name') + (!isCompany ? '*' : '')}
													 errors={errors}
													 register={register}
													 options={{
														 required: !isCompany && t('app.validation.required'),
													 }}
													 items={customersByName}
													 Item={NameAutocompleteItem}
													 watch={watch}
													 onAutoComplete={onAutoCompleteCustomerName}
													 filter={filterName}
													 setItems={setItemsByName}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 color="gray">
					<CheckBoxInput label={t('AddOrder.Client.form.Company')}
												 id="is-company"
												 name="isCompany"
												 testId="is-company"
												 watch={watch('isCompany')}
												 register={register} />
				</AutocompleteInput>
			</CustomerModalFormNameContainer>
			{isCompany &&
				<AutocompleteInput testId="payer-company"
													 name="companyName"
													 tabIndex="904"
													 zIndex={COMPANY_NAME_EVENT_Z_INDEX}
													 returnZIndex={zIndex}
													 label={t('AddOrder.Client.form.Company') + (isCompany ? '*' : '')}
													 errors={errors}
													 register={register}
													 options={{
														 required: isCompany && t('app.validation.required'),
													 }}
													 items={customersByCompanyName}
													 Item={CompanyNameAutocompleteItem}
													 watch={watch('companyName')}
													 onAutoComplete={onAutoCompleteCompanyName}
													 filter={filterCompanyName}
													 setItems={setItemsByCompanyName}
													 onClick={handleCustomerClick}
													 onReset={handleEscape}
													 onSubmit={handleF2}
													 color="gray" />}
			<CustomerModalFormAddressWrapper>
				<AddressSearchInputs firstInputName="address"
														 secondInputName="streetNumber"
														 dataName="customerAddress"
														 firstTabIndex="905"
														 secondTabIndex="906"
														 firstLabel={t('AddOrder.Client.form.Strasse') + '*'}
														 secondLabel={t('AddOrder.Client.form.Hausnummer') + '*'}
														 errors={errors}
														 watch={watch}
														 setValue={setValue}
														 setFocus={setFocus}
														 formatAddress={formatAddressInInput}
														 countryDataRequired={false}
														 geoDataRequired={false}
														 autocompleteRequest={true}
														 register={register}
														 countryCode={companyCountryCode}
														 returnZIndex={zIndex}
														 cols="cols-2"
														 color="gray"
														 clearErrors={clearErrors} />
			</CustomerModalFormAddressWrapper>
		</CustomerModalFormWrapper>
	)
}

MainPayerDataForm.displayName = 'MainCustomerDataForm'
export default MainPayerDataForm
