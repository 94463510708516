import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { ModalBody, ModalCloseBlock, ModalFooter, ModalHeader, ModalHeaderTitle, ModalHeaderTitleBlock, ModalHeaderTitleText, ModalMain, ModalMainBlock, ModalWrapper } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { setAppEventZIndex } from '../../../../redux/action/system'
import Button from '../../buttons/Button'
import { useTranslation } from 'react-i18next'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'
import MainIcon from '../../../../img/svg/main'

const DURATION = 100
const CustomFullScreenModal = React.memo(({
	isOpen = false, close, title, children, id, button, customHeaderTitle = null,
	enableOutsideClick = true, zIndex = 1, returnZIndex = 0,
	showBackButton, disableBackButton = false, dividedFooterLine = false, footerClassname,
	heading = false, titleUpperCase = false, titleColor, icon, titleInOneRow = false,
	fullSizeBody = false, bodyClassName, showCloseCross = true,
}) => {
	const [isShow, setIsShow] = useState(false)
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(zIndex))
	const dispatch = useDispatch()
	const footerLayout = (dividedFooterLine ? 'dividedFooterLine ' : '') + footerClassname + (!button ? ' center' : '')
	const titleLayout = (titleUpperCase ? 'titleUpperCase ' : '') + (titleColor ? titleColor : '')
	const layoutBody = (fullSizeBody ? 'fullSizeBody' : '') + (bodyClassName ? ' ' + bodyClassName : '')
	const nodeRef = useRef(null)

	useEffect(() => {
		if (isOpen) {
			dispatch(setAppEventZIndex(zIndex))
			setIsShow(true)
		}
		return () => {
			setIsShow(false)
			dispatch(setAppEventZIndex(returnZIndex))
		}
	}, [isOpen]) //eslint-disable-line react-hooks/exhaustive-deps

	const handleModalWrapperMouseDown = useCallback(() => {
		if (enableOutsideClick) {
			setIsShow(false)
			close()
		}
	}, [enableOutsideClick, close])

	const handleModalMainMouseDown = useCallback(e => {
		e.stopPropagation()
	}, [])

	return (
		<>
			<CSSTransition in={isShow}
										 timeout={DURATION}
										 classNames="modal"
										 unmountOnExit
										 nodeRef={nodeRef}>
				<ModalWrapper id={id}
											data-testid={id}
											onMouseDown={handleModalWrapperMouseDown}
											ref={nodeRef}>
					<ModalMain onMouseDown={handleModalMainMouseDown}>
						<ModalMainBlock>
							<ModalHeader heading={heading} className={titleLayout}>
								{customHeaderTitle ? customHeaderTitle : <ModalHeaderTitle className={titleInOneRow ? ' titleInOneRow' : ''}>
									<ModalHeaderTitleBlock>
										{icon && <MainIcon icon={icon} className="header-icon" />}
										<ModalHeaderTitleText>{title}</ModalHeaderTitleText>
									</ModalHeaderTitleBlock>
								</ModalHeaderTitle>}
								{showCloseCross &&
									<ModalCloseBlock onClick={close} className="btn-close-modal">
										<svg  viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
											<path d="M0.6 0.599219C1.3 -0.100781 2.5 -0.100781 3.3 0.599219L9.6 6.89922L15.9 0.599219C16.6 -0.100781 17.8 -0.100781 18.6 0.599219C19.3 1.29922 19.3 2.49922 18.6 3.29922L12.3 9.59922L18.6 15.8992C19.3 16.5992 19.3 17.7992 18.6 18.5992C18.2 18.8992 17.8 19.0992 17.3 19.0992C16.8 19.0992 16.3 18.8992 16 18.4992L9.7 12.1992L3.4 18.4992C2.9 18.7992 2.4 18.9992 1.9 18.9992C1.4 18.9992 0.9 18.7992 0.6 18.3992C-0.1 17.6992 -0.1 16.4992 0.6 15.6992L6.9 9.39922L0.6 3.19922C-0.2 2.49922 -0.2 1.29922 0.6 0.599219Z" />
										</svg>
									</ModalCloseBlock>}
							</ModalHeader>
							<ModalBody heading={heading} className={layoutBody}>{children}</ModalBody>
							<ModalFooter className={footerLayout}>
								{showBackButton &&
									<Button name="close" icon="clear" text={t('buttons.controls.cancel_1')} onClick={close} keyButton="Escape" keyText="esc" zIndex={zIndex} className="back" />}
								{button}
							</ModalFooter>
						</ModalMainBlock>
					</ModalMain>
				</ModalWrapper>
			</CSSTransition>
			{isNeededZIndex &&
				<KeyBindings escape={close} />}
		</>
	)
})

CustomFullScreenModal.displayName = 'CustomFullScreenModal'
export default CustomFullScreenModal
