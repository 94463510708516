import { SET_CANCEL_REASON, SET_ORDER_CALCULATE_USER, SET_ORDER_EVENT, SET_ORDER_PROCEED_USER, SET_ORDER_SHOW_PAYMENT_METHOD, SET_ORDER_USER_MODAL, SET_PROCESSED_BY_VALUES, SET_SELECTED_USER, SET_SHOW_CANCEL_ORDER, SET_SHOW_ORDER, SET_SHOW_ORDER_DATA, SET_SHOW_RECEIPT_PAYER_DATA } from '../../actionTypes/all-order'
import { setCancelReasonFoodoraOptions, setCancelReasonOptions } from './constants'

const initialState = {
  drivers: [],
  selectedUser: null,
  cancelReason: setCancelReasonOptions()[0].value,
  cancelReasonOptions: setCancelReasonOptions(),
	cancelReasonFoodoraOptions: setCancelReasonFoodoraOptions(),
  modal: {
    showSelectedUser: false,
    showOrder: false,
    showOrderData: false,
    showCancelOrder: false,
    showProceedUser: false,
    showCalculateUser: false,
    showPaymentMethod: false,
		showReceiptPayerData: false
  },
  event: { //TODO get rid
    eventTable: true,
    eventUser: true,
    eventOrder: false,
    eventCancelOrder: false,
    eventCall: false,
  },
  processedByValues: {
    ordersCount: 0,
    ordersCashCount: 0,
    ordersOnlineCount: 0,
    totalPrice: 0,
    cashTotalPrice: 0,
    cartTotalPrice: 0,
    onlineTotalPrice: 0,
    totalDistance: 0,
  },
}

export function allOrderReducer (state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_ORDER:
      return {
        ...state,
        modal: {
          ...state.modal,
          showOrder: action.payload.content,
        },
      }
    case SET_SHOW_ORDER_DATA:
      return {
        ...state,
        modal: {
          ...state.modal,
          showOrderData: action.payload.content,
        },
      }
    case SET_SHOW_CANCEL_ORDER:
      return {
        ...state,
        modal: {
          ...state.modal,
          showCancelOrder: action.payload.content,
        },
      }
    case SET_ORDER_USER_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          showSelectedUser: action.showSelectedUser,
        },
      }
    case SET_ORDER_PROCEED_USER:
      return {
        ...state,
        modal: {
          ...state.modal,
          showProceedUser: action.showSelectedProceedUser,
        },
      }
    case SET_ORDER_CALCULATE_USER:
      return {
        ...state,
        modal: {
          ...state.modal,
          showCalculateUser: action.showCalculateUser,
        },
      }
    case SET_ORDER_SHOW_PAYMENT_METHOD:
      return {
        ...state,
        modal: {
          ...state.modal,
          showPaymentMethod: action.showPaymentMethod,
        },
      }
    case SET_SHOW_RECEIPT_PAYER_DATA:
      return {
        ...state,
        modal: {
          ...state.modal,
					showReceiptPayerData: action.value,
        },
      }
    case SET_ORDER_EVENT: //TODO get rid if this functionality due of Z-layer model is presented
      return {
        ...state,
        event: {
          ...state.events,
          eventTable: action.payload.content.eventTable ?? state.event.eventTable,
          eventUser: action.payload.content.eventUser ?? state.event.eventUser,
          eventOrder: action.payload.content.eventOrder ?? state.event.eventOrder,
          eventCancelOrder: action.payload.content.eventCancelOrder ?? state.event.eventCancelOrder,
        },
      }
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.user,
      }
    case SET_CANCEL_REASON:
      return {
        ...state,
        cancelReason: action.cancelReason ? action.cancelReason : setCancelReasonOptions()[0].value,
      }
    case SET_PROCESSED_BY_VALUES:
      return {
        ...state,
        processedByValues: action.value,
      }
    default:
      return state
  }
}


