import styled from 'styled-components'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { theme } from '../../../../style/theme'

export const PayerModalWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1.25em;
	margin-bottom: 0.63em;
`
PayerModalWrapper.displayName = 'PayerModalWrapper'

export const PayerModalSubtitle = styled.div`
	color: ${colorTheme.modal.payerDataModal.subtitle};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.13em;
	font-weight: 500;
`
PayerModalSubtitle.displayName = 'PayerModalSubtitle'

export const PayerModalDescription = styled.div`
	color: ${colorTheme.modal.payerDataModal.description};
	font-size: 1.06em;
	font-weight: 400;
`
PayerModalDescription.displayName = 'PayerModalDescription'
