import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomModal from '../../../../../Elements/modal/CustomModal'
import { ButtonsWrapper, ChangeInput, ChangeInputSum, ChangeInputText, ChangePaymentAmountContent, InputsWrapper, Sum, SumContent, SumText } from '../index.styled'
import Button from '../../../../../Elements/buttons/Button'
import { getFloat, roundPrice } from '../../../../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormattedCurrency } from '../../../../../../redux/selector/system'
import Currency from '../../../../../Elements/currency'
import GetMoneyInput from './GetMoneyInput'
import { setShowChangePaymentAmountModal, setShowProcessPaymentTap2PayModal, setShowTipSelectionModal, setTap2PayTips } from '../../../../../../redux/action/tap2Pay'
import { handleInputChange } from '../helper'

const EVENT_Z_INDEX = 94

const ModalChangePaymentAmount = React.memo(({ totalPrice, close }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isOpen = useSelector(store => store.tap2Pay.modal.showChangePaymentAmount)
	const [customerGivenSum, setCustomerGivenSum] = useState('0')
	const customerGivenSumNumber = +customerGivenSum.replace(',', '.')
	const roundedTotalPrice = roundPrice(totalPrice)
	const differenceSum = (customerGivenSumNumber > roundedTotalPrice) ? customerGivenSumNumber - roundedTotalPrice : 0
	const formattedDifferenceSum = useSelector(selectFormattedCurrency(differenceSum))

	const onChange = (e) => {
		handleInputChange(e.target.value, setCustomerGivenSum)
	}

	const handleOnBlur = (e) => {
		const value = e.target.value
		const formattedValue = value ? (getFloat(value).toFixed(2).replace('.', ',')).toString() : value
		setCustomerGivenSum(formattedValue)
	}

	const handleFocus = () => {
		if (!+customerGivenSum) setCustomerGivenSum('')
	}

	const handleClose = useCallback(() => {
		setCustomerGivenSum('0')
		close()
	}, [close])

	const handleGreenButtonClick = useCallback(() => {
		if (customerGivenSumNumber) {
			dispatch(setTap2PayTips(differenceSum))
			dispatch(setShowProcessPaymentTap2PayModal(true))
		} else {
			dispatch(setShowTipSelectionModal(true))
		}
		setCustomerGivenSum('0')
		dispatch(setShowChangePaymentAmountModal(false))
	}, [dispatch, customerGivenSumNumber, differenceSum])

	const handleBack = useCallback(() => {
		setCustomerGivenSum('0')
		close()
	}, [close])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 id="modal-change-payment-amount"
								 size="x-small"
								 title={t('Tap2PayModals.changePaymentAmount.title')}
								 zIndex={EVENT_Z_INDEX}
								 disableBackButton={true}
								 button={<ButtonsWrapper>
									 <Button name="back" icon="back" text={t('Cart.Buttons.back')} zIndex={EVENT_Z_INDEX} color="gray" onClick={handleBack} />
									 <Button name="send" icon="tap2Pay" text={t('Cart.Buttons.pay')} zIndex={EVENT_Z_INDEX} color="green" onClick={handleGreenButtonClick} />
								 </ButtonsWrapper>}>
			<ChangePaymentAmountContent>
				<SumContent>
					<SumText>{t('Tap2PayModals.changePaymentAmount.amount')}:</SumText>
					<Sum><Currency>{roundedTotalPrice}</Currency></Sum>
				</SumContent>
				<InputsWrapper>
					<GetMoneyInput value={customerGivenSum}
												 textForGetMoneyInput={t('Tap2PayModals.changePaymentAmount.otherAmount')}
												 onChange={onChange}
												 onBlur={handleOnBlur}
												 onFocus={handleFocus} />
					<ChangeInput data-testid={'change-payment-amount-tips'}>
						<ChangeInputText>{t('Tap2PayModals.tip')}:</ChangeInputText>
						<ChangeInputSum>{formattedDifferenceSum}</ChangeInputSum>
					</ChangeInput>
				</InputsWrapper>
			</ChangePaymentAmountContent>

		</CustomModal>
	)
})
ModalChangePaymentAmount.displayName = 'ModalChangePaymentAmount'
export default ModalChangePaymentAmount
