import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import MainPayerDataForm from './main'
import { searchResetThunk } from '../../../../redux/thunk/customer'
import { printRequestsThunk } from '../../../../redux/thunk/order/printRequests'
import CustomModal from '../CustomModal'
import Button from '../../buttons/Button'
import { PayerModalDescription, PayerModalSubtitle, PayerModalWrapper } from './index.styled'
import { formatAddress } from '../../../../helper'
import { setCustomerAddressIsNotFull } from '../../../../redux/action/customer'
import { updatePayerThunk } from '../../../../redux/thunk/order/payer'

const EVENT_Z_INDEX = 102

const defaultValues = {
	customerNumber: null, companyName: null, name: null,
	phoneNumber: null, streetNumber: null,
	address: null, customerAddress: null, isCompany: true,
}

const PayerDataModal = React.memo(({ isOpen, close, order }) => {
	const { t } = useTranslation()
	const { handleSubmit, register, formState: { errors }, reset, setValue, watch, setFocus, clearErrors } = useForm({ defaultValues })
	const dispatch = useDispatch()
	const orderPayer = order?.payer
	const orderId = order?.orderId

	const handleResetForm = useCallback((values) => {
		reset({ ...defaultValues, ...values })
	}, [reset])

	const handleResetWithPayerData = useCallback((payer) => {
		handleResetForm({
			customerNumber: payer.customerNumber,
			companyName: payer.companyName,
			name: payer.name,
			phoneNumber: payer.phoneNumber,
			isCompany: !!payer.companyName,
			streetNumber: payer.streetNumber,
			address: payer ? formatAddress(payer.street, payer.streetNumber, payer.postalCode, payer.city) : null,
			customerAddress: {
				city: payer.city || null,
				street: payer.street || null,
				house: payer.streetNumber || null,
				zipCode: payer.postalCode || null,
			},
		})

		if (!payer.city || !payer.street || !payer.postalCode) {
			dispatch(setCustomerAddressIsNotFull(true))
		} else {
			dispatch(setCustomerAddressIsNotFull(false))
		}
	}, [dispatch, handleResetForm])

	useEffect(() => {
		if (isOpen && orderPayer) {
			handleResetWithPayerData(orderPayer)
		}
	}, [isOpen, orderPayer]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleReset = useCallback(() => {
		handleResetForm(defaultValues)
		dispatch(searchResetThunk())
	}, [handleResetForm, dispatch])

	const handleClose = useCallback(() => {
		handleReset()
		close()
	}, [handleReset, close])

	const onSubmit = useCallback(async (data) => {
		const dataValue = {
			customerNumber: data.customerNumber || null,
			companyName: data.isCompany ? data.companyName : null,
			name: data.name,
			phoneNumber: data.phoneNumber,
			city: data.customerAddress.city,
			street: data.customerAddress.street,
			postalCode: data.customerAddress.zipCode,
			streetNumber: data.customerAddress.house,
		}
		await dispatch(updatePayerThunk(orderId, dataValue))
		await dispatch(printRequestsThunk('ENTERTAINMENT_EXPENSES_RECEIPT', { orderId }))
		handleClose()
	}, [dispatch, orderId, handleClose])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 title={t('Modal.payerData.title')}
								 size="x-small"
								 id="payer-data-modal"
								 zIndex={EVENT_Z_INDEX}
								 disableBackButton={true}
								 button={
									 <>
										 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')}
														 keyButton="F3" zIndex={EVENT_Z_INDEX}
														 onClick={handleClose}
														 className="back" />
										 <Button name="print-receipt" color="green" icon="print" text={t('Modal.payerData.print_button')}
														 keyButton="F2" zIndex={EVENT_Z_INDEX}
														 onClick={handleSubmit(onSubmit)} />
									 </>
								 }>
			<PayerModalWrapper>
				<PayerModalSubtitle>{t('Modal.payerData.subtitle')}</PayerModalSubtitle>
				{isOpen &&
					<MainPayerDataForm {...{
						zIndex: EVENT_Z_INDEX,
						handleSubmit, onSubmit, register, errors, reset, setValue, setFocus, watch, handleEscape: handleReset, clearErrors,
						handleResetForm: handleResetWithPayerData,
					}} />}
				<PayerModalDescription>{'*' + t('Modal.payerData.description')}</PayerModalDescription>
			</PayerModalWrapper>
		</CustomModal>
	)
})

PayerDataModal.displayName = 'PayerDataModal'
export default PayerDataModal
