import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CartGrid, CartHeaderButtonWrapper } from '../../../Elements/cart/index.styled'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { selectIsOldReactAppVersion } from '../../../../redux/selector/system'
import { theme } from '../../../../style/theme'
import { selectCurrentOrdersInTableCart, selectGiftCouponInTableCart, selectSelectDishCourseIndex } from '../../../../redux/selector/restaurant'
import { addTableCartOrderItem, removeTableCartItemRemark, removeTableCartOrderItem, removeTableCartOrderSubItem, setTableCartItemFocusIndex, setTableCartModalCustomProductIsOpen, setTableCartNewOrder, setTableCartOrderFocusIndex } from '../../../../redux/action/tableCart'
import { batch, useDispatch, useSelector } from 'react-redux'
import { getCartProductThunk } from '../../../../redux/thunk/cartProduct'
import { clone, jwtContainsAnyGroup, parseJwt } from '../../../../helper'
import { setAppEventZIndex } from '../../../../redux/action/system'
import DishCourses from '../DishCourses'
import Cart from './Cart'
import KeyBindings from '../../../Elements/keybindings'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import Button from '../../../Elements/buttons/Button'
import CartProducts from './CartProducts'

const CartWithProducts = React.memo(({ zIndex, submitCart, onAdminLogin, setModalLoginIsOpen, setItemToDelete, adminAccessToken, createNewOrder }) => {
	const { t } = useTranslation()
	const { eventZIndex } = useSelector(state => state.system)
	const { accessToken } = useSelector(state => state.login)
	const [touchPad] = useLocalStorage('touchPad', false)
	const adminTokenIsValid = adminAccessToken && (new Date(parseJwt(adminAccessToken).exp * 1000)) > new Date()
	const isOldReactAppVersion = useSelector(selectIsOldReactAppVersion)
	const hasGiftCoupon = useSelector(selectGiftCouponInTableCart)
	const orders = useSelector(selectCurrentOrdersInTableCart)
	const orderFocusIndex = useSelector(state => state.tableCart.orderFocusIndex)
	const itemFocusIndex = useSelector(state => state.tableCart.itemFocusIndex)
	const newOrder = useSelector(state => state.tableCart.newOrder)
	const selectDishCourseIndex = useSelector(selectSelectDishCourseIndex)
	const showMobileCart = useSelector(state => state.tableCart.showMobileCart)
	const userIsNotAdmin = !jwtContainsAnyGroup(accessToken, ['OWNER', 'ADMIN', 'SUPERADMIN'])
	const isTablet = useWindowBreakPoint(theme.point820)
	const dispatch = useDispatch()
	const [restaurantNewOrder, setRestaurantNewOrder] = useLocalStorage('restaurantNewOrder', null)

	useEffect(() => {
		if (restaurantNewOrder) {
			dispatch(setTableCartNewOrder(restaurantNewOrder))
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (newOrder) {
			setRestaurantNewOrder(newOrder)
		}
		// eslint-disable-next-line
	}, [newOrder])

	const openCustomProduct = () => {
		dispatch(setTableCartModalCustomProductIsOpen(true))
	}

	const setFocus = (orderIndex, itemIndex) => {
		dispatch(setTableCartOrderFocusIndex(orderIndex))
		dispatch(setTableCartItemFocusIndex(itemIndex))
	}

	const handleOpenProduct = (orderIndex, itemIndex, item, e) => {
		e.stopPropagation()
		setFocus(orderIndex, itemIndex, item)
		if (!orders[orderIndex].id && item.type !== 'MENU') {
			dispatch(getCartProductThunk(item))
		}
	}

	const incrementQuantity = (event, item, itemIndex, orderIndex) => {
		event?.stopPropagation()
		const itemCopy = clone(item)
		const itemWithIncrementQuantity = { ...itemCopy, quantity: item.quantity + 1 }
		const orderCopy = clone(newOrder)
		orderCopy.items = newOrder.items.map((el, index) => index === itemIndex ? itemWithIncrementQuantity : el)
		dispatch(setTableCartOrderFocusIndex(orderIndex))
		dispatch(setTableCartItemFocusIndex(itemIndex))
		dispatch(setTableCartNewOrder(orderCopy))
	}

	const dropProduct = (event, orderIndex, itemIndex, isNewOrder, item) => {
		event?.stopPropagation()

		if (isNewOrder) {
			newOrder.items[itemIndex].quantity > 1
				? batch(() => {
					dispatch(setTableCartOrderFocusIndex(orderIndex))
					dispatch(setTableCartItemFocusIndex(itemIndex))
				})
				: batch(() => {
					dispatch(setTableCartOrderFocusIndex(null))
					dispatch(setTableCartItemFocusIndex(null))
				})
			dispatch(removeTableCartOrderItem({ itemIndex }))
		} else if (!adminTokenIsValid && userIsNotAdmin) {
			setItemToDelete({ orderIndex, itemIndex, item })
			setModalLoginIsOpen(true)
		} else if (adminTokenIsValid && userIsNotAdmin) {
			onAdminLogin(adminAccessToken, { orderIndex, itemIndex, item })
		} else {
			onAdminLogin(accessToken, { orderIndex, itemIndex, item })
		}
	}

	const dropTopping = (event, orderIndex, itemIndex, subItemIndex, isNewOrder) => {
		event?.stopPropagation()
		if (isNewOrder) {
			dispatch(removeTableCartOrderSubItem({ itemIndex, subItemIndex }))
		} else if (!adminTokenIsValid && userIsNotAdmin) {
			setItemToDelete({ orderIndex, itemIndex, subItemIndex })
			setModalLoginIsOpen(true)
		} else if (adminTokenIsValid && userIsNotAdmin) {
			onAdminLogin(adminAccessToken, { orderIndex, itemIndex, subItemIndex })
		} else {
			onAdminLogin(accessToken, { orderIndex, itemIndex, subItemIndex })
		}
	}

	const removeRemark = async (event, orderIndex, itemIndex) => {
		event?.stopPropagation()
		dispatch(removeTableCartItemRemark({ itemIndex }))
	}

	const addItemToNewOrder = (event, item) => {
		event?.stopPropagation()
		if (newOrder) {
			const itemInNewOrder = newOrder.items.find(el => el.itemId === item.itemId)
			const newOrderIndex = orders.findIndex(order => !order.id)

			if (itemInNewOrder) {
				const itemCopy = clone(itemInNewOrder)
				const itemWithIncrementQuantity = { ...itemCopy, quantity: itemCopy.quantity + 1 }
				const existedItemIndex = newOrder.items.findIndex(el => el.itemId === item.itemId)
				const orderCopy = clone(newOrder)
				orderCopy.items = newOrder.items.map((el) => el.itemId === item.itemId ? itemWithIncrementQuantity : el)

				dispatch(setTableCartOrderFocusIndex(newOrderIndex))
				dispatch(setTableCartItemFocusIndex(existedItemIndex))
				dispatch(setTableCartNewOrder(orderCopy))
			} else {
				const oneItemWithDishCourse = { ...item, quantity: 1, dishCourse: selectDishCourseIndex }
				const indexOfItem = newOrder.items.length
				dispatch(setTableCartOrderFocusIndex(newOrderIndex))
				dispatch(setTableCartItemFocusIndex(indexOfItem))
				dispatch(addTableCartOrderItem(oneItemWithDishCourse))
			}
		} else {
			const oneItemCopy = { ...item, quantity: 1 }
			createNewOrder(oneItemCopy)
		}
	}

	const handleTab = () => {
		dispatch(setAppEventZIndex(3))
	}

	const handleF10 = () => {
		dispatch(setAppEventZIndex(6))
	}

	const handleF9 = () => {
		dispatch(setAppEventZIndex(7))
	}

	const handleF3 = () => {
		openCustomProduct()
	}

	const handleDelete = () => {
		dropProduct(null, orderFocusIndex, itemFocusIndex, !orders[orderFocusIndex].id)
	}


	return (
		<>
			<CartGrid className={(touchPad ? 'touchPad ' : '') + (showMobileCart ? ' cart' : '') + (isOldReactAppVersion ? ' versionUpdater' : '')}>
				{!isTablet &&
					<CartHeaderButtonWrapper>
						<Button icon="plus" text={t('Cart.Buttons.extras')} keyButton="F3" color="blue" showTextForMobile={false}
										size="small" zIndex={zIndex} onClick={openCustomProduct} disabled={hasGiftCoupon} />
						<DishCourses />
					</CartHeaderButtonWrapper>}
				<Cart {...{ showTotal: true, handleOpenProduct, orders, orderFocusIndex, itemFocusIndex, zIndex: zIndex, setFocus, incrementQuantity, dropProduct, dropTopping, submitCart, removeRemark, addItemToNewOrder, hasGiftCoupon }} />
				<CartProducts createNewOrder={createNewOrder} submitCart={submitCart} />
			</CartGrid>

			{eventZIndex === zIndex &&
				<KeyBindings tab={handleTab}
										 f10={handleF10}
										 f9={handleF9}
										 f3={handleF3}
										 delet={handleDelete} />}
		</>
	)
})

CartWithProducts.displayName = 'CartWithProducts'
export default CartWithProducts
