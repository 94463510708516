import { orderTypeOptionsStatic } from '../../../reducer/order/constants'

export const sortPosPrices = (posPrices) => {
	return  orderTypeOptionsStatic.reduce((acc, currentOption) => {
		const foundOption = posPrices.find(posPrice => posPrice.name === currentOption.value)

		if (foundOption) {
			acc.push(foundOption)
		}

		return acc
	}, [])
}
