/* eslint no-console: off */
import { setCartPaymentTap2PayAccountDetails, setCartPaymentTap2PayAccountDetailsError, setCartPaymentTap2PayConnectionToken, setCartPaymentTap2PayConnectionTokenError, setCartPaymentTap2PayCreateSessionError, setCartPaymentTap2PayPaymentSession, setCartPaymentTap2PayPaymentSessionInvalid, setCartPaymentTap2PaySessionCreateIsLoading } from '../../../action/cartPayment'
import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import createTap2PayPaymentSession from '../../../axios/payment/tap2Pay/paymentSession/post'
import getTap2PayPaymentSession from '../../../axios/payment/tap2Pay/paymentSession/get'
import deleteTap2PayPaymentSession from '../../../axios/payment/tap2Pay/paymentSession/delete'
import getTap2PayConnectionToken from '../../../axios/payment/tap2Pay/paymentSession/connectionToken/post'
import accountDetails from '../../../axios/payment/tap2Pay/paymentSession/accountDetails'

export const getTap2PayConnectionTokenThunk = (hasModalLogin) => async (dispatch, getState) => {
	try {
		console.log(`Get tap2pay connection token: companyIndex=${getState().login.companyIndex}`)
		const res = await getTap2PayConnectionToken(hasModalLogin)
		console.log(`Got tap2pay connection token: companyIndex=${getState().login.companyIndex}`)
		dispatch(setCartPaymentTap2PayConnectionToken(res.data.connectionToken))
	} catch (error) {
		dispatch(setCartPaymentTap2PayConnectionTokenError(true))
	}
}
export const getAccountDetailsThunk = (hasModalLogin) => async (dispatch, getState) => {
	try {
		console.log(`Get tap2pay account details: companyIndex=${getState().login.companyIndex}`)
		const res = await accountDetails(hasModalLogin)
		console.log(`Got tap2pay account details: companyIndex=${getState().login.companyIndex}`)
		dispatch(setCartPaymentTap2PayAccountDetails(res.data))
	} catch (error) {
		dispatch(setCartPaymentTap2PayAccountDetailsError(true))
	}
}

export const createTap2PayPaymentSessionThunk = (totalSum, paymentSource, hasModalLogin) => async (dispatch, getState) => {
	dispatch(setCartPaymentTap2PaySessionCreateIsLoading(true))
	try {
		console.log(`Create Tap2Pay payment session: total=${totalSum} companyIndex=${getState().login.companyIndex}`)
		const res = await createTap2PayPaymentSession(totalSum, paymentSource, hasModalLogin)
		console.log(`Created Tap2Pay payment session: total=${totalSum} sessionId=${res.data.sessionId} and clientSecret=${res.data.clientSecret} companyIndex=${getState().login.companyIndex}`)
		dispatch(setCartPaymentTap2PayPaymentSession(res.data))
	} catch (error) {
		dispatch(setCartPaymentTap2PayCreateSessionError(true))
	} finally {
		dispatch(setCartPaymentTap2PaySessionCreateIsLoading(false))
	}
}

export const getTap2PayPaymentSessionThunk = (sessionId, hasModalLogin) => async (dispatch, getState) => {
	try {
		console.log(`Get tap2pay payment session: session=${sessionId} companyIndex=${getState().login.companyIndex}`)
		const res = await getTap2PayPaymentSession(sessionId, hasModalLogin)
		const tap2PayPaymentSession = getState().cartPayment.tap2PayPaymentSession
		if (tap2PayPaymentSession && tap2PayPaymentSession.sessionId === res.data.sessionId && res.data.status !== 'PROCESSING') {
			console.log(`Got tap2pay payment session: session=${sessionId} status=${res.data.status} companyIndex=${getState().login.companyIndex}`)
			dispatch(setCartPaymentTap2PayPaymentSession(res.data))
		}
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const deleteTap2PayPaymentSessionThunk = (sessionId, hasModalLogin) => async (dispatch) => {
	try {
		const res = await deleteTap2PayPaymentSession(sessionId, hasModalLogin)
		return res.data
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const resetTap2PayPaymentErrorsThunk = () => dispatch => {
	dispatch(setCartPaymentTap2PayPaymentSessionInvalid(false))
}
