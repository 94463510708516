import { retrieveErrorFromApi } from '../../../../helper'
import { setAppErrors } from '../../../action/system'
import printRequest from '../../../axios/order/printRequest'

export const printRequestsThunk = (layoutType, data) => dispatch => {
	return printRequest(layoutType, data)
		.then(res => {
		})
		.catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
