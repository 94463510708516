/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import searchProduct from '../../../redux/axios/product/searchProduct/index'
import { setAppEventZIndex, setToggled } from '../../../redux/action/system'
import { addItemAndCouponPrice, calcCartItemTotalPrice, clone, notifyToast, roundPrice } from '../../../helper'
import { useTranslation } from 'react-i18next'
import { addDeliveryCartItem, addDeliveryCartRemarkToItem, addDeliveryCartSubItem, removeDeliveryCartCoupon, removeDeliveryCartItem, removeDeliveryCartRemark, removeDeliveryCartSubItem, setDeliveryCartCurrentDeliveryCost, setDeliveryCartDefaultDeliveryCost, setDeliveryCartFreeFrom, setDeliveryCartGiftCoupon, setDeliveryCartItemFocusIndex, setDeliveryCartItems, setDeliveryCartMaxDistance, setDeliveryCartMinimumPrice, setDeliveryCartModalChangeCalculatorIsNeeded, setDeliveryCartOrder, setDeliveryCartOrderType, setDeliveryCartPreorderDate, setShowDeliveryConfigError, updateDeliveryCartItem } from '../../../redux/action/deliveryCart'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { setCartCategoryCategoryId, setCartCategoryProductType } from '../../../redux/action/cartCategory'
import ModalCustomProduct from '../../Elements/cart/ModalCustomProduct'
import CartProductSearch from '../../Elements/cart/CartProductSearch'
import { useNavigate } from 'react-router-dom'
import Cart from './Cart'
import { storeOrderThunk, updateOrderThunk, updateUnpaidOrderThunk } from '../../../redux/thunk/order'
import { setCustomer } from '../../../redux/action/customer'
import ModalPayment from '../../Elements/cart/ModalPayment'
import ModalDate from '../../Elements/cart/ModalDate'
import { setCartDiscount, setCartExtraDiscount, setCartExtraDiscountType } from '../../../redux/action/cartDiscount'
import { CartGrid } from '../../Elements/cart/index.styled'
import MainWrapper from '../../MainWrapper'
import { getCartProductThunk } from '../../../redux/thunk/cartProduct'
import KeyBindings from '../../Elements/keybindings'
import { getDeliveryCartModalChangeCalculatorIsNeededThunk } from '../../../redux/thunk/deliveryCart'
import { selectCartDiscountInCurrency, selectCartInitialDiscount, selectCouponDiscountInCurrency, selectCouponInCart, selectExtraDiscountInCurrency, selectGiftCouponInCart, selectGiftCouponSum, selectHasUnpaidOrderInCart, selectMoneyExchangeInCart, selectResultItemsInCart } from '../../../redux/selector/cart'
import { setGiftCoupon } from '../../../redux/action/coupon'
import ActionModal from '../../Elements/modal/ActionModal'
import { calculateGiftCouponsBalance } from '../../CashDesk/helper'
import { getGiftCouponsPayment } from '../../../helper/getGiftCouponsPayment'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import { CURRENCY } from '../../../redux/reducer/businessScopeDiscount/constants'
import { deleteClientMonitorCartThunk, updateClientMonitorCartThunk } from '../../../redux/thunk/clientMonitorCart'
import { getClientMonitorConfigThunk } from '../../../redux/thunk/clientMonitorConfig'
import { selectIsOldReactAppVersion } from '../../../redux/selector/system'
import ModalECTerminalWarning from '../../Elements/cart/ModalECTerminalWarning'
import DeliveryCartHeader from './header'
import { fetchPrintSettingsThunk } from '../../../redux/thunk/printReceipt'
import { cancelItem, cancelSubItem, getActualItemsUnpaidOrder, removeStatusPositionIdAndDecrementQuantity } from '../../../helper/unpaidOrder'
import CartHeaderButtons from './CartHeaderButtons'

const EVENT_Z_INDEX = 0
const MODULE = 'DELIVERY'

const DeliveryCart = React.memo(() => {
	const { t } = useTranslation()
	const deliveryCost = useSelector(store => store.customerStore.deliveryCost)
	const storedCustomer = useSelector(store => store.customerStore.customer)
	const geoData = useSelector(store => store.customerStore.geoData)
	const itemFocusIndex = useSelector(store => store.deliveryCart.itemFocusIndex)
	const orderType = useSelector(store => store.deliveryCart.orderType)
	const preOrderDate = useSelector(store => store.deliveryCart.preOrderDate)
	const freeFrom = useSelector(store => store.deliveryCart.freeFrom)
	const currentDeliveryCost = useSelector(store => store.deliveryCart.currentDeliveryCost)
	const order = useSelector(store => store.deliveryCart.order)
	const modalChangeCalculatorIsNeeded = useSelector(store => store.deliveryCart.modalChangeCalculatorIsNeeded)
	const initialCartDiscount = useSelector(selectCartInitialDiscount)
	const eventZIndex = useSelector(store => store.system.eventZIndex)
	const toggled = useSelector(store => store.system.toggled)
	const menu = useSelector(store => store.cartProduct.menu)
	const hasCoupon = useSelector(selectCouponInCart(MODULE))
	const hasGiftCoupon = useSelector(selectGiftCouponInCart(MODULE))
	const hasMoneyExchange = useSelector(selectMoneyExchangeInCart(MODULE))
	const isDisabled = hasGiftCoupon || hasMoneyExchange
	const items = useSelector(selectResultItemsInCart(MODULE))
	const isOldReactAppVersion = useSelector(selectIsOldReactAppVersion)
	const giftCoupon = useSelector(state => state.coupon?.giftCoupon)
	const dispatch = useDispatch()
	const [touchPad] = useLocalStorage('touchPad', false)
	const [modalCustomProductIsOpen, setModalCustomProductIsOpen] = useState(false)
	const [modalPaymentIsOpen, setModalPaymentIsOpen] = useState(false)
	const [modalPreOrderDateIsOpen, setModalPreOrderDateIsOpen] = useState(false)
	const [showMobileCart, setShowMobileCart] = useState(false)
	const giftCoupons = useSelector(store => store.deliveryCart.giftCoupons)
	const [giftCouponFocused, setGiftCouponFocused] = useState(null)
	const [modalAddGiftCouponOpen, setModalAddGiftCouponOpen] = useState(false)
	const [giftCouponAccepted, setGiftCouponAccepted] = useState()
	const navigate = useNavigate()
	const [showEsTerminalWarningModal, setShowEsTerminalWarningModal] = useState(false)

	const focusedItem = itemFocusIndex >= 0 && items ? items[itemFocusIndex] : null
	const itemsTotal = items?.reduce((tp, i) => tp + calcCartItemTotalPrice(i), 0)
	const discountInCurrency = useSelector(selectCartDiscountInCurrency(MODULE))
	const extraDiscountInCurrency = useSelector(selectExtraDiscountInCurrency(MODULE))
	const itemsAndDiscountTotal = itemsTotal - discountInCurrency - extraDiscountInCurrency
	const couponDiscountInCurrency = useSelector(selectCouponDiscountInCurrency(MODULE))
	const totalPrice = roundPrice(itemsAndDiscountTotal - couponDiscountInCurrency + (currentDeliveryCost ? currentDeliveryCost : 0))
	const giftCouponSum = useSelector(selectGiftCouponSum(MODULE))
	const priceWithGiftCoupons = roundPrice(totalPrice - giftCouponSum)
	const isOverFreeFrom = itemsAndDiscountTotal >= freeFrom
	const focusedItemRemark = items && items[itemFocusIndex]?.remark
	const itemExist = !!items?.length
	const isDelivery = orderType === MODULE
	const isTablet = useWindowBreakPoint(theme.point820)
	const payments = useSelector(store => store.cartPayment.payments)
	const existTerminal = payments.find(payment => payment.paymentMethod === 'EC_TERMINAL') || false
	const terminalsLength = useSelector(store => store.cartPayment.terminalsLength)
	const clientMonitorEnabled = useSelector(store => store.clientMonitorConfig.config?.enabled)

	const isUnpaidOrder = useSelector(selectHasUnpaidOrderInCart(MODULE))
	useEffect(() => {
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (orderType) {
			dispatch(getDeliveryCartModalChangeCalculatorIsNeededThunk(orderType))
		}

		return () => dispatch(setDeliveryCartModalChangeCalculatorIsNeeded(false))
	}, [orderType]) // eslint-disable-line react-hooks/exhaustive-deps

	const recalculateDeliveryCost = useCallback(() => {
		if (isDelivery) {
			if (freeFrom !== null && isOverFreeFrom && currentDeliveryCost !== 0) {
				dispatch(setDeliveryCartCurrentDeliveryCost(0))
			} else if (freeFrom !== null && !isOverFreeFrom && currentDeliveryCost !== deliveryCost) {
				dispatch(setDeliveryCartCurrentDeliveryCost(deliveryCost))
			}
		}
	}, [freeFrom, isOverFreeFrom, currentDeliveryCost, deliveryCost, dispatch, isDelivery])

	useEffect(() => {
		if (itemFocusIndex === null && items?.length > 0) {
			dispatch(setDeliveryCartItemFocusIndex(items.length - 1))
			setGiftCouponFocused(null)
		}
		recalculateDeliveryCost()
	}, [items]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const isExistGiftCoupon = giftCoupons.findIndex(item => item?.id === giftCoupon?.id)
		if (itemExist && giftCoupon && priceWithGiftCoupons <= 0 && !giftCouponAccepted) {
			setModalAddGiftCouponOpen(true)
		} else if (giftCoupon && isExistGiftCoupon === -1) {
			let allGiftCoupons = [...giftCoupons, { ...giftCoupon, cartBalance: 0 }]
			const giftCouponsWithBalance = calculateGiftCouponsBalance(allGiftCoupons, totalPrice)
			dispatch(setDeliveryCartGiftCoupon(giftCouponsWithBalance))
			dispatch(setGiftCoupon(null))
			dispatch(setDeliveryCartItemFocusIndex(null))
			setGiftCouponFocused(allGiftCoupons.length - 1)
			setGiftCouponAccepted(false)
		}
	}, [giftCoupon, giftCouponAccepted]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const giftCouponsWithBalance = calculateGiftCouponsBalance([...giftCoupons], totalPrice)
		dispatch(setDeliveryCartGiftCoupon(giftCouponsWithBalance))
	}, [totalPrice]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isDelivery && hasMoneyExchange) {
			handleDelete()
		}
	}, [isDelivery, hasMoneyExchange]) // eslint-disable-line react-hooks/exhaustive-deps

	const navigateBack = useCallback(() => {
		dispatch(setDeliveryCartOrderType(null))
		navigate('/delivery')
		setShowMobileCart(false)
	}, [dispatch, navigate])

	useEffect(() => {
		if (!orderType) {
			navigateBack()
		}
	}, [orderType]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(getClientMonitorConfigThunk())
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (clientMonitorEnabled) {
			dispatch(updateClientMonitorCartThunk(MODULE, () => {
			}))
		}
		// eslint-disable-next-line
	}, [clientMonitorEnabled])

	const handleOrderProcessing = () => {
		if (priceWithGiftCoupons <= 0 && itemExist && giftCoupons.length) {
			handlePayment({ paymentMethod: 'GIFT_COUPON', remark: null })
			return
		}
		if (isUnpaidOrder) {
			const actualItemsUnpaidOrder = getActualItemsUnpaidOrder(items)
			const extraDiscountItem = { name: 'Extra Rabatt', type: 'EXTRA_DISCOUNT', quantity: 1, price: -extraDiscountInCurrency }
			const orderData = { ...order, items: [...actualItemsUnpaidOrder, extraDiscountItem], totalPrice, payments: [{ ...order.payments[0], total: roundPrice(priceWithGiftCoupons) }], totalDiscount: discountInCurrency }
			dispatch(updateUnpaidOrderThunk(order.orderId, orderData))
			resetCart()
			return
		}

		if (hasMoneyExchange && (!existTerminal || !terminalsLength)) {
			setShowEsTerminalWarningModal(true)
			return
		}

		if (!menu) {
			if (items?.length) {
				setModalPaymentIsOpen(true)
			} else {
				notifyToast({ message: t('Cart.Toasts.item_first') }, 'warning')
			}
		}
	}

	const reOpenPaymentModal = useCallback(() => {
		setModalPaymentIsOpen(true)
	}, [])

	const handleTab = () => {
		dispatch(setAppEventZIndex(3))
	}

	const closePaymentModal = useCallback(() => {
		setModalPaymentIsOpen(false)
		dispatch(searchProduct(''))
	}, [dispatch])

	const openModalCustomProduct = () => {
		setModalCustomProductIsOpen(true)
	}

	const closeModalCustomProduct = () => {
		setModalCustomProductIsOpen(false)
		dispatch(searchProduct(''))
		dispatch(setAppEventZIndex(0))
	}

	const openPreOrderDateModal = () => {
		setModalPreOrderDateIsOpen(true)
	}

	const closePreOrderDateModal = () => {
		setModalPreOrderDateIsOpen(false)
	}

	const resetDeliveryCost = () => {
		dispatch(setDeliveryCartCurrentDeliveryCost(0))
	}

	const handleF3 = () => {
		openModalCustomProduct()
	}

	const handleF6 = () => {
		dispatch(setAppEventZIndex(6))
		resetDeliveryCost()
	}

	const handleF8 = () => {
		if (preOrderDate) {
			closePreOrderDateModal()
		} else {
			openPreOrderDateModal()
		}
	}

	const handleF9 = () => {
		dispatch(setAppEventZIndex(7))
	}

	const handleF10 = () => {
		dispatch(setAppEventZIndex(6))
	}

	const addProduct = (item, updateMode = false) => {
		if (updateMode) {
			dispatch(updateClientMonitorCartThunk(MODULE,
				() => dispatch(updateDeliveryCartItem({ item, itemIndex: itemFocusIndex }))))
		} else {
			batch(() => {
				const focusIndex = hasCoupon ? items.length - 1 : null
				dispatch(setDeliveryCartItemFocusIndex(focusIndex))
				dispatch(updateClientMonitorCartThunk(MODULE,
					() => dispatch(addDeliveryCartItem(item))))
				setGiftCouponFocused(null)
			})
		}
	}

	const incrementQuantity = (event, item, itemIndex) => {
		event?.stopPropagation()
		const itemCopy = clone(item)

		if (item.itemPositionId && isUnpaidOrder) {
			const indexNewItemExistsInItems = items.findIndex(el => !el.itemPositionId && el.itemId === item.itemId)
			if (indexNewItemExistsInItems === -1) {
				const { itemPositionId, items: subItems, ...rest } = item
				const updatedSubItems = subItems?.map(subItem => {
					const { itemPositionId, ...restSubItem } = subItem
					return restSubItem
				})
				dispatch(setDeliveryCartItemFocusIndex(items.length))
				dispatch(updateClientMonitorCartThunk(MODULE,
					() => dispatch(addDeliveryCartItem({ ...rest, quantity: 1, items: updatedSubItems }))))
			} else {
				const newItem = items[indexNewItemExistsInItems]
				const newItemWithIncrementQuantity = { ...newItem, quantity: newItem.quantity + 1 }
				const copyItems = items.map((el, index) => indexNewItemExistsInItems === index ? newItemWithIncrementQuantity : el)
				dispatch(setDeliveryCartItemFocusIndex(indexNewItemExistsInItems))
				dispatch(updateClientMonitorCartThunk(MODULE,
					() => dispatch(setDeliveryCartItems(copyItems))))
			}
		} else {
			const itemWithIncrementQuantity = { ...itemCopy, quantity: item.quantity + 1 }
			const copyItems = items.map((el, index) => itemIndex === index ? itemWithIncrementQuantity : el)
			dispatch(setDeliveryCartItemFocusIndex(itemIndex))
			dispatch(updateClientMonitorCartThunk(MODULE,
				() => dispatch(setDeliveryCartItems(copyItems))))
		}
		setGiftCouponFocused(null)
	}

	const dropProduct = (event, itemIndex) => {
		event?.stopPropagation()
		const selectItem = items[itemIndex]
		if (selectItem.itemPositionId && isUnpaidOrder) {
			const copyItems = cancelItem(items, itemIndex)
			dispatch(updateClientMonitorCartThunk(MODULE,
				() => dispatch(setDeliveryCartItems(copyItems))))
			if (selectItem.quantity > 1) {
				const copySelectItem = removeStatusPositionIdAndDecrementQuantity(selectItem)
				dispatch(updateClientMonitorCartThunk(MODULE,
					() => dispatch(addDeliveryCartItem(copySelectItem))))
			}
		} else {
			dispatch(setDeliveryCartItemFocusIndex(items[itemIndex].quantity > 1 ? itemIndex : null))
			dispatch(updateClientMonitorCartThunk(MODULE,
				() => dispatch(removeDeliveryCartItem(itemIndex))))
		}
		setGiftCouponFocused(null)
	}

	const handleDelete = () => {
		dropProduct(null, itemFocusIndex)
	}

	const addTopping = (toppingItem) => {
		if (items[itemFocusIndex].type === 'MENU') {
			return notifyToast({ message: t('shoppingCart.warning') }, 'warning')
		}
		dispatch(updateClientMonitorCartThunk(MODULE,
			() => dispatch(addDeliveryCartSubItem({ itemIndex: itemFocusIndex, item: toppingItem })),
		))
	}

	const dropTopping = (event, itemIndex, subItemIndex) => {
		event?.stopPropagation()
		const selectItem = items[itemIndex]
		if (selectItem?.itemPositionId && isUnpaidOrder) {
			const copyItems = cancelSubItem(items, itemIndex, subItemIndex)
			dispatch(updateClientMonitorCartThunk(MODULE,
				() => dispatch(setDeliveryCartItems(copyItems))))
		} else {
			dispatch(updateClientMonitorCartThunk(MODULE,
				() => dispatch(removeDeliveryCartSubItem({ itemIndex: itemIndex, subItemIndex: subItemIndex }))))
		}
	}
	const setFocus = (itemIndex, item) => {
		if (isUnpaidOrder && item.itemPositionId) return
		dispatch(setDeliveryCartItemFocusIndex(itemIndex))
		setGiftCouponFocused(null)
		if (item.type !== 'MENU') {
			dispatch(getCartProductThunk(item))
		}
	}

	const clearSearchParams = useCallback(() => {
		dispatch(setCartCategoryProductType(null))
		dispatch(setCartCategoryCategoryId(null))
	}, [dispatch])

	const resetCart = useCallback((clearClientMonitorCart = true) => {
		dispatch(setCustomer(null))
		dispatch(setDeliveryCartOrder(null))
		if (clearClientMonitorCart) {
			dispatch(updateClientMonitorCartThunk(MODULE,
				() => dispatch(setDeliveryCartItems([]))))
		} else {
			dispatch(setDeliveryCartItems([]))
		}
		dispatch(setDeliveryCartItemFocusIndex(null))
		dispatch(setDeliveryCartPreorderDate(null))
		dispatch(setDeliveryCartOrderType(null))
		dispatch(setCartDiscount(initialCartDiscount))
		dispatch(setCartExtraDiscount(0))
		dispatch(setCartExtraDiscountType(CURRENCY))
		dispatch(setDeliveryCartMaxDistance(null))
		dispatch(setDeliveryCartCurrentDeliveryCost(0))
		dispatch(setDeliveryCartDefaultDeliveryCost(null))
		dispatch(setDeliveryCartMinimumPrice(0))
		dispatch(setDeliveryCartFreeFrom(null))
		dispatch(setShowDeliveryConfigError(false))
		setShowMobileCart(false)
		setGiftCouponFocused(null)
		dispatch(setDeliveryCartGiftCoupon([]))
		clearSearchParams()
	}, [dispatch, clearSearchParams, initialCartDiscount])

	const handleResetCartAfterPayment = useCallback(async () => {
		resetCart(false)
		closePaymentModal()
		navigateBack()
		await dispatch(deleteClientMonitorCartThunk())
	}, [resetCart, closePaymentModal, navigateBack, dispatch])

	const handlePayment = async ({ paymentId, paymentLink, paymentMethod, tips = 0, remark, deferred = false, customer = null }) => {
		const isEcTerminalPaymentMethod = paymentMethod === 'EC_TERMINAL'
		const resultTotalPrice = roundPrice(totalPrice + tips)
		const paymentWithGiftCoupon = getGiftCouponsPayment(giftCoupons)
		const payments = paymentMethod === 'GIFT_COUPON' ? paymentWithGiftCoupon :
			[{ id: paymentId, link: paymentLink, method: paymentMethod, total: roundPrice(priceWithGiftCoupons + tips), deferred }, ...paymentWithGiftCoupon]

		const orderData = {
			...order, orderType, totalPrice: resultTotalPrice, totalDiscount: discountInCurrency, paymentMethod, payments, preOrder: preOrderDate,
			customer: customer || (storedCustomer ? { ...storedCustomer, geoData } : null),
			deliveryCost: { cost: currentDeliveryCost },
			items: addItemAndCouponPrice(items, extraDiscountInCurrency, couponDiscountInCurrency),
			remark, tips, module: { moduleType: MODULE },
		}
		if (order) {
			const updatedOrderId = await dispatch(updateOrderThunk(order.orderId, orderData))
			if (!deferred) dispatch(fetchPrintSettingsThunk(updatedOrderId, { module: MODULE, businessScope: orderType }))
		} else {
			const orderId = await dispatch(storeOrderThunk(orderData))
			if (!deferred && !paymentLink && !isEcTerminalPaymentMethod) dispatch(fetchPrintSettingsThunk(orderId, { module: MODULE, businessScope: orderType }))
		}
		if (!paymentLink && !isEcTerminalPaymentMethod) {
			await handleResetCartAfterPayment()
		}
	}

	const toggleMobileCart = () => {
		setShowMobileCart(!showMobileCart)
		if (!toggled) dispatch(setToggled(true))
	}

	const removeRemark = (event, itemIndex) => {
		event?.stopPropagation()
		dispatch(removeDeliveryCartRemark(itemIndex))
	}

	const addRemarkToItem = (comment) => {
		dispatch(addDeliveryCartRemarkToItem({ itemFocusIndex, comment }))
	}

	const removeCoupon = useCallback((event) => {
		event?.stopPropagation()
		dispatch(updateClientMonitorCartThunk(MODULE,
			() => dispatch(removeDeliveryCartCoupon())))
		dispatch(setDeliveryCartItemFocusIndex(null))
	}, [dispatch])

	const removeGiftCoupon = useCallback((event, id) => {
		event?.stopPropagation()
		const filteredGiftCoupons = giftCoupons.filter(item => item.id !== id)
		dispatch(updateClientMonitorCartThunk(MODULE,
			() => dispatch(setDeliveryCartGiftCoupon(filteredGiftCoupons))))
		if (filteredGiftCoupons.length > 0) {
			setGiftCouponFocused(filteredGiftCoupons.length - 1)
			dispatch(setDeliveryCartItemFocusIndex(null))
		} else if (itemFocusIndex === null && items?.length > 0) {
			dispatch(setDeliveryCartItemFocusIndex(items.length - 1))
			setGiftCouponFocused(null)
		}
	}, [dispatch, giftCoupons, itemFocusIndex, items?.length])

	const handleAddGiftCouponAccept = useCallback(() => {
		setGiftCouponAccepted(true)
		setModalAddGiftCouponOpen(false)
	}, [])

	const handleAddGiftCouponClose = useCallback(() => {
		dispatch(setGiftCoupon(null))
		setModalAddGiftCouponOpen(false)
	}, [dispatch])

	return (
		<>
			<DeliveryCartHeader {...{ zIndex: EVENT_Z_INDEX, toggleMobileCart, openPreOrderDateModal, openModalCustomProduct, resetDeliveryCost }} />
			<MainWrapper>
				<CartGrid className={(touchPad ? 'touchPad ' : '') + (showMobileCart ? ' cart' : '') + (isOldReactAppVersion ? ' versionUpdater' : '')}>
					{!isTablet &&
						<CartHeaderButtons
							{...{ addProduct, giftCoupons, isDisabled, hasMoneyExchange, orderType, openPreOrderDateModal, openModalCustomProduct, resetDeliveryCost, removeCoupon }} />}
					<Cart {...{ showTotal: true, items, itemsAndDiscountTotal, itemsTotal, itemFocusIndex, totalPrice: priceWithGiftCoupons, zIndex: EVENT_Z_INDEX, setFocus, incrementQuantity, dropProduct, dropTopping, handleOrderProcessing, navigateBack, removeRemark, addProduct, removeCoupon, removeGiftCoupon, giftCouponFocused, hasGiftCoupon, resetCart }} />
					<CartProductSearch {...{ focusedItem, module: MODULE, orderType, clearSearchParams, addTopping, isDisabled }}
														 handleF2={handleOrderProcessing} addMainDish={addProduct} />
				</CartGrid>
				<ModalPayment isOpen={modalPaymentIsOpen}
											closeModal={closePaymentModal}
											openModal={reOpenPaymentModal}
											handlePayment={handlePayment}
											remark={order?.remark}
											totalSum={priceWithGiftCoupons}
											changeCalculatorIsNeeded={modalChangeCalculatorIsNeeded}
											module={MODULE}
											orderType={orderType}
											resetCart={handleResetCartAfterPayment}
											isPreorder={!!preOrderDate}
											isMoneyExchange={hasMoneyExchange}
											updateOrder={!!order?.orderId} />
				<ModalDate isOpen={modalPreOrderDateIsOpen}
									 date={preOrderDate}
									 setDate={date => dispatch(setDeliveryCartPreorderDate(date))}
									 close={closePreOrderDateModal} />
				<ModalCustomProduct isOpen={modalCustomProductIsOpen}
														close={closeModalCustomProduct}
														addRemarkToItem={addRemarkToItem}
														addProduct={addProduct}
														focusedItemRemark={focusedItemRemark}
														itemExist={itemExist} />
				<ActionModal modalIsOpen={modalAddGiftCouponOpen}
										 accept={handleAddGiftCouponAccept}
										 cancel={handleAddGiftCouponClose}
										 close={handleAddGiftCouponClose}
										 modalTitle={t('app.modals.Attention')}
										 modalBody={t('Cart.ActionModal.addGiftCoupon')} />
				<ModalECTerminalWarning isOpen={showEsTerminalWarningModal}
																setOpenModal={setShowEsTerminalWarningModal} />

				{eventZIndex === EVENT_Z_INDEX &&
					<KeyBindings tab={handleTab} f10={handleF10} f9={handleF9} f8={handleF8} f3={handleF3} f6={handleF6} delet={handleDelete} />}
			</MainWrapper>
		</>
	)
})

DeliveryCart.displayName = 'DeliveryCart'
export default DeliveryCart
