import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const InfoMessageWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	gap: 0.44em;
	margin: -0.94em 0 1.44em;

	&.center {
		justify-content: center;
		align-items: center;
	}

	&.fullWidth {
		align-items: center;
	}

	&.start {
		align-items: start;
	}

	svg {
		width: 1.5em;
		height: 1.5em;
		flex-shrink: 0;
		fill: ${colorTheme.message.success.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		&.fullWidth {
			align-items: start;
		}
	}
`
InfoMessageWrapper.displayName = 'InfoMessageWrapper'

export const InfoMessageText = styled.div`
	font-size: 1em;
	font-family: ${theme.fontRoboto};
	color: ${colorTheme.message.success.text};
	width: 24.95em;
	text-align: left;

	&.fullWidth {
		width: 100%;
	}

	&.font-14 {
		font-size: 0.88em;
	}

	&.font-18 {
		font-size: 1.13em;
	}

	@media screen and (max-width: ${theme.point1280}) {
		width: 100%;
	}
`
InfoMessageText.displayName = 'InfoMessageText'
