import styled from 'styled-components'

export const TipsButtonWrapper = styled.div`
	width: 100%;
	font-size: 1rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.63em;
`
TipsButtonWrapper.displayName = 'TipsButtonWrapper'
